import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import theme from '../../theme';
import { DatePicker } from '@mui/x-date-pickers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { CloseRounded, Keyboard, Notes, SaveOutlined, Title } from '@mui/icons-material';
import { useBoolState } from '../../helpers/hooks/useBoolState';
import EventScheduleIcon from '@mui/icons-material/AccessTimeFilledRounded';
import { flexStart, formatLocalDateToISOString } from '../../helpers/Tools';
import PrivateIcon from '@mui/icons-material/VisibilityOffRounded';
import { EventType, Garage } from '@movalib/movalib-commons';
import { setSnackbar } from '../../slices/snackbarSlice';
import { useDispatch } from 'react-redux';
import GarageService from '../../services/GarageService';
import CustomTextarea from '../../components/CustomTextArea';

enum SelectHolidayTitle {
  CONGES = 'Congés',
  TRAVAUX = 'Travaux',
  FERIE = 'Férié',
  OTHERS = 'Autres...',
}

const formSchema = Yup.object({
  title: Yup.string()
    .min(3, 'Le titre doit contenir au minimum 3 caractères')
    .required("Veuillez préciser le motif de l'absence"),
  notes: Yup.string().optional().nullable(),
  startDate: Yup.date().optional(),
  endDate: Yup.date().optional(),
});

type Form = Yup.InferType<typeof formSchema>;

type CalendarHolidayDialogProps = {
  garageId: Garage['id'];
  isDialogOpen: boolean;
  onCloseDialog: () => void;
  refreshEvents: () => void;
};

const NOW = new Date();

const formInitialValues = {
  title: 'Congés',
  notes: null,
  startDate: new Date(NOW.getFullYear(), NOW.getMonth(), NOW.getDate(), 7, 0, 0),
  endDate: new Date(NOW.getFullYear(), NOW.getMonth(), NOW.getDate() + 1, 20, 0, 0),
};
export const CalendarHolidayDialog: FC<CalendarHolidayDialogProps> = ({
  garageId,
  isDialogOpen,
  onCloseDialog,
  refreshEvents,
}) => {
  const [selectValue, setSelectValue] = useState<SelectHolidayTitle>(SelectHolidayTitle.CONGES);
  const { isTitleFieldUsed, setTitleFieldUsed } = useBoolState(false, 'titleFieldUsed');
  // Pour piloter l'autoFocus sur le champ titleField
  const titleFieldRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  // useEffect pour gérer le focus
  useEffect(() => {
    if (isTitleFieldUsed && titleFieldRef.current) {
      titleFieldRef.current.focus();
    }
  }, [isTitleFieldUsed]); // Dépendance à isTitleFieldUsed pour déclencher le focus

  const handleChangeStartDate = (date: Date | null) => {
    if (!date) {
      return;
    }
    setFieldValue('startDate', new Date(date.setHours(7, 0, 0, 0)));
  };

  const handleChangeEndDate = (date: Date | null) => {
    if (!date) {
      return;
    }
    setFieldValue('endDate', new Date(date.setHours(20, 0, 0, 0)));
  };

  const handleChangeSelectTitle = (e: SelectChangeEvent<SelectHolidayTitle>) => {
    // Le choix sélectionné est "Autres...", alors on active la saisie manuelle
    if (e.target.value === SelectHolidayTitle.OTHERS) {
      setTitleFieldUsed(true);
      setFieldValue('title', '');
    } else {
      // Si l'input manuel est visible, alors on le cache, puis on set le titre par défaut
      if (isTitleFieldUsed) {
        setTitleFieldUsed(false);
      }
      setFieldValue('title', e.target.value);
    }

    setSelectValue(e.target.value as SelectHolidayTitle);
  };

  const onSubmitForm = (values: Form) => {
    const requestPayload = {
      ...values,
      type: EventType.UNAVAILABILITY,
      startDate: formatLocalDateToISOString(values.startDate!),
      endDate: formatLocalDateToISOString(values.endDate!),
    };
    const formData = new FormData();

    formData.append('event', JSON.stringify(requestPayload));

    // Création de l'événement
    GarageService.createGarageEvent(dispatch, garageId, formData)
      .then(() => {
        refreshEvents();

        // Fermeture de la boite de dialogue (avec refresh des events)
        onCloseDialog();

        // Affichage notification utilisateur
        dispatch(setSnackbar({ open: true, message: "L'indisponibilité a bien été ajoutée", severity: 'success' }));
      })
      .catch((error) => {
        console.error(error);
        dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
      });
  };

  const { values, errors, isValid, setFieldValue, handleChange, handleSubmit } = useFormik<Form>({
    initialValues: formInitialValues,
    validationSchema: formSchema,
    onSubmit: onSubmitForm,
    enableReinitialize: true,
  });

  return (
    <Dialog scroll='body' 
    open={isDialogOpen} 
    sx={{ p: 0 }} 
    onClose={onCloseDialog} maxWidth='sm' fullWidth>
      <Toolbar
        disableGutters
        sx={{
          display: 'block',
          background: theme.palette.grey[200],
          minHeight: 3,
          p: 0,
        }}
      >
        <DialogTitle p={1}>
          <Box position='relative'>
            <Typography
              variant='h6'
              sx={{
                textTransform: 'uppercase',
                fontStyle: 'bold',
                textAlign: 'center',
              }}
            >
              Ajouter une <b>période d'absence</b>
            </Typography>

            <IconButton
              sx={{ position: 'absolute', right: 0, top: 0, zIndex: 1 }}
              size='small'
              aria-label='close'
              onClick={onCloseDialog}
              title={'Fermer'}
            >
              <CloseRounded />
            </IconButton>
          </Box>
        </DialogTitle>
      </Toolbar>
      <form onSubmit={handleSubmit}>
        <DialogContent
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <Typography variant='h6'>Durée de l'absence</Typography>
          <Box display='flex' gap='8px' alignItems='center'>
		  <Title sx={{color: theme.palette.grey[500], display: {xs: 'none', md:'inline-block'}}}/>
            <DatePicker
              label={"Début de l'absence"}
              name={'startDate'}
              value={values.startDate}
              format='dd/MM/yyyy'
              formatDensity='dense'
              views={['day']}
              displayWeekNumber
              onChange={handleChangeStartDate}
              slotProps={{
                textField: {
                  required: true,
                  size: 'small',
                  sx: {
                    padding: 0,
                  },
                },
              }}
            />

            <DatePicker
              label={"Fin de l'absence"}
              name={'endDate'}
              value={values.endDate}
              format='dd/MM/yyyy'
              formatDensity='dense'
              views={['day']}
              displayWeekNumber
              onChange={handleChangeEndDate}
              slotProps={{
                textField: {
                  required: true,
                  size: 'small',
                  sx: {
                    padding: 0,
                  },
                },
              }}
            />
          </Box>
          <Typography variant='h6'>Motif de l'absence</Typography>
          <Box display='flex' gap='8px' alignItems='center'>
		  <Title sx={{color: theme.palette.grey[500], display: {xs: 'none', md:'inline-block'}}}/>
		  <Select name='title' onChange={handleChangeSelectTitle} value={selectValue} fullWidth size='small'>
              <MenuItem value={SelectHolidayTitle.CONGES}> Congés </MenuItem>
              <MenuItem value={SelectHolidayTitle.TRAVAUX}> Travaux </MenuItem>
              <MenuItem value={SelectHolidayTitle.FERIE}> Férié </MenuItem>
              <MenuItem value={SelectHolidayTitle.OTHERS}> Autres... </MenuItem>
            </Select>
          </Box>
          {selectValue === SelectHolidayTitle.OTHERS && (
            <Box display='flex' gap='8px' alignItems='center'>
								<Keyboard sx={{color: theme.palette.grey[500], display: {xs: 'none', md:'inline-block'}}}/>
								<TextField
                name='title'
                placeholder='Précisez ...'
                autoFocus={isTitleFieldUsed}
                value={values.title}
                onChange={handleChange}
                size='small'
                error={!!errors.title}
                helperText={errors.title || undefined}
                fullWidth
                inputRef={titleFieldRef}
              />
            </Box>
          )}

          <Typography variant='h6'>Notes</Typography>
          <Box display='flex' gap='8px' alignItems='center'>
							<Notes sx={{color: theme.palette.grey[500], display: {xs: 'none', md:'inline-block'}}}/>
            <CustomTextarea
              id='notes'
              name='notes'
              value={values.notes || ''}
              onChange={handleChange}
              error={errors.notes}
              helperText={
                errors.notes ? (
                  errors.notes
                ) : (
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <PrivateIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                    Non visible par le client
                  </Box>
                )
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={!isValid} variant='contained' type='submit' startIcon={<SaveOutlined />}>
            Enregistrer
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
