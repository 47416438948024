import type React from 'react';
import { Box, FormControl, FormHelperText } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useEffect, useRef } from 'react';
interface CustomTextareaProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  error?: string | undefined;
  placeholder?: string;
  helperText?: React.ReactNode;
  id?: string;
  name?: string;
}
const CustomTextarea: React.FC<CustomTextareaProps> = ({
  value,
  onChange,
  onBlur,
  error,
  placeholder = 'Notes internes',
  helperText,
  id,
  name,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // Réinitialise la hauteur
      textarea.style.height = `${textarea.scrollHeight - 17}px`; // Ajuste à la hauteur du contenu
    }
  };
  useEffect(() => {
    adjustHeight();
  }, [value]); // Ré-exécute à chaque changement de `value`
  const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    adjustHeight(); // Ajuste la hauteur du textarea
    onChange(event); // Appelle la fonction onChange
  };
  return (
    <FormControl fullWidth error={Boolean(error)}>
      <Box
        sx={{
          border: '1px solid',
          borderColor: error ? 'red' : 'rgba(0, 0, 0, 0.23)',
          borderRadius: '4px',
          padding: '2px',
          '&:focus-within': {
            borderColor: 'primary.main',
            borderWidth: '2px',
          },
        }}
      >
        <textarea
          ref={textareaRef}
          id={id}
          name={name}
          aria-label={placeholder}
          placeholder={placeholder}
          value={value}
          onChange={handleInput}
          onBlur={onBlur}
          rows={3}
          style={{
            width: '96%',
            fontSize: '1rem',
            fontFamily: 'Outfit, sans-serif',
            padding: '8px',
            border: 'none',
            outline: 'none',
            resize: 'none', // Désactive le redimensionnement manuel
            overflow: 'hidden', // Cache la barre de défilement
          }}
        />
      </Box>
      <FormHelperText>{error ? error : helperText}</FormHelperText>
    </FormControl>
  );
};
export default CustomTextarea;
