import type React from 'react';
import { useEffect, useRef, useState, type FunctionComponent } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Avatar,
  Input,
  TextField,
  InputAdornment,
  Button,
  FormHelperText,
  type Theme,
  IconButton,
  Box,
  Grid,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  type CategoryPrestation,
  type Garage,
  type Prestation,
  PrestationState,
  getApplicationsShortLabels,
} from '@movalib/movalib-commons';
import { flexCenterRow, importIcon } from '../../../../helpers/Tools';
import type { PrestationRequest } from '../../../../helpers/Types';
import type { CatergoryPrestationAggrs } from '../../../../pages/Settings';
import theme from '../../../../theme';
import type { EventForm } from '../../MyCalendarEventDialog';
import { Title } from '@mui/icons-material';

// Définition des types pour les props
interface PickerPrestationProps {
  form: EventForm;
  handleChange: (fieldName: keyof EventForm, newValue: any, validate?: boolean) => void;
  selectedPrestations: PrestationRequest[];
  localGarage: Garage;
}

// Composant principal formaté
const PickerPrestation: FunctionComponent<PickerPrestationProps> = ({
  form,
  handleChange,
  selectedPrestations,
  localGarage,
}) => {
  const [isPrestationsMenuOpen, setIsPrestationsMenuOpen] = useState(false);
  const [prestationsSearchTerm, setPrestationsSearchTerm] = useState(''); // État local pour la recherche des prestations
  const prestationsSearchInputRef = useRef<HTMLInputElement | null>(null); // Référence pour le champ de recherche
  const [expandedCategory, setExpandedCategory] = useState({});
  const [prestationsWithCategory, setPrestationsWithCategory] = useState<CatergoryPrestationAggrs[]>([]);

  useEffect(() => {
    return () => {
      setPrestationsSearchTerm('');
    };
  }, []);

  useEffect(() => {
    if (localGarage) {
      const categoryPrestations = [...localGarage.prestationCategories];
      const prestationGarage = [...localGarage.prestations];
      const categoryPrestationTable = [] as CatergoryPrestationAggrs[];
      categoryPrestations.forEach((category: CategoryPrestation) => {
        const prestations = prestationGarage?.filter(
          (p) => p.categoryCode === category.code && p.state === PrestationState.VALIDATED && p.active,
        ) as Prestation[];
        categoryPrestationTable.push({ ...category, prestations: prestations });
      });
      categoryPrestationTable.sort((a, b) => a.position - b.position);
      setPrestationsWithCategory(categoryPrestationTable);
    }
  }, [localGarage]);

  // Ajoute un effet pour donner le focus au champ de recherche lorsque le menu est ouvert
  useEffect(() => {
    if (isPrestationsMenuOpen) {
      setTimeout(() => {
        if (prestationsSearchInputRef.current) {
          prestationsSearchInputRef.current.focus();
        }
      }, 0); // Laisse le temps au composant de se rendre
    }
  }, [isPrestationsMenuOpen]);
  const handleMenuClose = () => setIsPrestationsMenuOpen(false);
  const handleMenuOpen = (e: any) => {
    if (Number(e.target.getAttribute('aria-label'))) {
      handleChange(
        'prestations',
        form.prestations?.value.filter((idprestation: number) => idprestation !== Number(e.target.getAttribute('aria-label'))),
        true,
      );
    } else {
      if(isPrestationsMenuOpen === false) {setIsPrestationsMenuOpen(true)}
    }
  };

  function filterSearch(prestationName: string): boolean {
    const prestationN = prestationName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    const search = prestationsSearchTerm.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return prestationN.includes(search);
  }

  const getPrestationStyles = (prestation: string, prestationList: string[] | null, theme: Theme) => {
    return {
      fontWeight:
        prestationList && prestationList.indexOf(prestation) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  };

  const handleCategoryClick = (categoryCode: string) => {
    setExpandedCategory((prevCategory) => (prevCategory === categoryCode ? '' : categoryCode));
  };

  const renderPrestation = (prestation: Prestation) => {
    return (
      <>
        {/* Affichage d'un picto si défini */}
        {importIcon(prestation.code) && (
          <img
            src={importIcon(prestation.code)}
            style={{
              width: '20px',
              height: '20px',
              paddingLeft: '4px',
              marginRight: '7px',
            }}
            alt=''
          />
        )}
        {prestation.name}{' '}
        {selectedPrestations
          .filter((p) => p.id === prestation.id)
          .map((p) => getApplicationsShortLabels(p.applications))}
      </>
    );
  };

  return (
    <>
    <FormControl error={Boolean(form.prestations?.error)} variant='standard' fullWidth>
      <InputLabel id='event-prestations-label'>Choix prestation(s) *</InputLabel>
      <Select
        labelId='event-prestations-label'
        id='event-prestations'
        multiple
        open={isPrestationsMenuOpen}
        onOpen={(e) => handleMenuOpen(e)}
        onClose={handleMenuClose}
        onClick={(e) => { handleMenuOpen(e)}}
        value={form.prestations?.value}
        onChange={(e) => handleChange('prestations', e.target.value, true)}
        input={<Input error={Boolean(form.prestations?.error)} />}
        variant='standard'
        renderValue={(selected) => (
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
            {selected
              .map((id: number) =>
                prestationsWithCategory
                  .flatMap((category) => category.prestations)
                  .find((prestation) => prestation.id === id),
              )
              .filter(Boolean)
              .map((prestation: Prestation) => (
                <Chip
                  key={prestation.id}
                  label={
                    <>
                      {prestation.name}{' '}
                      {selectedPrestations
                        .filter((p) => p.id === prestation.id)
                        .map((p) => getApplicationsShortLabels(p.applications))}
                      <IconButton size='small' aria-label={prestation.id.toString()} color='inherit'>
                        <CloseIcon sx={{ pointerEvents: 'none', fontSize: '18px' }} />
                      </IconButton>
                    </>
                  }
                  avatar={
                    importIcon(prestation.code) ? (
                      <Avatar
                        src={importIcon(prestation.code)}
                        style={{ width: '30px', height: '30px' }}
                        sx={{ width: 24, height: 24 }}
                        alt=''
                      />
                    ) : undefined
                  }
                  style={{ margin: '2px' }}
                />
              ))}
          </div>
        )}
        MenuProps={{ disableAutoFocusItem: true, 
          PaperProps: {
            className: 'full-screen',
            style: {
              maxHeight: '400px',
              overflowY: 'auto',
            },
          },
        }}
      >
        <MenuItem disableRipple sx={{position: 'sticky',
              top: 0,
              backgroundColor: 'rgba(245, 245, 245, 1) !important',
              zIndex: 500}}>
          <TextField
            inputRef={prestationsSearchInputRef}
            placeholder='Rechercher une prestation...'
            fullWidth
            variant='outlined'
            size='small'
            value={prestationsSearchTerm}
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingRight: '2px',
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Button
                    variant='contained'
                    disableElevation
                    color='primary'
                    aria-label='clear search'
                    onClick={() => {
                      handleMenuClose();
                      setPrestationsSearchTerm('');
                    }}
                    sx={{
                      borderRadius: 1,
                      color: 'white',
                    }}
                  >
                    <CheckIcon />
                  </Button>
                </InputAdornment>
              ),
            }}
            onChange={(e) => setPrestationsSearchTerm(e.target.value.toLowerCase())}
            onKeyDown={(e) => e.stopPropagation()} // Empêche le Select de réagir aux touches saisies
          />
        </MenuItem>
        {localGarage?.prestations &&
          (() => {
            const allFilteredPrestations = prestationsWithCategory.flatMap((categoryPrestation) =>
              categoryPrestation.prestations.filter((prestation) => filterSearch(prestation.name.toLowerCase())),
            );

            if (prestationsSearchTerm && allFilteredPrestations.length === 0) {
              return (
                <MenuItem disabled key='no-results'>
                  Aucun résultat
                </MenuItem>
              );
            }

            const items = prestationsWithCategory.flatMap((categoryPrestation) => {
              const filteredPrestations = categoryPrestation.prestations.filter((prestation) =>
                filterSearch(prestation.name.toLowerCase()),
              );

              if (prestationsSearchTerm) {
                return filteredPrestations.map((prestation) => (
                  <MenuItem
                    key={prestation.id}
                    value={prestation.id}
                    style={getPrestationStyles(prestation.name, form.prestations?.value, theme)}
                    sx={{ fontSize: '0.9rem' }}
                  >
                    {renderPrestation(prestation)}
                  </MenuItem>
                ));
              }

              const elements = [
                categoryPrestation.prestations.length > 0 && (
                  <MenuItem
                    key={`${categoryPrestation.code}-header`}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleCategoryClick(categoryPrestation.code);
                    }}
                    style={{ cursor: 'pointer', fontWeight: 'bold' }}
                  >
                    {categoryPrestation.name.toUpperCase()}
                  </MenuItem>
                ),
              ];

              if (expandedCategory === categoryPrestation.code) {
                elements.push(
                  ...categoryPrestation.prestations.map((prestation) => (
                    <MenuItem
                      key={prestation.id}
                      value={prestation.id}
                      style={getPrestationStyles(prestation.name, form.prestations?.value, theme)}
                      sx={{ fontSize: '0.9rem' }}
                    >
                      {renderPrestation(prestation)}
                    </MenuItem>
                  )),
                );
              }

              return elements;
            });
            return items;
          })()}
      </Select>
      <FormHelperText>{form.prestations?.error}</FormHelperText>
    </FormControl>
  
    </>
  );
};

export default PickerPrestation;
