import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MyAppBar from "../components/MyAppBar";
import MyCalendar from "../components/calendar/MyCalendar";
import { Avatar, Box, Container, Divider, Drawer, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, lighten, useTheme } from "@mui/material";
import { flexCenterRow } from "../helpers/Tools";

interface AgendaProps {
    garageId: string;
  }

//xs, sm, md, lg, and xl
const Agenda: FunctionComponent<AgendaProps> = ({ garageId }) => {

    return (
        // 72px height of the toolbar
        <Box sx={{
            pt: {
              xs: '48px', // Pour les écrans plus petits que 900px
              md: '72px', // Pour les écrans à partir de 900px
            },
            height: {
              xs: 'calc(var(--fullHeight) - 48px)', // Pour les écrans plus petits que 900px
              md: 'calc(var(--fullHeight) - 72px)', // Pour les écrans à partir de 900px
            },
          }}>
            <MyCalendar garageId={garageId}/>
        </Box>
    );
};

export default Agenda;

