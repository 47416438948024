
import { CloseRounded } from '@mui/icons-material';
import { Dialog, Toolbar, DialogTitle, Box, Typography, IconButton, DialogContent } from '@mui/material';
import theme from '../../../theme';

import type { Event } from '@movalib/movalib-commons';
import MyCalendarEvent from '../MyCalendarEvent';
import { eventStyleGetter } from '../MyCalendar';
import { flexCenter } from '@movalib/movalib-commons/dist/src/helpers/Tools';
type ShowMoreNoteProps = {
    events: Event[];
    isNoteDialogOpened: boolean;
    teamManagement: boolean | undefined;
    handleCloseDialogNote: () => void;
    handleSelectEvent: (event: Event) => void;
  };

export const ShowMoreNote = ({events, isNoteDialogOpened, teamManagement = false, handleCloseDialogNote, handleSelectEvent}: ShowMoreNoteProps) => {
    return (
<Dialog
      open={isNoteDialogOpened}
      onClose={handleCloseDialogNote}
      fullWidth
      maxWidth='sm'
      sx={{ height: {md:'40%', xs: 'auto'} }}
    >
            <Toolbar
        disableGutters
        sx={{
          display: 'block',
          background: theme.palette.grey[200],
          minHeight: 3,
          p: 0,
        }}
      >
      <DialogTitle id='search-dialog-title'>
      <Box position='relative'>
            <Typography
              variant='h6'
              sx={{
                textTransform: 'uppercase',
                fontStyle: 'bold',
                textAlign: 'center',
              }}
            >
        Liste d'événements
        </Typography>


        <IconButton
              sx={{ position: 'absolute', right: 0, top: 0, zIndex: 1 }}
              size='small'
              aria-label='close'
              onClick={handleCloseDialogNote}
              title={'Fermer'}
            >
              <CloseRounded />
            </IconButton>
            </Box>
      </DialogTitle>
      </Toolbar>
      <DialogContent>
        <Box sx={{...flexCenter, flexDirection: 'column', width: '100%', height: '100%'}}>
        {events.map((event) => {
             const { style, className } = eventStyleGetter(event, event.start ?? new Date(), event.end ?? new Date(), false);
             return (
            <Box key={`${event.id}-note`} onClick={() =>{handleCloseDialogNote(); handleSelectEvent(event)}} style={style} className={className} sx={{my: 1, width: '99%'}}>
                    <MyCalendarEvent event={event} view='week' teamManagement={teamManagement}/>
            </Box>)
    })}
    </Box>
      </DialogContent>
    </Dialog>
)};
