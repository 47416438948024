import {
  AddressFields,
  ConfirmationDialog,
  DateFormatTypes,
  DayOfWeek,
  type DaySchedule,
  type Garage,
  GarageService,
  GenderSelector,
  Logger,
  MovaAppType,
  type MovaFormField,
  Schedule,
  ScheduleFields,
  SlotAlgorithm,
  SubscriptionPaymentInterval,
  User,
  UserService,
  deleteCookie,
  formatDateByCountryCode,
  readCookie,
  validateEmail,
  validatePhoneNumber,
} from '@movalib/movalib-commons';
import {
  findScheduleByDayOfWeek,
  flexCenter,
  formatPhoneNumber,
  getFormattedSchedule,
  validateField,
} from '@movalib/movalib-commons/dist/src/helpers/Tools';
import WarningIcon from '@mui/icons-material/AnnouncementRounded';
import BackIcon from '@mui/icons-material/ArrowBackIosRounded';
import ForwardIcon from '@mui/icons-material/ArrowForwardIosRounded';
import SummaryIcon from '@mui/icons-material/Article';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/CloseRounded';
import MandateIcon from '@mui/icons-material/Gavel';
import ConfirmIcon from '@mui/icons-material/RecommendRounded';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  type AlertColor,
  Box,
  Button,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
  darken,
  lighten,
  useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import type React from 'react';
import { type CSSProperties, type FunctionComponent, type ReactNode, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import GreenLeafImage from '../../assets/images/leaf_green_large.png';
import PinkLeafImage from '../../assets/images/leaf_pink_large.png';
import LogoProLarge from '../../assets/images/logo/logo_pro_large_border.png';
import DayPeriodImage from '../../assets/images/sreenshots/signup_dayperiod.png';
import HourImage from '../../assets/images/sreenshots/signup_hour.png';
import GarageSheetImage from '../../assets/images/sreenshots/signup_ss1.png';
import SignUpHeader from '../../components/registration/SignUpHeader';
import { COOKIE_PRO_TOKEN, COOKIE_SELECTED_GARAGE_ID } from '../../helpers/Constants';
import { capitalizeFirstLetterNew, createCookie, deleteCookies, flexEnd, formatTime } from '../../helpers/Tools';
import { setSnackbar } from '../../slices/snackbarSlice';
import './SignUp.css';
import PricingTable from '../../components/registration/PricingTable';

const SMS_OPERATOR_UNIT: number = 250;

// Permet de centrer le contenu de l'application
const centered: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  height: 'var(--fullHeight)', // Ajustez la hauteur en fonction de vos besoins
};

enum SignupStep {
  ADMIN = 0,
  GARAGE = 1,
  SETTINGS = 2,
  BILLING = 3,
  SUMMARY = 4,
}

enum SubscriptionTabs {
  ALLOWANCE = 0,
  GAIN = 1,
  LAUNCH_OFFER = 2,
}

const initialSignupFormState: MovaSignupForm = {
  adminFirstname: { value: '', isValid: true },
  adminLastname: { value: '', isValid: true },
  adminEmail: { value: '', isValid: true },
  adminPhoneNumber: { value: '', isValid: true },
  gender: { value: '', isValid: true },
  adminBirthDate: { value: '', isValid: true },
  garageName: { value: '', isValid: true },
  garageCountry: { value: '', isValid: true },
  garageSchedules: { value: [], isValid: true },
  garageContactPhone: { value: '', isValid: true },
  garageContactEmail: { value: '', isValid: true },
  garageSettings: { value: null, isValid: true },
};

const initialSignupAddressFormState = {
  streetName: { value: '', isValid: true },
  additional: { value: '', isValid: true },
  postalCode: { value: '', isValid: true },
  cityName: { value: '', isValid: true },
};

const initialSignupSettingsFormState: MovaSignupSettingsForm = {
  workforce: { value: '', isValid: true },
  slotAlgorithm: { value: '', isValid: true },
  amVehicleDeposit: { value: undefined, isValid: true },
  pmVehicleDeposit: { value: undefined, isValid: true },
  appointmentRequestStart: { value: '', isValid: true },
  customerQuoteActive: { value: '', isValid: true },
  supplierOrderActive: { value: '', isValid: true },
  subscriptionRibFile: { value: '', isValid: true },
  subscriptionPaymentInterval: { value: SubscriptionPaymentInterval.YEAR, isValid: true },
  subscriptionComment: { value: '', isValid: true },
  commissioningPreference: { value: '', isValid: true },
};

type MovaSignupForm = {
  adminFirstname: MovaFormField;
  adminLastname: MovaFormField;
  adminEmail: MovaFormField;
  adminPhoneNumber: MovaFormField;
  gender: MovaFormField;
  adminBirthDate: MovaFormField;
  garageName: MovaFormField;
  garageCountry: MovaFormField;
  garageSchedules: { value?: Schedule[]; error?: string; isValid?: boolean };
  garageContactPhone: MovaFormField;
  garageContactEmail: MovaFormField;
  garageSettings: MovaFormField;
};

type MovaSignupAdressForm = {
  streetName: MovaFormField;
  additional: MovaFormField;
  postalCode: MovaFormField;
  cityName: MovaFormField;
};

type MovaSignupSettingsForm = {
  workforce: MovaFormField;
  slotAlgorithm: MovaFormField;
  amVehicleDeposit: { value?: Date | undefined; error?: string; isValid?: boolean };
  pmVehicleDeposit: { value?: Date | undefined; error?: string; isValid?: boolean };
  appointmentRequestStart: MovaFormField;
  customerQuoteActive: MovaFormField;
  supplierOrderActive: MovaFormField;
  subscriptionRibFile: MovaFormField;
  subscriptionPaymentInterval: MovaFormField;
  subscriptionComment: MovaFormField;
  commissioningPreference: MovaFormField;
};

const SignUp: FunctionComponent = () => {
  const [tabValue, setTabValue] = useState<SubscriptionTabs>(SubscriptionTabs.ALLOWANCE);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // Référence à l'éventuel RIB transmis
  const ribInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  // La sévérité est initialisée à "error" par défaut
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>('error');
  const [connectedUser, setConnectedUser] = useState<User | undefined>();
  const [currentStep, setCurrentStep] = useState<SignupStep>(SignupStep.ADMIN);
  const isAuthenticated: boolean = Boolean(readCookie(COOKIE_PRO_TOKEN));
  const breakDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const [openMenu, setOpenMenu] = useState<boolean>();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  //const [selectedGarage, setSelectedGarage] = useState<Garage>();
  const [selectedGarageId, setSelectedGarageId] = useState<string | undefined>(undefined);
  const [signupForm, setSignupForm] = useState<MovaSignupForm>(initialSignupFormState);
  const [signupAddressForm, setSignupAddressForm] = useState<MovaSignupAdressForm>(initialSignupAddressFormState);
  const [signupSettingsForm, setSignupSettingsForm] = useState<MovaSignupSettingsForm>(initialSignupSettingsFormState);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [dailyInvoices, setDailyInvoices] = useState<number>(6);
  const [starterPackCost, setStarterPackCost] = useState<number>();
  const [techniciansCost, setTechniciansCost] = useState<number>();
  const [subscriptionCost, setSubscriptionCost] = useState<number>();
  const [openConfirmSignupDialog, setOpenConfirmSignupDialog] = useState<boolean>(false);
  
  // Chargement initial de la page
  useEffect(() => {
    if (isAuthenticated) {
      // On contrôle que l'utilisateur dispose du rôle adéquat
      UserService.getCurrentUser(MovaAppType.GARAGE).then((response) => {
        if (response.success) {
          setConnectedUser(response.data);
        }
      });

      // Récupération éventuelle de l'identifiant du garage sélectionné depuis les Cookies
      setSelectedGarageId(readCookie(COOKIE_SELECTED_GARAGE_ID));
    }
  }, []);

  useEffect(() => {
    if (selectedGarageId) {
      // Chargement du garage depuis un appel API
      GarageService.getGarageAllData(selectedGarageId).then((response) => {
        if (response.success) {
          if (response.data) {
            initializeForms(response.data);
          }
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? 'Récupération des données du garage impossible, contactez Movalib',
              severity: 'error',
            }),
          );
        }
      });
    }
  }, [selectedGarageId]);

  const initializeForms = (garage: Garage) => {
    if (garage) {
      const updatedSignupFormState = {
        ...initialSignupFormState, // Copie de l'état initial
        garageName: { value: garage.name },
        adminFirstname: { value: garage.admin?.firstname },
        adminLastname: { value: garage.admin?.lastname },
        adminEmail: { value: garage.admin?.email },
        adminPhoneNumber: { value: garage.admin?.phoneNumber ? garage.admin?.phoneNumber : '' },
        gender: { value: garage.admin?.gender ? garage.admin?.gender.toString() : '' },
        adminBirthDate: { value: garage.admin?.birthDate instanceof Date ? new Date(garage.admin?.birthDate) : '' },
        garageContactEmail: { value: garage.contactEmail ? garage.contactEmail : '' },
        garageContactPhone: { value: garage.contactPhone ? garage.contactPhone : '' },
        garageSchedules: { value: garage.schedules ? garage.schedules : [] },
      };

      setSignupForm(updatedSignupFormState);

      const updatedSignupAddressFormState = {
        ...initialSignupAddressFormState, // Copie de l'état initial
        streetName: { value: garage.address?.streetName, isValid: true },
        additional: { value: garage.address?.additional ? garage.address.additional : '', isValid: true },
        postalCode: { value: garage.address?.postalCode, isValid: true },
        cityName: { value: garage.address?.cityName, isValid: true },
      };

      setSignupAddressForm(updatedSignupAddressFormState);

      const today = new Date().toISOString().substring(0, 10);

      const updatedSignupSettingsFormState = {
        ...initialSignupSettingsFormState, // Copie de l'état initial
        workforce: { value: garage.workforce ? garage.workforce : null, isValid: true },
        slotAlgorithm: { value: garage.slotAlgorithm ? garage.slotAlgorithm.toString() : '', isValid: true },
        amVehicleDeposit: {
          value: garage.zonedAmVehicleDeposit ? new Date(`${today}T${garage.zonedAmVehicleDeposit!}`) : undefined,
          isValid: true,
        },
        pmVehicleDeposit: {
          value: garage.zonedPmVehicleDeposit ? new Date(`${today}T${garage.zonedPmVehicleDeposit!}`) : undefined,
          isValid: true,
        },
        subscriptionPaymentInterval: {
          value: garage.subscriptionPaymentInterval
            ? garage.subscriptionPaymentInterval.toString()
            : SubscriptionPaymentInterval.QUARTER,
          isValid: true,
        },
      };

      setSignupSettingsForm(updatedSignupSettingsFormState);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const fieldName: string = e.target.name;
    let fieldValue: string = e.target.value;

    // On autorise uniquement les chiffre pour le n° de téléphone
    if (fieldName === 'adminPhoneNumber' || fieldName === 'garageContactPhone') {
      fieldValue = fieldValue.replace(/[^0-9]/g, '');
      // Limiter la longueur à 10 caractères
      if (fieldValue.length > 10) {
        fieldValue = fieldValue.substring(0, 10);
      }
    }

    // Capitalisation automatique du prénom
    if (fieldName === 'adminFirstname') {
      fieldValue = capitalizeFirstLetterNew(fieldValue);
    }

    // Uppercase automatique pour le nom
    if (fieldName === 'adminLastname') {
      fieldValue = fieldValue.toUpperCase();
    }

    const newField: MovaFormField = { [fieldName]: { value: fieldValue, isValid: true } };

    setSignupForm({ ...signupForm, ...newField });
  };

  const handleDepositTimeChange = (type: 'amVehicleDeposit' | 'pmVehicleDeposit', newValue: '' | Date | null) => {
    const fieldName: string = type;
    const fieldValue: '' | Date | null = newValue;

    const newField: MovaFormField = { [fieldName]: { value: fieldValue, isValid: true } };

    setSignupSettingsForm({ ...signupSettingsForm, ...newField });
  };

  const isSignupAuthorized = (user: User | undefined) => {
    return user && (User.isSales(user) || User.isSuperAdmin(user));
  };

  const handleSubmit = async (validate: boolean): Promise<boolean> => {
    try {
      // Préparation de la requête (utilisation d'un formData car éventuelle transmission d'un RIB)
      const formData = new FormData();

      const adminJson = {
        email: signupForm.adminEmail?.value,
        firstname: signupForm.adminFirstname?.value,
        lastname: signupForm.adminLastname?.value,
        gender: signupForm.gender?.value,
        birthDate: signupForm.adminBirthDate?.value,
        phoneNumber: signupForm.adminPhoneNumber?.value,
      };

      const addressJson = {
        streetName: signupAddressForm.streetName?.value,
        additional: signupAddressForm.additional?.value,
        postalCode: signupAddressForm.postalCode?.value,
        cityName: signupAddressForm.cityName?.value,
      };

      const query = {
        id: selectedGarageId,
        admin: adminJson,
        name: signupForm.garageName?.value,
        address: currentStep === SignupStep.GARAGE || currentStep === SignupStep.SUMMARY ? addressJson : null,
        schedules: signupForm.garageSchedules?.value,
        contactPhone: signupForm.garageContactPhone?.value,
        contactEmail: signupForm.garageContactEmail?.value,
        workforce: signupSettingsForm.workforce?.value,
        slotAlgorithm: signupSettingsForm.slotAlgorithm?.value,
        countryCode: 'FR', // Code Pays par défaut pour le moment
        amVehicleDeposit: formatDateByCountryCode(
          signupSettingsForm.amVehicleDeposit?.value,
          'FR',
          DateFormatTypes.TIME_ONLY,
        ),
        pmVehicleDeposit: formatDateByCountryCode(
          signupSettingsForm.pmVehicleDeposit?.value,
          'FR',
          DateFormatTypes.TIME_ONLY,
        ),
        subscriptionPaymentInterval: signupSettingsForm.subscriptionPaymentInterval?.value,
        subscriptionComment: signupSettingsForm.subscriptionComment?.value,
        commissioningPreference: signupSettingsForm.commissioningPreference?.value,
        validateRegistration: validate,
        rdvRatioCost: getRdvRatioCost(),
      };

      formData.append('garage', JSON.stringify(query));
      formData.append('rib', signupSettingsForm.subscriptionRibFile?.value);

      const response = await GarageService.salesSignUp(formData);

      if (response.success) {
        // Inscription de l'identifiant du garage dans un Cookie
        createCookie(COOKIE_SELECTED_GARAGE_ID, String(response.data));
        setSelectedGarageId(response.data ? String(response.data) : '');

        if (currentStep === SignupStep.SUMMARY) {
          dispatch(
            setSnackbar({
              open: true,
              message: "Inscription terminée, le compte va être validé par l'équipe Movalib",
              severity: 'success',
            }),
          );
          // Reset de la page
          purgeForms();
        }

        return Promise.resolve(true);
      }
      dispatch(
        setSnackbar({
          open: true,
          message: response.error ?? 'Inscription du garage impossible, contactez Movalib',
          severity: 'error',
        }),
      );
      return Promise.resolve(false);
    } catch (error) {
      console.error('Error occurred during submission:', error);
      return Promise.resolve(false);
    }
  };

  const handleSignupSettingsChange = (fieldName: keyof MovaSignupSettingsForm, newValue: any) => {
    const newField: MovaFormField = { [fieldName]: { value: newValue, isValid: true } };

    setSignupSettingsForm({ ...signupSettingsForm, ...newField });
  };

  const handleWorkforceChange = (value: number) => {
    setSignupSettingsForm((prevForm) => ({
      ...prevForm,
      workforce: { ...prevForm.workforce, value: value },
    }));
  };

  const handleSignupChange = (fieldName: keyof MovaSignupForm, newValue: any) => {
    // Maj AUTO si nom du garage ou nom de l'administrateur
    if (fieldName === 'garageName') {
      newValue = String(newValue).toUpperCase();
    }

    const newField: MovaFormField = { [fieldName]: { value: newValue, isValid: true } };

    setSignupForm({ ...signupForm, ...newField });
  };

  const handleSignupAddressChange = (fieldName: keyof MovaSignupAdressForm, newValue: any) => {
    // Upper case automatique sur le nom de la ville
    if (fieldName === 'cityName') {
      newValue = (newValue as string).toUpperCase();
    }

    const newField: MovaFormField = { [fieldName]: { value: newValue, isValid: true } };

    setSignupAddressForm({ ...signupAddressForm, ...newField });
  };

  const handleScheduleChange = (schedules: DaySchedule[]) => {
    if (schedules) {
      // On contrôle l'absence d'erreur dans le tableau de schedule
      const hasErrors = schedules.some((day) => day.intervals.some((interval) => interval.error !== null));

      Logger.info(schedules);

      if (!hasErrors) {
        // On crée un objet Schedule sur la base du DaySchedule reçu de ScheduleFields
        const newSchedules = new Array<Schedule>();
        schedules.forEach((s) => {
          // Seuls les journées actives (cochées) sont envoyées
          if (s.checked) {
            newSchedules.push(
              new Schedule(
                s.day,
                s.intervals.map(({ startTime, endTime, countryCode }) => ({
                  startTime: formatTime(startTime),
                  endTime: formatTime(endTime),
                  countryCode,
                })),
                true,
              ),
            );
          }
        });

        // On sauvegarde les objets dans le formulaire
        setSignupForm((prevForm) => ({
          ...prevForm,
          ['garageSchedules']: { ...prevForm['garageSchedules'], value: newSchedules },
        }));
      } else {
      }
    }
  };

  const validateSignupBillingStep = (): boolean => {
    const newSettingsForm: MovaSignupSettingsForm = { ...signupSettingsForm };

    // Validator pour les champs obligatoires
    newSettingsForm.subscriptionPaymentInterval = validateField(
      signupSettingsForm.subscriptionPaymentInterval,
      (value) => !!value,
      'Champ obligatoire',
    );
    newSettingsForm.commissioningPreference = validateField(
      signupSettingsForm.commissioningPreference,
      (value) => !!value,
      'Champ obligatoire',
    );

    setSignupSettingsForm(newSettingsForm);

    return !Boolean(newSettingsForm.subscriptionPaymentInterval.error || newSettingsForm.commissioningPreference.error);
  };

  const validateSignupSettingsStep = (): boolean => {
    const newSettingsForm: MovaSignupSettingsForm = { ...signupSettingsForm };

    // Validator pour les champs obligatoires
    newSettingsForm.workforce = validateField(signupSettingsForm.workforce, (value) => !!value, 'Champ obligatoire');
    newSettingsForm.slotAlgorithm = validateField(
      signupSettingsForm.slotAlgorithm,
      (value) => !!value,
      'Champ obligatoire',
    );

    // Validator pour les horaires de dépôts le cas échéant
    if (newSettingsForm.slotAlgorithm.value === SlotAlgorithm.DAY_PERIOD) {
      if (
        !(newSettingsForm.amVehicleDeposit.value instanceof Date) &&
        !(newSettingsForm.pmVehicleDeposit.value instanceof Date)
      ) {
        // On positionne l'erreur uniquement sur le créneau AM, le helper text sera valable pour les deux
        newSettingsForm.amVehicleDeposit.isValid = false;
        newSettingsForm.amVehicleDeposit.error = 'Vous devez définir au moins un horaire de dépôt véhicule';
      } else {
        newSettingsForm.amVehicleDeposit.isValid = true;
        newSettingsForm.amVehicleDeposit.error = undefined;
      }
    } else {
      newSettingsForm.amVehicleDeposit.isValid = true;
      newSettingsForm.amVehicleDeposit.error = undefined;
    }

    setSignupSettingsForm(newSettingsForm);

    return !Boolean(
      newSettingsForm.workforce.error || newSettingsForm.slotAlgorithm.error || newSettingsForm.amVehicleDeposit.error,
    );
  };

  const purgeForms = () => {
    //localStorage.removeItem('selectedGarage');
    deleteCookie(COOKIE_SELECTED_GARAGE_ID);
    setSelectedGarageId(undefined);
    setSignupForm(initialSignupFormState);
    setSignupAddressForm(initialSignupAddressFormState);
    setSignupSettingsForm(initialSignupSettingsFormState);
  };

  const validateSignupAdminStep = (): boolean => {
    const newGarageForm: MovaSignupForm = { ...signupForm };

    // Validator pour les champs obligatoires
    newGarageForm.garageName = validateField(signupForm.garageName, (value) => !!value, 'Champ obligatoire');
    newGarageForm.adminFirstname = validateField(signupForm.adminFirstname, (value) => !!value, 'Champ obligatoire');
    newGarageForm.adminLastname = validateField(signupForm.adminLastname, (value) => !!value, 'Champ obligatoire');

    /*         newGarageForm.adminBirthDate = validateField(signupForm.adminBirthDate, value => {
            // Vérifie que la valeur existe
            if (!value) return false;

            // Tente de convertir la valeur en date et vérifie si ce n'est pas une 'Invalid Date'
            const date = new Date(value);
            return !isNaN(date.getTime());
        }, 'Champ obligatoire');
        newGarageForm.gender = validateField(signupForm.gender, value => !!value, 'Champ obligatoire'); */

    newGarageForm.adminPhoneNumber = validateField(
      signupForm.adminPhoneNumber,
      (value) => !!value,
      'Champ obligatoire',
    );

    // Validator pour l'email
    if (newGarageForm.adminEmail.isValid)
      newGarageForm.adminEmail = validateField(signupForm.adminEmail, validateEmail, 'Adresse email invalide');

    // Validator 'phoneNumber'
    if (newGarageForm.adminPhoneNumber.isValid)
      newGarageForm.adminPhoneNumber = validateField(
        signupForm.adminPhoneNumber,
        validatePhoneNumber,
        'Le n° de téléphone est invalide.',
      );

    setSignupForm(newGarageForm);

    return !Boolean(
      newGarageForm.garageName.error ||
        newGarageForm.adminFirstname.error ||
        newGarageForm.adminLastname.error ||
        newGarageForm.adminEmail.error ||
        newGarageForm.adminPhoneNumber.error,
    );
  };

  const validateSignupGarageStep = (): boolean => {
    const newGarageForm: MovaSignupForm = { ...signupForm };
    const newAddressForm: MovaSignupAdressForm = { ...signupAddressForm };

    // Validator pour les champs obligatoires
    newGarageForm.garageContactEmail = validateField(
      signupForm.garageContactEmail,
      (value) => !!value,
      'Champ obligatoire',
    );
    newGarageForm.garageContactPhone = validateField(
      signupForm.garageContactPhone,
      (value) => !!value,
      'Champ obligatoire',
    );
    newAddressForm.streetName = validateField(signupAddressForm.streetName, (value) => !!value, 'Champ obligatoire');
    newAddressForm.postalCode = validateField(signupAddressForm.postalCode, (value) => !!value, 'Champ obligatoire');
    newAddressForm.cityName = validateField(signupAddressForm.cityName, (value) => !!value, 'Champ obligatoire');

    // Validator pour l'email
    if (newGarageForm.garageContactEmail.isValid)
      newGarageForm.garageContactEmail = validateField(
        signupForm.garageContactEmail,
        validateEmail,
        'Adresse email invalide',
      );

    // Validator 'phoneNumber'
    if (newGarageForm.garageContactPhone.isValid)
      newGarageForm.garageContactPhone = validateField(
        signupForm.garageContactPhone,
        validatePhoneNumber,
        'Le n° de téléphone est invalide.',
      );

    // Validator pour les horaires d'ouverture
    newGarageForm.garageSchedules = validateField(
      signupForm.garageSchedules,
      (value) => !!value && value.length > 0,
      'Vous devez au moins définir une journée de travail',
    );

    setSignupForm(newGarageForm);
    setSignupAddressForm(newAddressForm);

    return !Boolean(
      newGarageForm.garageContactEmail.error ||
        newGarageForm.garageContactPhone.error ||
        newAddressForm.streetName.error ||
        newAddressForm.cityName.error ||
        newAddressForm.postalCode.error ||
        newGarageForm.garageSchedules.error,
    );
  };

  const handleGenderChange = (e: SelectChangeEvent<string>): void => {
    const fieldName: string = e.target.name;
    const fieldValue: string = e.target.value;
    const newField: MovaFormField = { [fieldName]: { value: fieldValue, isValid: true } };

    setSignupForm({ ...signupForm, ...newField });
  };

  const handleSlotAlgorithmChange = (e: SelectChangeEvent<string>): void => {
    const fieldName: string = e.target.name;
    const fieldValue: string = e.target.value;
    const newField: MovaFormField = { [fieldName]: { value: fieldValue, isValid: true } };

    setSignupSettingsForm({ ...signupSettingsForm, ...newField });

    if (fieldValue === SlotAlgorithm.HOUR) {
      setSignupSettingsForm((prevForm) => ({
        ...prevForm,
        ['amVehicleDeposit']: { ...prevForm['amVehicleDeposit'], value: undefined },
      }));
      setSignupSettingsForm((prevForm) => ({
        ...prevForm,
        ['pmVehicleDeposit']: { ...prevForm['pmVehicleDeposit'], value: undefined },
      }));
    }
  };

  const handlePaymentIntervalChange = (e: SelectChangeEvent<string>): void => {
    const fieldName: string = e.target.name;
    const fieldValue: string = e.target.value;
    const newField: MovaFormField = { [fieldName]: { value: fieldValue, isValid: true } };

    // Si engagement mensuel on force la valeur du tabValue à 1 (onglet Amortissement)
    if (fieldValue === SubscriptionPaymentInterval.MONTH && tabValue === SubscriptionTabs.LAUNCH_OFFER) {
      setTabValue(SubscriptionTabs.ALLOWANCE);
    }

    setSignupSettingsForm({ ...signupSettingsForm, ...newField });
  };

  const handleSubscriptionChange = (value: SubscriptionPaymentInterval) => {
    setSignupSettingsForm((prevForm) => ({
      ...prevForm,
      ['subscriptionPaymentInterval']: { ...prevForm['subscriptionPaymentInterval'], value: value },
    }));
  };

  const handlePushSubscriptionCost = (value: number) => {
    setSubscriptionCost(value);
  };

  const handleDateChange = (name: string, date: Date | null) => {
    if (name && date) {
      const fieldName: string = name;
      const fieldValue: Date = date;
      const newField: MovaFormField = { [fieldName]: { value: fieldValue, isValid: true } };

      setSignupForm({ ...signupForm, ...newField });
    }
  };

  const handleSignupNext = async () => {
    switch (currentStep) {
      case SignupStep.ADMIN:
        if (validateSignupAdminStep()) {
          setCurrentStep(SignupStep.GARAGE);
          // On pré-rempli les informations de contact Garage avec celles de l'admin
          const fieldName: string = 'garageContactEmail';
          const newEmailField: MovaFormField = { [fieldName]: { value: signupForm.adminEmail.value, isValid: true } };
          /* const fieldName2: string = "garageContactPhone";
                    const newPhoneField: MovaFormField = { [fieldName2]: { value: signupForm.adminPhoneNumber.value, isValid: true } };  */
          setSignupForm({ ...signupForm, ...newEmailField });
          if (isSignupAuthorized(connectedUser)) handleSubmit(false);
        }
        break;

      case SignupStep.GARAGE:
        if (validateSignupGarageStep()) {
          setCurrentStep(SignupStep.SETTINGS);
          if (isSignupAuthorized(connectedUser)) handleSubmit(false);
        }
        break;

      case SignupStep.SETTINGS:
        if (validateSignupSettingsStep()) {
          setCurrentStep(SignupStep.BILLING);
          if (isSignupAuthorized(connectedUser)) handleSubmit(false);
        }
        break;

      case SignupStep.BILLING:
          setOpenConfirmSignupDialog(true);
          break;

      case SignupStep.SUMMARY:
        if (isSignupAuthorized(connectedUser)) {
          const response = await handleSubmit(true);

          if (response) {
            // Redirection vers le listing des inscriptions en cours
            history.push(`/registrations?key=${Date.now()}`);
            purgeForms();
            setCurrentStep(SignupStep.ADMIN);
          }
        }

        break;
    }
  };

  const handleCloseConfirmSignup = () => {
    setOpenConfirmSignupDialog(false);
  };

  const handleConfirmSignup = () => {
    setOpenConfirmSignupDialog(false);
    if (validateSignupBillingStep()) {
      setCurrentStep(SignupStep.SUMMARY);
      if (isSignupAuthorized(connectedUser)) handleSubmit(false);
    }
  };

  const handleSendMandate = async () => {
    setLoading(true);

    try {
      if (selectedGarageId) {
        // On commence par pousser les éventuelles modifications sur les données de pré-inscription
        const submit = await handleSubmit(false);

        if (submit) {
          const response = await GarageService.sendGarageMandate(selectedGarageId);

          if (!response.success || !response.data) {
            setLoading(false);
            dispatch(
              setSnackbar({ open: true, message: response.error ?? 'Une erreur est survenue', severity: 'error' }),
            );
          } else {
            setLoading(false);
            dispatch(
              setSnackbar({
                open: true,
                message: response.data ?? "Le mandat a été envoyé par email au client, il doit désormais l'accepter.",
                severity: 'success',
              }),
            );
          }
        } else {
          setLoading(false);
          dispatch(setSnackbar({ open: true, message: 'Une erreur est survenue', severity: 'error' }));
        }
      }
    } catch (error) {
      Logger.error('Error occurred during submission:', error);
      setLoading(false);
    }
  };

  const handleSignupPrev = () => {
    switch (currentStep) {
      case SignupStep.GARAGE:
        setCurrentStep(SignupStep.ADMIN);
        break;
      case SignupStep.SETTINGS:
        setCurrentStep(SignupStep.GARAGE);
        break;

      case SignupStep.BILLING:
        setCurrentStep(SignupStep.SETTINGS);
        break;

      case SignupStep.SUMMARY:
          setCurrentStep(SignupStep.BILLING);
          break;
    }
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElNav(null);
  };

  const handlePageClick = (page: string) => {
    //Fermeture du menu
    handleCloseMenu();
    if (page) {
      // Cas particulier du Logout
      switch (page) {
        case 'PRICING':
          deleteCookie(COOKIE_SELECTED_GARAGE_ID);
          // Redirection vers le formulaire d'inscription
          history.push('/pricing');
          break;
        case 'LOGOUT':
          // Suppression des cookies
          deleteCookies();
          // Suppression de l'utilisateur connecté
          setConnectedUser(undefined);
          setCurrentStep(SignupStep.ADMIN);
          purgeForms();
          // Redirection vers le login
          history.push('/login');
          break;

        case 'REGISTRATIONS':
          setCurrentStep(SignupStep.ADMIN);
          // Redirection vers le login
          history.push(`/registrations?key=${Date.now()}`);
          break;

        case 'SIGNUP':
          setCurrentStep(SignupStep.ADMIN);
          purgeForms();
          // Redirection vers le login
          //history.push('/signup?key=' + Date.now());
          break;
      }
    }
  };

  const getActionButtons = (): ReactNode => {
    return (
      <>
        {currentStep !== SignupStep.ADMIN && (
          <Button variant='contained' color='inherit' sx={{ width: '200px', mr: 4 }} onClick={handleSignupPrev}>
            <BackIcon style={{ fontSize: '1rem', marginRight: 2 }} />
            &nbsp;PRÉCÉDENT
          </Button>
        )}
        <Button
          variant='contained'
          sx={{ width: currentStep !== SignupStep.SUMMARY ? '200px' : '250px' }}
          onClick={handleSignupNext}
        >
          {currentStep === SignupStep.SUMMARY ? (
            <>
              VALIDER L'INSCRIPTION&nbsp;
              <CheckIcon style={{ fontSize: '1rem', marginLeft: 2 }} />
            </>
          ) : currentStep === SignupStep.BILLING ? (
            <>
              PASSER AU RECAP&nbsp;
              <SummaryIcon style={{ fontSize: '1rem', marginLeft: 2 }} />
            </>
          ) : (
            <>
              SUIVANT&nbsp;
              <ForwardIcon style={{ fontSize: '1rem', marginLeft: 2 }} />
            </>
          )}
        </Button>
      </>
    );
  };

  const getAddRibContainer = (): ReactNode => {
    return (
      <Container style={flexCenter}>
        <input
          accept='image/*, application/pdf'
          type='file'
          style={{ display: 'none' }}
          ref={ribInputRef}
          id='raised-button-rib'
          onChange={(e) => handleSignupSettingsChange('subscriptionRibFile', e.target.files ? e.target.files[0] : '')}
        />
        <div style={flexCenter}>
          <label htmlFor='raised-button-rib'>
            <Button
              component='span'
              color='inherit'
              disableRipple
              sx={{ backgroundColor: theme.palette.grey[200], width: '200px' }}
            >
              Joindre un RIB
            </Button>
          </label>
          {signupSettingsForm.subscriptionRibFile?.value instanceof File && (
            <IconButton onClick={(e) => handleSignupSettingsChange('subscriptionRibFile', undefined)} sx={{ ml: 1 }}>
              <Tooltip title='Supprimer le RIB'>
                <CloseIcon />
              </Tooltip>
            </IconButton>
          )}
        </div>
      </Container>
    );
  };

  const getRdvRatioCost = (): number => {
    if (subscriptionCost) {
      return subscriptionCost / (dailyInvoices * 20);
    }
    return 0;
  };

  const getSignUpForm = (): ReactNode => {
    return (
      <Box>
        {currentStep === SignupStep.ADMIN && (
          <Box>
            <Grid container columnSpacing={4} sx={{ pt: 2, pb: 2, mt: 12 }}>
              <Grid item lg={7} xs={12} order={{ xs: 2, lg: 1 }} sx={{ pr: 6 }}>
                <Grid container columnSpacing={4}>
                  <Grid item xs={12}>
                    <TextField
                      id='settings-garage-name'
                      label='NOM DU GARAGE'
                      value={signupForm.garageName.value}
                      required
                      name='garageName'
                      autoFocus
                      onChange={(e) => handleSignupChange('garageName', e.target.value)}
                      error={Boolean(signupForm.garageName.error)}
                      helperText={signupForm.garageName.error}
                      sx={{ width: '100%', mb: 3 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6' color={theme.palette.text.secondary}>
                      <b>Responsable du garage</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      margin='normal'
                      required
                      fullWidth
                      id='adminFirstname'
                      label='Prénom'
                      name='adminFirstname'
                      autoComplete='given-name'
                      onChange={(e) => handleInputChange(e)}
                      value={signupForm.adminFirstname?.value}
                      error={Boolean(signupForm.adminFirstname?.error)}
                      helperText={signupForm.adminFirstname?.error}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      margin='normal'
                      required
                      fullWidth
                      id='adminLastname'
                      label='Nom'
                      name='adminLastname'
                      autoComplete='family-name'
                      onChange={(e) => handleInputChange(e)}
                      value={signupForm.adminLastname?.value}
                      error={Boolean(signupForm.adminLastname?.error)}
                      helperText={signupForm.adminLastname?.error}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      margin='normal'
                      required
                      fullWidth
                      id='adminEmail'
                      label='Adresse email'
                      name='adminEmail'
                      autoComplete='email'
                      onChange={(e) => handleInputChange(e)}
                      value={signupForm.adminEmail?.value}
                      error={Boolean(signupForm.adminEmail?.error)}
                      helperText={signupForm.adminEmail?.error}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      type='tel'
                      margin='normal'
                      required
                      fullWidth
                      id='adminPhoneNumber'
                      label='N° de portable'
                      name='adminPhoneNumber'
                      autoComplete='tel'
                      onChange={(e) => handleInputChange(e)}
                      value={signupForm.adminPhoneNumber?.value}
                      error={Boolean(signupForm.adminPhoneNumber?.error)}
                      helperText={signupForm.adminPhoneNumber?.error}
                      placeholder='06 ...'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <GenderSelector
                      handleSelectChange={handleGenderChange}
                      form={signupForm}
                      sx={{
                        width: '100%',
                        mr: 2,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      label='Date de naissance (falcultatif)'
                      value={signupForm.adminBirthDate?.value}
                      format='dd / MM / yyyy'
                      formatDensity='dense'
                      openTo='year'
                      views={['year', 'month', 'day']}
                      onChange={(newValue) => handleDateChange('adminBirthDate', newValue)}
                      slotProps={{
                        textField: {
                          error: Boolean(signupForm.adminBirthDate?.error),
                          sx: {
                            width: '100%',
                            mt: 2,
                            float: 'right',
                            padding: 0,
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 6, mb: 2 }}>
                  <Grid item xs={12} style={flexEnd}>
                    {getActionButtons()}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                lg={5}
                order={{ xs: 1, lg: 2 }}
                sx={{
                  backgroundColor: lighten(theme.palette.primary.light, 0.7),
                  borderRadius: '24px',
                  pb: 2,
                  mx: breakDownLg ? 6 : 0,
                  mb: breakDownLg ? 6 : 0,
                }}
              >
                <Box sx={{ height: '100%' }}>
                  <Grid container style={flexCenter}>
                    <Grid item xs={6} style={flexCenter} sx={{ pt: 2 }}>
                      <img src={LogoProLarge} style={{ width: '50%' }} />
                    </Grid>
                    <Grid item xs={6}>
                      <Alert
                        severity='success'
                        icon={<br />}
                        style={flexCenter}
                        sx={{ width: '100%', textTransform: 'none', textAlign: 'center', px: 1 }}
                      >
                        <b>RESPONSABLE GARAGE</b>
                        <br />
                        <Typography variant='body2' style={flexCenter} sx={{ mt: 1 }}>
                          Étape n°1 / 4
                        </Typography>
                      </Alert>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 3, pr: 3 }}>
                      <Typography variant='h6' color={theme.palette.text.secondary}>
                        Grâce à toi, une fois ces étapes terminées l'activation du service Movalib PRO pour ton client
                        prendra 2 minutes 😊🚀 !<br />
                        <br />
                        Déroule le formulaire, valide l'abonnement <b>AVEC</b> ton client, enregistre l'inscription et
                        on s'occupe de la mise en service 🏁
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        {currentStep === SignupStep.GARAGE && (
          <Box>
            <Grid container sx={{ pt: 2, pb: 2, mt: 9 }} columnSpacing={4}>
              <Grid item lg={7} xs={12} order={{ xs: 2, lg: 1 }} sx={{ pr: 6 }}>
                <Grid item xs={12}>
                  <Typography variant='h6' color={theme.palette.text.secondary}>
                    <b>Adresse du garage</b>
                  </Typography>
                  <AddressFields
                    form={signupAddressForm}
                    size='small'
                    handleChange={(fieldName, newValue) => handleSignupAddressChange(fieldName, newValue)}
                  />
                </Grid>
                <Typography variant='h6' color={theme.palette.text.secondary} sx={{ mt: 2, mb: 3 }}>
                  <b>Coordonnées du garage</b>
                </Typography>
                <Grid item xs={12}>
                  <TextField
                    id='settings-garage-contact-email'
                    label='EMAIL'
                    value={signupForm.garageContactEmail.value}
                    required
                    name='garageContactEmail'
                    size='small'
                    onChange={(e) => handleInputChange(e)}
                    error={Boolean(signupForm.garageContactEmail?.error)}
                    helperText={signupForm.garageContactEmail.error}
                    sx={{ width: '55%' }}
                  />
                  <TextField
                    id='settings-garage-contact-phone'
                    label='N° DE TÉLÉPHONE'
                    value={signupForm.garageContactPhone.value}
                    required
                    name='garageContactPhone'
                    size='small'
                    onChange={(e) => handleInputChange(e)}
                    error={Boolean(signupForm.garageContactPhone?.error)}
                    helperText={signupForm.garageContactPhone.error}
                    sx={{ width: '40%', ml: '5%' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='h6' color={theme.palette.text.secondary} sx={{ mt: 3, mb: 3 }}>
                    <b>Horaires d'ouverture</b>
                  </Typography>
                  <ScheduleFields
                    schedules={signupForm.garageSchedules.value}
                    size='small'
                    onChange={handleScheduleChange}
                    timePickerStep={30}
                  />
                  <FormHelperText sx={{ color: theme.palette.error.main, mt: 1 }}>
                    {signupForm.garageSchedules.error}
                  </FormHelperText>
                </Grid>
                <Grid container sx={{ mt: 6, mb: 2 }}>
                  <Grid item xs={12} style={flexEnd}>
                    {getActionButtons()}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                lg={5}
                order={{ xs: 1, lg: 2 }}
                sx={{
                  backgroundColor: lighten(theme.palette.primary.light, 0.7),
                  borderRadius: '24px',
                  pb: 2,
                  mx: breakDownLg ? 6 : 0,
                  mb: breakDownLg ? 6 : 0,
                }}
              >
                <Box sx={{ height: '100%' }}>
                  <Grid container style={flexCenter}>
                    <Grid item xs={6} style={flexCenter} sx={{ pt: 2 }}>
                      <img src={LogoProLarge} style={{ width: '50%' }} />
                    </Grid>
                    <Grid item xs={6}>
                      <Alert
                        severity='success'
                        icon={<br />}
                        style={flexCenter}
                        sx={{ width: '100%', textTransform: 'none', textAlign: 'center', px: 1 }}
                      >
                        <b>DESCRIPTION DU GARAGE</b>
                        <br />
                        <Typography variant='body2' style={flexCenter} sx={{ mt: 1 }}>
                          Étape n°2 / 4
                        </Typography>
                      </Alert>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 3, pr: 3 }}>
                      <Typography variant='h6' color={theme.palette.text.secondary}>
                        Ces informations seront reprises sur la <b>fiche du garage</b>&nbsp; depuis l'application
                        Movalib qu'utiliseront les clients lors de leur prise de rendez-vous 🚘 🔧
                      </Typography>
                      <Box style={flexCenter} sx={{ mt: 4, mb: 1 }}>
                        <img src={GarageSheetImage} style={{ width: '350px' }} />
                      </Box>
                    </Grid>
                    <Grid item xs={12} style={flexCenter} sx={{ p: 0 }}>
                      <Typography sx={{ color: theme.palette.text.secondary }}>
                        <i>Exemple de fiche garage</i>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        {currentStep === SignupStep.SETTINGS && (
          <Box>
            <Grid container sx={{ pt: 2, pb: 2, mt: 9 }} columnSpacing={4}>
              <Grid item lg={7} xs={12} order={{ xs: 2, lg: 1 }} sx={{ pr: 6 }}>
                <Grid container columnSpacing={4}>
                  <Grid item xs={12}>
                    <Typography variant='h6' color={theme.palette.text.secondary}>
                      <b>Effectif à l'atelier</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id='settings-workforce'
                      label='NOMBRE DE RÉPARATEURS'
                      value={signupSettingsForm.workforce.value}
                      type='number'
                      required
                      size='small'
                      onChange={(e) => handleSignupSettingsChange('workforce', Math.max(1, Number(e.target.value)))}
                      helperText="Indiquez l'effectif à temps plein"
                      error={Boolean(signupSettingsForm.workforce.error)}
                      sx={{ width: '80%', mt: 2.5 }}
                      InputProps={{ inputProps: { min: 1 } }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6' color={theme.palette.text.secondary} sx={{ mt: 3, mb: 1 }}>
                      <b>Mode de prise de rendez-vous</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth margin='normal' required size='small'>
                      <InputLabel id='slot-algorithm-label'>CHOIX DES CRÉNEAUX</InputLabel>
                      <Select
                        labelId='slot-algorithm-label'
                        id='slotAlgorithm'
                        name='slotAlgorithm'
                        size='small'
                        label='Détermination des créneaux'
                        value={signupSettingsForm.slotAlgorithm.value}
                        onChange={(e) => handleSlotAlgorithmChange(e)}
                        error={Boolean(signupSettingsForm.slotAlgorithm.error)}
                      >
                        <MenuItem value={SlotAlgorithm.DAY_PERIOD}>
                          Le client dépose son véhicule le matin et/ou l'après-midi
                        </MenuItem>
                        <MenuItem value={SlotAlgorithm.HOUR}>Le client choisit l'heure précise</MenuItem>
                      </Select>
                      <FormHelperText sx={{ color: theme.palette.error.main }}>
                        {signupSettingsForm.slotAlgorithm.error}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  {signupSettingsForm && signupSettingsForm.slotAlgorithm.value === SlotAlgorithm.DAY_PERIOD && (
                    <Grid container columnSpacing={4} sx={{ pl: 4 }}>
                      <Grid item xs={5}>
                        <Typography variant='subtitle1' color={theme.palette.text.secondary} sx={{ mt: 1, mb: 1 }}>
                          <b>Heure de dépot matin :</b>
                        </Typography>
                        <TimePicker
                          views={['hours', 'minutes']}
                          minutesStep={30} // Valeur par défaut de 30 minutes
                          value={signupSettingsForm.amVehicleDeposit.value}
                          formatDensity='dense'
                          onChange={(value, context) => handleDepositTimeChange('amVehicleDeposit', value)}
                          slotProps={{
                            textField: {
                              size: 'small',
                              sx: {
                                width: '150px',
                                minWidth: '100px',
                                padding: 0,
                              },
                            },
                            actionBar: {
                              sx: { display: 'none' },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant='subtitle1' color={theme.palette.text.secondary} sx={{ mt: 1, mb: 1 }}>
                          <b>Heure de dépot après-midi :</b>
                        </Typography>
                        <TimePicker
                          views={['hours', 'minutes']}
                          minutesStep={30} // Valeur par défaut de 30 minutes
                          value={signupSettingsForm.pmVehicleDeposit.value}
                          formatDensity='dense'
                          onChange={(value, context) => handleDepositTimeChange('pmVehicleDeposit', value)}
                          slotProps={{
                            textField: {
                              size: 'small',
                              sx: {
                                width: '150px',
                                minWidth: '100px',
                                padding: 0,
                              },
                            },
                            actionBar: {
                              sx: { display: 'none' },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormHelperText sx={{ color: theme.palette.error.main, pl: 2 }}>
                          {signupSettingsForm.amVehicleDeposit.error}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid container sx={{ mt: 6, mb: 2 }}>
                  <Grid item xs={12} style={flexEnd}>
                    {getActionButtons()}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                lg={5}
                order={{ xs: 1, lg: 2 }}
                sx={{
                  backgroundColor: lighten(theme.palette.primary.light, 0.7),
                  borderRadius: '24px',
                  pb: 2,
                  mx: breakDownLg ? 6 : 0,
                  mb: breakDownLg ? 6 : 0,
                }}
              >
                <Box sx={{ height: '100%' }}>
                  <Grid container style={flexCenter}>
                    <Grid item xs={6} style={flexCenter} sx={{ pt: 2 }}>
                      <img src={LogoProLarge} style={{ width: '50%' }} />
                    </Grid>
                    <Grid item xs={6}>
                      <Alert
                        severity='success'
                        icon={<br />}
                        style={flexCenter}
                        sx={{ width: '100%', textTransform: 'none', textAlign: 'center', px: 1 }}
                      >
                        <b>PRISE DE RENDEZ-VOUS</b>
                        <br />
                        <Typography variant='body2' style={flexCenter} sx={{ mt: 1 }}>
                          Étape n°3 / 4
                        </Typography>
                      </Alert>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 3, pr: 3 }}>
                      <Typography variant='h6' color={theme.palette.text.secondary}>
                        Le garage <b>choisit</b> de quelle façon ses clients <b>prennent rendez-vous</b>.
                      </Typography>
                      <Grid container style={flexCenter} sx={{ mt: 4, mb: 1 }}>
                        <Grid item xs={6} style={flexCenter} direction={'column'}>
                          <Typography color={theme.palette.text.primary}>
                            <b>Horaires de dépôts (ex: 9h / 14h)</b>
                          </Typography>
                          <br />
                          <img src={DayPeriodImage} style={{ width: '270px' }} />
                        </Grid>
                        <Grid item xs={6} style={flexCenter} direction={'column'}>
                          <Typography color={theme.palette.text.primary}>
                            <b>À l'heure précise</b>
                          </Typography>
                          <br />
                          <img src={HourImage} style={{ width: '280px' }} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        {currentStep === SignupStep.BILLING && (
          <Box>
            <Grid container sx={{ pt: 2, pb: 2, mt: 24 }} columnSpacing={4}>
              <Grid item lg={6} xs={12} order={{ xs: 2, lg: 1 }} sx={{ pr: 6 }}>
                <Grid container columnSpacing={4}>
                  <Grid item xs={12}>
                    <Alert
                      severity='warning'
                      sx={{ mb: 3, borderRadius: 20, color: theme.palette.error.dark, borderWidth: 2 }}
                      variant='standard'
                      icon={<WarningIcon sx={{ color: theme.palette.error.dark }} />}
                      className='styled-warning-alert'
                    >
                      <b>À VALIDER PAR ET AVEC LE CLIENT !</b>
                    </Alert>
                  </Grid>

                  <Grid item xs={8}>
                    <Typography variant='h6' color={theme.palette.text.secondary}>
                      <b>Souscription</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ pt: 1 }}>
                    <TextField
                      id='subscription-comment'
                      label='Commentaire ...'
                      value={signupSettingsForm.subscriptionComment?.value}
                      onChange={(e) => handleSignupSettingsChange('subscriptionComment', e.target.value)}
                      multiline
                      rows={4}
                      variant='outlined'
                      size='small'
                      error={Boolean(signupSettingsForm.subscriptionComment?.error)}
                      helperText={signupSettingsForm.subscriptionComment?.error}
                      fullWidth
                      sx={{ width: '97%' }}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ pt: 2 }}>
                    <Typography variant='h6' color={theme.palette.text.secondary}>
                      <b>Mandat de prélèvement</b>
                    </Typography>
                    <Typography color={theme.palette.primary.main} sx={{ fontFamily: 'Caveat', fontSize: '1.3rem' }}>
                      <b>Votre client va recevoir un mail d'acceptation du mandat SEPA (son RIB sera nécessaire)</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={flexCenter} sx={{ mt: 2 }}>
                    <LoadingButton
                      loading={loading}
                      variant='contained'
                      color='inherit'
                      sx={{ width: '50%', mr: 4 }}
                      onClick={handleSendMandate}
                    >
                      <MandateIcon style={{ fontSize: '1rem', marginRight: 3 }} />
                      &nbsp;ENVOYER LE MANDAT AU CLIENT (EMAIL)
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={12} sx={{ pt: 4 }}>
                    <Typography variant='h6' color={theme.palette.text.secondary}>
                      <b>Préférence pour le rendez-vous de mise en service ?</b>
                    </Typography>
                    <Typography color={theme.palette.primary.main} sx={{ fontFamily: 'Caveat', fontSize: '1.3rem' }}>
                      <b>Visio d'une demi-heure avec le client, gérée par Movalib </b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ pt: 2 }}>
                    <TextField
                      id='subscription-comment'
                      label='Indique plusieurs créneaux stp ...'
                      value={signupSettingsForm.commissioningPreference?.value}
                      onChange={(e) => handleSignupSettingsChange('commissioningPreference', e.target.value)}
                      required
                      multiline
                      rows={3}
                      variant='outlined'
                      size='small'
                      error={Boolean(signupSettingsForm.commissioningPreference?.error)}
                      helperText={
                        Boolean(signupSettingsForm.commissioningPreference?.error)
                          ? signupSettingsForm.commissioningPreference?.error
                          : ''
                      }
                      fullWidth
                      sx={{ width: '97%' }}
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 6, mb: 2 }}>
                  <Grid item xs={12} style={flexEnd}>
                    {getActionButtons()}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                lg={6}
                order={{ xs: 1, lg: 2 }}
                sx={{
                  backgroundColor: lighten(theme.palette.primary.light, 0.7),
                  borderRadius: '24px',
                  pb: 2,
                  mx: breakDownLg ? 6 : 0,
                  mb: breakDownLg ? 6 : 0,
                  mt: breakDownLg ? 34 : 0,
                }}
              >
                <PricingTable
                  defaultWorkforce={signupSettingsForm.workforce.value}
                  onWorkforceChange={handleWorkforceChange}
                  defaultSubscription={signupSettingsForm.subscriptionPaymentInterval.value}
                  onWSubscriptionChange={handleSubscriptionChange}
                  pushSubscriptionCost={handlePushSubscriptionCost}
                />
            </Grid>
            </Grid>
          </Box>
        )}

        {currentStep === SignupStep.SUMMARY && (
          <Box>
            <Grid container sx={{ pb: 2, mt: 12 }}>
              <Grid item xs={12}>
                <Grid item xs={12} sx={{ backgroundColor: 'transparen', borderRadius: '24px', pb: 2 }}>
                  <Box>
                    <Grid container style={flexCenter}>
                      <Grid item xs={6} style={flexCenter} sx={{ pt: 2 }}>
                        <img src={LogoProLarge} style={{ width: '30%' }} />
                      </Grid>
                      <Grid item xs={6}>
                        <Alert
                          severity='success'
                          style={flexCenter}
                          icon={<SummaryIcon />}
                          className='styled-alert'
                          sx={{ width: '80%', textTransform: 'none', textAlign: 'center', px: 1, mt: 2 }}
                        >
                          <b>RÉCAPITULATIF</b>
                        </Alert>
                      </Grid>
                      <Grid item xs={5} style={flexEnd} sx={{ pr: 6, mt: 4 }}>
                        <Typography variant='h6' color={theme.palette.text.secondary}>
                          <b>Administrateur du compte</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={7} sx={{ mt: 4 }}>
                        <Typography variant='body1' color={theme.palette.text.secondary}>
                          <b>
                            {signupForm.adminFirstname?.value}&nbsp;&nbsp;{signupForm.adminLastname?.value}
                          </b>
                          <br />
                          {signupForm.adminBirthDate?.value && signupForm.adminBirthDate?.value instanceof Date && (
                            <>
                              Né(e) le :{' '}
                              <b>
                                {formatDateByCountryCode(
                                  signupForm.adminBirthDate?.value,
                                  'FR',
                                  DateFormatTypes.SHORT_FORMAT_DATE,
                                )}
                              </b>
                              <br />
                            </>
                          )}
                          N° de téléphone : <b>{formatPhoneNumber(signupForm.adminPhoneNumber?.value)}</b>
                          <br />
                          Email de connexion : <b>{signupForm.adminEmail?.value}</b>
                          <br />
                        </Typography>
                      </Grid>
                      <Grid item xs={10} sx={{ border: '1px solid #F29ABA', mt: 3 }} />
                      <Grid item xs={5} style={flexEnd} sx={{ pr: 6, mt: 4 }}>
                        <Typography variant='h6' color={theme.palette.text.secondary}>
                          <b>Description du garage</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={7} sx={{ mt: 4 }}>
                        <Typography variant='body1' color={theme.palette.text.secondary}>
                          <b>{signupForm.garageName?.value}</b>
                          <br />
                          <Typography variant='body1' color='text.secondary'>
                            {signupAddressForm && (
                              <>
                                <b>
                                  {signupAddressForm.streetName && `${signupAddressForm.streetName.value} `} <br />
                                  {signupAddressForm.additional && `${signupAddressForm.additional.value} `}
                                  {signupAddressForm.postalCode && `${signupAddressForm.postalCode.value} `}
                                  {signupAddressForm.cityName && `${signupAddressForm.cityName.value}`}
                                </b>
                              </>
                            )}
                          </Typography>
                          <div style={{ paddingBottom: 10 }}>
                            Contact (email / tel) :{' '}
                            <b>
                              {signupForm.garageContactEmail?.value} /{' '}
                              {formatPhoneNumber(signupForm.garageContactPhone?.value)}{' '}
                            </b>
                            <br />
                          </div>
                          <div style={{ paddingBottom: 10 }}>
                            Horaires d'ouverture : <br />
                          </div>
                          {Object.keys(DayOfWeek).map((day, index) => (
                            <Typography variant='body1' sx={{ textAlign: 'left' }}>
                              <b>
                                {signupForm.garageSchedules.value &&
                                  getFormattedSchedule(
                                    findScheduleByDayOfWeek(signupForm.garageSchedules.value, index),
                                    index,
                                  )}
                              </b>
                            </Typography>
                          ))}
                          <br />
                        </Typography>
                      </Grid>
                      <Grid item xs={10} sx={{ border: '1px solid #F29ABA', mt: 1 }} />
                      <Grid item xs={5} style={flexEnd} sx={{ pr: 6, mt: 4 }}>
                        <Typography variant='h6' color={theme.palette.text.secondary}>
                          <b>Configuration du garage</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={7} sx={{ mt: 4 }}>
                        <Typography variant='body1' color={theme.palette.text.secondary}>
                          Effectif à l'atelier : <b>{signupSettingsForm.workforce?.value}</b>
                          <br />
                          Mode de prise de rendez-vous :{' '}
                          {signupSettingsForm.slotAlgorithm?.value === SlotAlgorithm.DAY_PERIOD ? (
                            <>
                              <b> Le client dépose son véhicule le matin et/ou l'après-midi &nbsp;&nbsp;- </b>
                              {signupSettingsForm.amVehicleDeposit?.value && (
                                <>
                                  &nbsp;&nbsp;(
                                  <b>
                                    {formatDateByCountryCode(
                                      signupSettingsForm.amVehicleDeposit?.value,
                                      'FR',
                                      DateFormatTypes.TIME_ONLY,
                                    )}
                                    )
                                  </b>
                                </>
                              )}
                              {signupSettingsForm.pmVehicleDeposit?.value && (
                                <>
                                  &nbsp;&nbsp;/&nbsp;&nbsp; (
                                  <b>
                                    {formatDateByCountryCode(
                                      signupSettingsForm.pmVehicleDeposit?.value,
                                      'FR',
                                      DateFormatTypes.TIME_ONLY,
                                    )}
                                    )
                                  </b>
                                </>
                              )}
                            </>
                          ) : (
                            <b>Le client choisit l'heure précise</b>
                          )}{' '}
                          <br />
                        </Typography>
                      </Grid>
                      <Grid item xs={10} sx={{ border: '1px solid #F29ABA', mt: 4 }} />
                      <Grid item xs={5} style={flexEnd} sx={{ pr: 6, mt: 4 }}>
                        <Typography variant='h6' color={theme.palette.text.secondary}>
                          <b>Souscription abonnement</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={7} sx={{ mt: 4 }}>
                        <Typography variant='body1' color={theme.palette.text.secondary}>
                          {signupSettingsForm.subscriptionPaymentInterval?.value === 'QUARTER' && (
                            <Typography sx={{ marginBottom: 2, fontWeight: 'bold' }}>
                              🎉&nbsp;&nbsp;Offre de lancement (1er mois offert)&nbsp;&nbsp;🎉
                            </Typography>
                          )}
                          Tarif de l'abonnement :{' '}
                          <b>
                            {subscriptionCost &&
                              new Intl.NumberFormat('fr-FR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(subscriptionCost)}{' '}
                            € /mois
                          </b>{' '}
                          <br />
                          Engagement :{' '}
                          <b>
                            {signupSettingsForm.subscriptionPaymentInterval?.value === 'MONTH' ? (
                              <>Mensuel</>
                            ) : signupSettingsForm.subscriptionPaymentInterval?.value === 'YEAR' ? (
                              <>Annuel (20% de remise)</>
                            ) : (
                              <>24 Mois (30% de remise)</>
                            )}
                          </b>
                          <br />
                          {signupSettingsForm.subscriptionComment.value && (
                            <>
                              Commentaire : <b>{signupSettingsForm.subscriptionComment.value}</b>
                            </>
                          )}
                          {signupSettingsForm.commissioningPreference.value && (
                            <>
                              <br />
                              Préférence mise en service : <b>{signupSettingsForm.commissioningPreference.value}</b>
                            </>
                          )}
                        </Typography>
                      </Grid>
                      <Alert
                        icon={<br />}
                        style={flexCenter}
                        severity='success'
                        sx={{ mt: 6, width: '90%', textAlign: 'center', fontSize: 18 }}
                        className='styled-alert'
                      >
                        Et voilà, tu n'as plus rien à faire, Movalib s'occupe de la <b>mise en service</b>. <br />
                        Merci, beau boulot partenaire 😉
                      </Alert>
                      <Grid item xs={12} style={flexCenter} sx={{ mt: 6, pb: 2 }}>
                        {getActionButtons()}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      <div style={flexCenter}>
        <img
          src={GreenLeafImage}
          style={{
            position: 'fixed',
            float: 'left',
            width: '250px',
            height: '400px',
            top: '-20%',
            left: '0%',
            opacity: '0.3',
            display: connectedUser ? 'block' : 'none',
            zIndex: -8,
          }}
          alt='Feuille Verte Movalib'
        />

        <Container
          component='main'
          maxWidth='xl'
          style={
            currentStep !== SignupStep.GARAGE &&
            currentStep !== SignupStep.SETTINGS &&
            currentStep !== SignupStep.SUMMARY
              ? centered
              : {}
          }
        >
          <CssBaseline />

          {connectedUser && isSignupAuthorized(connectedUser) && (
            <>
              <SignUpHeader
                title={selectedGarageId ? `${signupForm.garageName.value}` : 'Nouvelle inscription'}
                connectedUser={connectedUser}
                onPageClick={handlePageClick}
              />
              {getSignUpForm()}
            </>
          )}
        </Container>

        <img
          src={PinkLeafImage}
          style={{
            position: 'fixed',
            float: 'right',
            width: '250px',
            height: '400px',
            bottom: '-20%',
            right: '0%',
            opacity: '0.3',
            display: connectedUser ? 'block' : 'none',
            zIndex: '-10',
          }}
          alt='Feuille Rose Movalib'
        />
      </div>

      <ConfirmationDialog
        open={openConfirmSignupDialog}
        onClose={handleCloseConfirmSignup}
        onConfirm={handleConfirmSignup}
        message={
          <Grid container style={flexCenter}>
            <Grid item xs={12}>
              <ConfirmIcon style={{ fontSize: '2.5rem', color: darken(theme.palette.primary.main, 0.1) }} />
            </Grid>
            <Typography variant='h6'>
              Tu confirmes que <b>le mandat a bien été accepté</b> par le client ?
            </Typography>
          </Grid>
        }
      />
    </>
  );
};

export default SignUp;
