import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-big-calendar';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
import { startCase } from 'lodash';
import { format, startOfDay } from 'date-fns';
import { fr } from 'date-fns/locale';
import { startOfWeek, addDays } from 'date-fns';
import { DEFAULT_END_OF_WEEK, DEFAULT_CALENDAR_STEP } from '../../helpers/Constants';
import { isDayInSchedules } from './MyCalendarEmployeeView';
import { connect } from 'react-redux';
class GarageSingleton {
  // Stockage statique pour l'instance unique
  static instance = null;

  // Garage initialisé à null ou à une valeur par défaut
  garage = null;

  // Fonction pour obtenir l'instance du singleton
  static getInstance() {
    if (!GarageSingleton.instance) {
      GarageSingleton.instance = new GarageSingleton();
    }
    return GarageSingleton.instance;
  }

  // Méthode pour définir le garage
  setGarage(garage) {
    this.garage = garage;
  }

  // Méthode pour obtenir le garage
  getGarage() {
    return this.garage;
  }
}
class MyCalendarFourDaysView extends React.Component {
  render() {
    const { garage, date, culture, localizer } = this.props;
    const range = getRange(date, garage, culture);
    GarageSingleton.getInstance().setGarage(garage);
    localizer.garage = garage;
    return (
      <TimeGrid
        // navigate={navigate(date, 0, { localizer})}
        {...this.props}
        range={range}
        eventOffset={DEFAULT_CALENDAR_STEP}
        localizer={localizer}
        scrollToTime={localizer.startOf(new Date(), 'day')}
      />
    );
  }
}


const mapStateToProps = (state) => ({
  garage: state.garage,
});
MyCalendarFourDaysView.navigate = (date, action, { localizer }) => {
  const garage = GarageSingleton.getInstance().getGarage();
  let tmpDate = date;
  let cpt = 0;

  switch (action) {
    case Navigate.PREVIOUS:
      while (cpt < 4) {
        tmpDate = addDays(tmpDate, -1); // Décrémente la date d'un jour
        if (garage?.garage && garage?.garage?.schedules !== undefined) {
          if (isDayInSchedules(tmpDate, garage.garage.schedules)) {
            cpt++;
          }
        } else {
          cpt = 4;
          tmpDate = addDays(tmpDate, -4);
        }
      }

      return tmpDate; // Retourne la date correspondant au 4ème jour ouvré précédent
    case Navigate.NEXT:
      while (cpt < 4) {
        if (garage?.garage && garage?.garage?.schedules !== undefined) {
          if (isDayInSchedules(tmpDate, garage.garage.schedules)) {
            cpt++;
          }
          tmpDate = addDays(tmpDate, 1);
        } else {
          cpt = 4;
          tmpDate = addDays(tmpDate, 4);
        }
      }
      return tmpDate;
    default:
      return date;
  }
};

const getRange = (date, garage, culture) => {
  // Par défaut, on ajoute toute la semaine
  let current = new Date(date.setHours(0, 0, 0, 0));
  const range = [];

  // Ensuite on boucle sur chaque journée
  while (range.length < 4) {
    // Si on ne l'a trouve pas dans les schedules du garage, on la filtre
    if (garage.garage && garage.garage.schedules !== undefined) {
      if (isDayInSchedules(current, garage.garage.schedules)) {
        range.push(current);
      }
    } else {
      range.push(current);
    }

    current = addDays(current, 1);
  }

  return range;
};

MyCalendarFourDaysView.title = (date) => startCase(format(startOfDay(date), 'eeee dd MMMM', { locale: fr }));
export default connect(mapStateToProps)(MyCalendarFourDaysView);
