import type { Customer } from '@movalib/movalib-commons';
import CloseIcon from '@mui/icons-material/CloseRounded';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import invariant from 'invariant';
import { memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useBoolState } from '../../../helpers/hooks/useBoolState';
import { useFetchAdministratedGarages, useGarageAddCustomerVehicle } from '../../../query/garage/GarageQuery';
import { setSnackbar } from '../../../slices/snackbarSlice';
import theme from '../../../theme';
import { VehicleForm } from './vehicleForm';
import { LoadingButton } from '@mui/lab';



type AddVehicleProps = {
  customer: Customer;
};

export const AddVehicle = memo(({ customer }: AddVehicleProps) => {
  const dispatch = useDispatch();
  const { isAddVehicleModalOpen, toggleAddVehicleModalOpen } = useBoolState(false, 'addVehicleModalOpen');

  const { data: garage } = useFetchAdministratedGarages();

  const { mutateAsync: createVehicle } = useGarageAddCustomerVehicle();
  const [isPending, setIsPending] = useState<boolean>(false);
  const [foundVehicle, setFoundVehicle] = useState<string>('');
  const [vehicleForm, setVehicleForm] = useState<VehicleForm>();

  const toggleIsPending = (e: boolean) => {
    setIsPending(e);
  }
  const toggleFoundVehicle = (e: string) => {
    setFoundVehicle(e);
  }

  const onSubmitForm = useCallback(async (values: VehicleForm) => {
    invariant(garage?.id, 'Garage ID is not defined');
    const { plate, averageMileagePerYear, currentMileage, tireInfos } = values;
      
    // Send to API
    const response = await createVehicle({
      garageId: garage.id ?? '',
      customerId: customer.id,
      plate,
      currentMileage,
      averageMileagePerYear,
      tireWidth: tireInfos.width || undefined,
      tireHeight: tireInfos.height || undefined,
      tireDiameter: tireInfos.diameter || undefined,
      tireSpeedIndex: tireInfos.speedIndex || undefined,
    });

    if (response.success) {
      dispatch(
        setSnackbar({
          open: true,
          message: response.data ?? 'Véhicule ajouté au fichier client !',
          severity: 'success',
        }),
      );
      toggleAddVehicleModalOpen();
    } else {
      dispatch(
        setSnackbar({ open: true, message: response.error ?? "Erreur lors de l'ajout du véhicule", severity: 'error' }),
      );
    }
  }, [createVehicle, customer.id, toggleAddVehicleModalOpen, garage?.id, dispatch]);

  return (
    <>
      <Button variant='contained' size='small' color='primary' sx={{ alignSelf: 'start', mt: 2 }} onClick={toggleAddVehicleModalOpen}>
        Ajouter un véhicule
      </Button>
      <Dialog open={isAddVehicleModalOpen} maxWidth='sm' scroll='body' onClose={toggleAddVehicleModalOpen} fullWidth>
        <Toolbar
          disableGutters
          variant='dense'
          sx={{
            minHeight: 3,
            backgroundColor: theme.palette.grey[200],
            py: 0,
          }}
        >
          <DialogTitle sx={{ flexGrow: 1 }} component={'div'}>
            <Typography
              py={0}
              my={0}
              sx={{
                pl: '34px',
                color: theme.palette.text.primary,
                textAlign: 'center',
              }}
            >
              <span>
                AJOUTER <b>UN VÉHICULE</b>
              </span>
            </Typography>
          </DialogTitle>
          <Tooltip title='Fermer'>
            <IconButton sx={{ mr: 1 }} size='small' aria-label='close' onClick={toggleAddVehicleModalOpen}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <DialogContent>
        <VehicleForm onCancelForm={toggleAddVehicleModalOpen} onFormChange={(e) => setVehicleForm(e)} onSubmitForm={onSubmitForm} toggleIsPending={toggleIsPending} toggleFoundVehicle={toggleFoundVehicle}/>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: theme.palette.grey[200],
            justifyContent: 'space-between',
          }}
        >
          <Button variant='contained' color='inherit' onClick={() => toggleAddVehicleModalOpen()}>
            Annuler
          </Button>
          <LoadingButton
            onClick={()=> onSubmitForm(vehicleForm!)}
            disabled={!foundVehicle}
            variant='contained'
            color='primary'
            loading={isPending}
          >
            Enregistrer
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
});
