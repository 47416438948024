import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Typography,
  darken,
} from '@mui/material';
import { useFormik } from 'formik';
import { type FC, useState } from 'react';
import * as Yup from 'yup';
import theme from '../../theme';

import type { Event, Garage } from '@movalib/movalib-commons';
import { flexCenter } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import { CloseRounded, DeleteOutline, NotesTwoTone, SaveOutlined, TitleOutlined } from '@mui/icons-material';
import EventScheduleIcon from '@mui/icons-material/AccessTimeFilledRounded';
import PrivateIcon from '@mui/icons-material/VisibilityOffRounded';
import { useDispatch } from 'react-redux';
import DateTimePickerGroup from '../../components/DateTimePickerGroup';
import EmployeeChoice from '../../components/calendar/EmployeeChoice';
import { MyEventDialog } from '../../components/calendar/MyCalendarTypes';
import { flexStart, formatLocalDateToISOString } from '../../helpers/Tools';
import { useEditGarageEvent } from '../../query/event/EventQuery';
import { useFetchAdministratedGarages } from '../../query/garage/GarageQuery';
import GarageService from '../../services/GarageService';
import { setSnackbar } from '../../slices/snackbarSlice';
import ConfirmationDialog from '../ConfirmationDialog';
import CustomTextarea from '../../components/CustomTextArea';

type EventUnavailabilityDialogProps = {
  refetchEvents: () => void;
  garageId: Garage['id'];
  open: boolean;
  onClose: () => void;
  event: Event;
};

const formSchema = Yup.object({
  title: Yup.string()
    .min(3, 'Le titre doit contenir au minimum 3 caractères')
    .required("L'évènement doit avoir un titre"),
  notes: Yup.string().optional().nullable(),
  startDate: Yup.date(),
  endDate: Yup.date(),
});

type Form = Yup.InferType<typeof formSchema>;

export const EventUnavailabilityDialog: FC<EventUnavailabilityDialogProps> = ({
  garageId,
  open,
  event,
  refetchEvents,
  onClose,
}) => {
  const { data: garage } = useFetchAdministratedGarages();
  const { mutateAsync: updateGarageEvent } = useEditGarageEvent();
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleDeletePopup = () => {
    setOpenConfirmDelete((previousValue) => !previousValue);
  };

  const dispatch = useDispatch();

  const onSubmitForm = (values: Form) => {
    updateGarageEvent({
      garageId,
      eventId: event.id,
      req: {
        ...values,
        startDate: formatLocalDateToISOString(values.startDate!),
        endDate: formatLocalDateToISOString(values.endDate!),
      },
    }).then((response) => {
      if (response.success) {
        refetchEvents();
        onClose();
      }
    });
  };

  const onDeleteEvent = () => {
    // Let's close the confirmation dialog
    handleDeletePopup();

    GarageService.deleteGarageEvent(dispatch, garageId, event.id)
      .then((response) => {
        // Affichage notification utilisateur
        dispatch(setSnackbar({ open: true, message: response, severity: 'success' }));
        refetchEvents();
        onClose();
      })
      .catch((error) => {
        console.error(error);
        dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
      });
  };

  const { isValid, values, errors, setFieldValue, handleChange, handleSubmit } = useFormik<Form>({
    initialValues: {
      title: event.title,
      notes: event.notes,
      startDate: event.start,
      endDate: event.end,
    },
    validationSchema: formSchema,
    onSubmit: onSubmitForm,
    enableReinitialize: true,
  });

  return (
    <>
      <Dialog scroll='body' open={open} sx={{ p: 0 }} onClose={onClose} maxWidth='sm' fullWidth>
        <Box>
          <Toolbar
            disableGutters
            sx={{
              display: 'block',
              minHeight: 3,
              p: 0,
            }}
          >
            <DialogTitle p={1}>
              <Box position='relative' style={flexCenter}>
                <Typography
                  variant='h6'
                  sx={{
                    pr: 2,
                    pl: 2,
                    textTransform: 'uppercase',
                    fontStyle: 'bold',
                    textAlign: 'center',
                    borderRadius: 4,
                    borderRight: `7px solid ${theme.palette.text.secondary}`,
                    borderLeft: `7px solid ${theme.palette.text.secondary}`,
                    color: darken(theme.palette.text.secondary, 0.1),
                  }}
                >
                  <b>Indisponibilité</b>
                </Typography>

                <IconButton
                  sx={{ position: 'absolute', right: 0, top: 0, zIndex: 1 }}
                  size='small'
                  aria-label='close'
                  onClick={onClose}
                  title={'Fermer'}
                >
                  <CloseRounded />
                </IconButton>
              </Box>
            </DialogTitle>
          </Toolbar>

          <form onSubmit={handleSubmit}>
            <DialogContent
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              {/** Affichage, choix du réparateur */}
              {garage && (
                <Box display='flex' gap={2} alignItems='center' sx={{ width: '100%' }}>
                  <EmployeeChoice
                    localEvent={event}
                    garage={garage}
                    type={MyEventDialog.DETAILS}
                    refreshEvent={refetchEvents}
                    employee={event.resourceId ? String(event.resourceId) : ''}
                  />
                </Box>
              )}
              <Box display='flex' gap={2} alignItems='center'>
                <EventScheduleIcon sx={{ color: theme.palette.grey[500] }} />
                <Grid item sx={{ mt: 1 }}>
                  <DateTimePickerGroup
                    dateLabel='Début'
                    timeLabel='Heure'
                    value={values.startDate}
                    onChange={(newValue) => setFieldValue('startDate', newValue)}
                    marginBottom={2}
                  />
                  <DateTimePickerGroup
                    dateLabel='Fin'
                    timeLabel='Heure'
                    value={values.endDate}
                    onChange={(newValue) => setFieldValue('endDate', newValue)}
                  />
                </Grid>
              </Box>

              <Box display='flex' gap={2} alignItems='center'>
                <TitleOutlined sx={{ color: theme.palette.grey[500] }} />
                <TextField
                  id='title'
                  name='title'
                  label="Titre de l'indisponibilité"
                  value={values.title}
                  error={!!errors.title}
                  helperText={errors.title || undefined}
                  onChange={handleChange}
                  variant='outlined'
                  size='small'
                  fullWidth
                  sx={{
                    mt: 1,
                    width: '97%',
                    '& input': { fontWeight: 'bold' }, // CSS pour modifier le style dans l'input
                  }}
                />
              </Box>
              <Box display='flex' gap={2} alignItems='center'>
                <NotesTwoTone sx={{ color: theme.palette.grey[500] }} />
                <CustomTextarea
                  id='notes'
                  name='notes'
                  value={values.notes || ''}
                  onChange={handleChange}
                  error={errors.notes}
                  helperText={
                    errors.notes ? (
                      errors.notes
                    ) : (
                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <PrivateIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                        Non visible par le client
                      </Box>
                    )
                  }
                />
              </Box>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Button variant='contained' color='error' onClick={handleDeletePopup} startIcon={<DeleteOutline />}>
                Supprimer
              </Button>
              <Button disabled={!isValid} variant='contained' type='submit' startIcon={<SaveOutlined />}>
                Enregistrer
              </Button>
            </DialogActions>
          </form>
        </Box>
      </Dialog>

      {openConfirmDelete && (
        <ConfirmationDialog
          open={openConfirmDelete}
          onClose={handleDeletePopup}
          onConfirm={onDeleteEvent}
          message={'Êtes-vous sûr de vouloir supprimer cette indisponibilité ?'}
        />
      )}
    </>
  );
};
