import type { NavigateAction } from 'react-big-calendar';
import Hammer, { DIRECTION_ALL } from 'hammerjs';
import { useEffect } from 'react';

export const useSwipeNavigation = (onNavigate: (direction: NavigateAction) => void) => {
  useEffect(() => {
    if (window.innerWidth < 900) {
      const containerListenEvent = document.getElementsByClassName('my-calendar')[0];
      const verticalScrollContainer = document.getElementsByClassName('scrollable-container')[0];
      const horizontalScrollContainer = document.getElementsByClassName('rbc-time-content')[0];
      const hammer = new Hammer(containerListenEvent as HTMLElement);
      hammer.get('pan').set({ direction: DIRECTION_ALL, enable: true });
      let lastDeltaX = 0;
      let lastDeltaY = 0;
      let blockNavigation = false;
      if (!containerListenEvent) { return; }

      const handleApplyInertia = (velocity: number, direction: 'left' | 'right' | 'up' | 'down') => {
        const finalVelocity = velocity; // Capture la vitesse finale du mouvement
        const initialSpeed = Math.abs(finalVelocity) * 10;
        let speed = initialSpeed;
        const friction = 0.9; 
        const minSpeed = 0.1;

        const applyInertia = () => {
          if (speed > minSpeed) {
            if(direction === 'right') { horizontalScrollContainer.scrollLeft -= speed; }
            if(direction === 'left')  { horizontalScrollContainer.scrollLeft += speed }
            if(direction === 'down')  { verticalScrollContainer.scrollTop -= speed }
            if(direction === 'up')  { verticalScrollContainer.scrollTop += speed }

            speed *= friction; 
            requestAnimationFrame(applyInertia); 
          } else {
            blockNavigation = false;
          }
        };
        applyInertia(); 
        lastDeltaX = 0;
        lastDeltaY = 0;
      };
      hammer.on('panend', () => {
        blockNavigation = false;
      });

      hammer.on('pandown', (e) => {
        blockNavigation = true;
        const deltaXChange = e.deltaY - lastDeltaY;
        lastDeltaY = e.deltaY;
        verticalScrollContainer.scrollTop -= deltaXChange;
        if(e.isFinal) {
          handleApplyInertia(e.velocityY, 'down');
        }
      });

      hammer.on('panup', (e) => {
        blockNavigation = true;
        const deltaXChange = Math.abs(e.deltaY) - Math.abs(lastDeltaY);
        lastDeltaY = e.deltaY;
        verticalScrollContainer.scrollTop += deltaXChange;
        if(e.isFinal) {
          handleApplyInertia(e.velocityY, 'up');
        }
      });
 
      hammer.on('panleft', (e) => {
        const horizontal = e.deltaX < -100 && Math.abs(e.deltaX) > Math.abs(e.deltaY);
        if (horizontalScrollContainer) {
          const remainingScroll =
            horizontalScrollContainer.scrollLeft +
            horizontalScrollContainer.clientWidth -
            horizontalScrollContainer.scrollWidth;
          const atEndScroll = remainingScroll < 1 && remainingScroll > -1;
          if (e.direction === 2 && atEndScroll && horizontal && !blockNavigation) {
            onNavigate('NEXT');
          } else if (!atEndScroll) {
            blockNavigation = true;
            const deltaXChange = Math.abs(e.deltaX) - Math.abs(lastDeltaX);
            lastDeltaX = e.deltaX;
            horizontalScrollContainer.scrollLeft += deltaXChange;
            if (e.isFinal) {
              handleApplyInertia(e.velocityX, 'left');
            }
          }
        } else if (e.direction === 2) {
          onNavigate('NEXT');
        }
      });

      hammer.on('panright', (e) => {
        const horizontal = e.deltaX > Math.abs(e.deltaY) && e.deltaX > 100;
        if (horizontalScrollContainer) {
          const atStartScroll = horizontalScrollContainer.scrollLeft === 0;
          if (e.direction === 4 && atStartScroll && horizontal && !blockNavigation) {
            onNavigate('PREV');
          } else if (!atStartScroll) {
            blockNavigation = true;

            const deltaXChange = e.deltaX - lastDeltaX;
            lastDeltaX = e.deltaX;
            horizontalScrollContainer.scrollLeft -= deltaXChange;

            if (e.isFinal) {
              handleApplyInertia(e.velocityX, 'right');
            }
          }
        } else if (e.direction === 4) {
          onNavigate('PREV');
        }
      });

      return () => {
        hammer.off('panleft');
        hammer.off('panright');
        hammer.off('pandown');
        hammer.off('panup');
        hammer.off('pan');
        hammer.destroy(); // Détruire l'instance pour éviter les fuites de mémoire
      };
    }
  }, [onNavigate]);
};

// Case for DnD on touch, but not working, i need to override the drag and drop of react-big-calendar
/*
useEffect(() => {
  if (calendarRef.current) {
    const hammer = new Hammer(calendarRef.current as HTMLElement);
    hammer.get('press').set({ enable: true, time: 20 });
    hammer.get('pan').set({ enable: true, threshold: 2 });
    

    let currentEventMoved: HTMLElement | null = null;
    hammer.on('press', (e) => {
      const eventElement = e.target.closest('.rbc-addons-dnd-resizable') as HTMLElement;
      if (eventElement) {
        currentEventMoved = eventElement;
        const mouseDownEvent = new MouseEvent('mousedown', {
          bubbles: true,
          cancelable: true,
          clientX: e.center.x,
          clientY: e.center.y,
        });
        eventElement.dispatchEvent(mouseDownEvent);
        const observer = new MutationObserver((mutations) => {
          const dragPreview = document.getElementsByClassName('rbc-addons-dnd-drag-preview')[0];
          if (dragPreview) {
            const mouseDownEvent = new MouseEvent('mousedown', {
              bubbles: true,
              cancelable: true,
              clientX: e.center.x,
              clientY: e.center.y,
            });
              dragPreview.dispatchEvent(mouseDownEvent);
            console.log(dragPreview); // Élement trouvé
            observer.disconnect(); // Déconnecter l'observateur une fois l'élément trouvé
    
            // TODO
            
          }
        });
    
        // Observer le document ou une partie spécifique
        observer.observe(document.body, {
          childList: true,
          subtree: true,
        });
      } else {
        currentEventMoved = null;
      }
    });
    hammer.on('panmove', (e) => {
      // if (currentEventMoved) {
      //   // console.log(currentEventMoved.parentElement?.parentElement);
      //   console.log(currentEventMoved.closest('.rbc-events-container'));
      //   const dragPreview = document.getElementsByClassName('rbc-addons-dnd-drag-preview')[0];
      //   console.log(dragPreview);
      //   const touch = new Touch({
      //     identifier: Date.now(), // Un identifiant unique
      //     target: dragPreview, // Élement cible
      //     clientX: e.center.x,
      //     clientY: e.center.y,
      //     pageX: e.center.x,
      //     pageY: e.center.y,
      //     force: 0, // Ajustez en fonction de vos besoins
      //     radiusX: 0,
      //     radiusY: 0,
      //     rotationAngle: 0,
      //   });
        
      //                 const touchStartEvent = new TouchEvent('touchstart', {
      //                   bubbles: true,
      //                   cancelable: true,
      //                   touches: [touch],
      //                   targetTouches: [],
      //                   changedTouches: [touch],
      //                 });
      //                   dragPreview.dispatchEvent(touchStartEvent);
      //                 console.log(dragPreview); // Élement trouvé
      //   // const containerEvent = currentEventMoved.closest('.rbc-events-container');
      //   // const event = currentEventMoved.closest('.rbc-event') as HTMLElement;
      //   // const currentX = e.center.x;
      //   // const currentY = e.center.y;

      //   // Calculer le déplacement horizontal (dx) et vertical (dy)
      //   // const dx = currentX - initialX;
      //   // const dy = currentY - initialY;

      //   // Récupérer les styles actuels de l'élément pour ajuster la position
      //   // const currentLeft = Number.parseFloat(event.style.left) || 0;
      //   // const currentTop = Number.parseFloat(event.style.top) || 0;

      //   // Appliquer le nouveau déplacement basé sur dx et dy
      //   // const newLeft = currentLeft + dx;
      //   // const newTop = currentTop + dy;

      //   // Mettre à jour les propriétés CSS de `currentEventMoved`
      //   // event.style.left = `${newLeft}px`;
      //   // event.style.top = `${newTop}px`;

      //   // Mettre à jour les coordonnées initiales pour le prochain calcul
      //   // initialX = currentX;
      //   // initialY = currentY;

      //   // const pointerMoveEvent = new PointerEvent('mousemove', {
      //   //   bubbles: true,
      //   //   cancelable: true,
      //   //   clientX: e.center.x,
      //   //   clientY: e.center.y,
      //   //   pointerId: 1,
      //   // });
      //   // currentEventMoved.dispatchEvent(pointerMoveEvent);
      // }
    });

    hammer.on('panend', (e) => {
      if (currentEventMoved) {
        const touchEndEvent = new TouchEvent('touchend', {
          bubbles: true,
          cancelable: true,
          touches: [],
          targetTouches: [],
          changedTouches: [],
          altKey: false,
        });
        e.target.closest('.rbc-events-container')!.dispatchEvent(touchEndEvent);
      }
      currentEventMoved = null;
    });

    return () => {
      hammer.off('panend');
      hammer.off('pan');
      hammer.off('pressup');
      hammer.off('press');
      hammer.destroy(); // Détruire l'instance pour éviter les fuites de mémoire
    };
  }
}, [calendarRef.current]);
*/
// export cont useDnDEvent
