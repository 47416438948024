import { Event, EventState, EventType, type Garage } from '@movalib/movalib-commons';
import { findEarliestScheduleTime } from '../DateUtils';

const daysOfWeek = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

type AddLunchTimeUnavailabilityProps = {
  events: Event[];
  garageId: Garage['id'];
  schedules: Garage['schedules'];
  currentDay: Date;
};
export function addLunchTimeUnavailability({
  events,
  schedules,
  garageId,
  currentDay,
}: AddLunchTimeUnavailabilityProps): Event[] {
  if (!schedules.length) {
    return events;
  }

  const eventsWithUnavailabilities = [...events];

  // On récupère l'horaire le plus tôt du début de journée
  let earliestStartTime = findEarliestScheduleTime(schedules, 'startTime');
  let earliestEndTime = findEarliestScheduleTime(schedules, 'endTime');

  // Pour chaque jour de la semaine dans le schedule
  schedules.forEach((schedule) => {
    const dayOfWeekIndex = daysOfWeek[schedule.dayOfWeek];

    // Commencer avec un intervalle représentant toute la journée
    let dayStart = new Date(currentDay);
    dayStart.setDate(dayStart.getDate() + ((7 + dayOfWeekIndex - dayStart.getDay()) % 7));
    const [startHours, startMinutes] = earliestStartTime.split(':').map(Number);
    dayStart.setHours(startHours, startMinutes, 0, 0);

    let dayEnd = new Date(dayStart);
    const [endHours, endMinutes] = earliestEndTime.split(':').map(Number);
    dayEnd.setHours(endHours, endMinutes, 0, 0);

    // Filtrer les intervalles où startTime ou endTime est null
    const validIntervals = schedule.intervals.filter((interval) => interval.startTime && interval.endTime);

    // Tri les intervalles par heure de début
    const sortedIntervals = validIntervals.sort((a, b) => {
      if (a.startTime && b.startTime) {
        return String(a.startTime).localeCompare(String(b.startTime));
      }
      return 0;
    });

    sortedIntervals.forEach((interval) => {
      if (interval.startTime && interval.endTime) {
        const intervalStart = new Date(dayStart);
        const [startHours, startMinutes] = String(interval.startTime).split(':').map(Number);
        intervalStart.setHours(startHours, startMinutes);

        // Si l'heure de début de l'intervalle est après le début de la journée, ajoute un événement
        if (intervalStart > dayStart) {
          // Ajout de l'événnement à la liste
          eventsWithUnavailabilities.push(
            new Event(
              '',
              Number(garageId),
              EventType.UNAVAILABILITY,
              'Fermé',
              '',
              Number(garageId),
              '',
              EventState.CANCELLED,
              undefined,
              dayStart,
              intervalStart,
              // Dirty way, when i'll have a bit of time i'll refactor some models from the commons library
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              false,
            ),
          );
        }

        // Mettre à jour le début de la journée pour la prochaine itération
        dayStart = new Date(dayStart);
        const [endHours, endMinutes] = String(interval.endTime).split(':').map(Number);
        dayStart.setHours(endHours, endMinutes);
      }
    });

    // Ajouter un événement pour la période restante de la journée si nécessaire
    if (dayStart < dayEnd) {
      eventsWithUnavailabilities.push(
        new Event(
          '',
          Number(garageId),
          EventType.UNAVAILABILITY,
          'Fermé',
          '',
          Number(garageId),
          '',
          EventState.CANCELLED,
          undefined,
          dayStart,
          dayEnd,
          // Dirty way, when i'll have a bit of time i'll refactor some models from the commons library
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          false,
        ),
      );
    }
  });

  return eventsWithUnavailabilities;
}
