import { Box, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { colors } from '../../../../helpers/Constants';

type ColorPickerProps = {
  selectedColor: string;
  handleChangeColor: (color: string) => void;
};

const ColorPicker = ({ selectedColor, handleChangeColor }: ColorPickerProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorSelect = (color: string) => {
    handleChangeColor(color);
    handleClose();
  };

  return (
    <div>
      <Box
        sx={{
          width: 20,
          height: 20,
          borderRadius: '50%',
          backgroundColor: selectedColor,
          cursor: 'pointer',
          border: '1px solid #7f7f7f',
        }}
        onClick={handleClick}
      />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {colors.map((color: any) => (
          <MenuItem key={color.hex} onClick={() => handleColorSelect(color.hex)}>
            <Box
              sx={{
                width: 20,
                height: 20,
                borderRadius: '50%',
                backgroundColor: color.hex,
                border: color.hex === '' ? '1px solid #7f7f7f' : 'none',
                mr: 1,
              }}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
export default ColorPicker;
