import type { FunctionComponent } from 'react';
import type { PricingPackageProps } from '../PricingPackage';
import { Box, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { flexCenterCol } from '../../../../helpers/Tools';
import { styleReparateur } from '../pricingTools';
import { useFormik } from 'formik';
import * as Yup from 'yup';

interface RepaireOffersProps extends PricingPackageProps {
    repaireSupp: number;
    handleChangeRepaireSupp: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface RepaireOffersData {
  id: number;
  name: string;
  offers: [{ id: number, include: boolean; optional: boolean, description: string,
    values: [{ id: number, description: string, price: number, quantity: number}] 
  }];
}

const RepaireOffers: FunctionComponent<RepaireOffersProps>  = ({ movaPackage, options, repaireSupp, handleChangeRepaireSupp, workforce = 0, onlyView }: RepaireOffersProps) => {
  const { values, handleChange } = useFormik<{ repaireSupp: number }>({
    initialValues: {
      repaireSupp: (workforce - 1) < 0 ? 0 : (workforce - 1),
    },
    onSubmit: () => {},
    validationSchema: Yup.object().shape({
      repaireSupp: Yup.number().optional().min(0),
    }),
    enableReinitialize: true,
  });

  useEffect(() => {
    const initData = workforce - 1;
    handleChangeRepaireSupp({
      target: {
        name: 'repaireSupp',
        value: (workforce > 1) ? initData.toString() : 0,
      },
    } as  React.ChangeEvent<HTMLInputElement>);
  }, []);

  const combinedHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event);  
    handleChangeRepaireSupp(event);
  };
    return (
        <Box sx={{minHeight: '21%', width: '100%', display: 'flex', flexDirection: 'column',}}>
         {options?.reparateur?.map((item: RepaireOffersData) => (
          <React.Fragment key={item.id}>
            <Box
              style={{ ...flexCenterCol, ...styleReparateur }}
              sx={{ minHeight: item.id === 8 ? '66px !important' : '' }}
            >
              {(item.id === 6 || item.id === 7) && (
                <Typography variant='body1' sx={{ margin: 0, padding: 0 }}>
                  {item.offers.filter((offre: RepaireOffersData['offers'][0]) => offre.id === movaPackage.id)[0]?.description}
                </Typography>
              )}
              {item.id === 8 && (
                <Box sx={{ width: '85%' }} style={flexCenterCol}>
                 
                    <TextField
                      size='small'
                      disabled={onlyView}
                      onChange={combinedHandleChange}
                      fullWidth
                      label={item.name}
                      value={values.repaireSupp}
                      name="repaireSupp"
                      type='number'
                      variant='outlined'
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  <Typography
                    variant='body2'
                    className='my-color'
                    sx={{
                      minHeight: '20px',
                    }}
                  >
                    {repaireSupp !== 0 && `${repaireSupp.toFixed(2)}€/mois`}
                  </Typography>
                </Box>
              )}
            </Box>
          </React.Fragment>
        ))}
        </Box>
    );
};

export default RepaireOffers;
