import { ConfirmationDialog, type Customer, type Vehicle, formatFrenchVehiclePlate } from '@movalib/movalib-commons';
import CloseIcon from '@mui/icons-material/CloseRounded';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import invariant from 'invariant';
import { memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import CatPlateBg from '../../../assets/images/car_plate_bg.png';
import { useBoolState } from '../../../helpers/hooks/useBoolState';
import { useFetchAdministratedGarages, useGarageDeleteCustomerVehicle } from '../../../query/garage/GarageQuery';
import { usePatchVehicleInfos } from '../../../query/vehicle/VehicleQuery';
import { setSnackbar } from '../../../slices/snackbarSlice';
import theme from '../../../theme';
import { VehicleForm } from './vehicleForm';
import { LoadingButton } from '@mui/lab';

type EditVehicleProps = {
  open: boolean;
  onClose: () => void;
  customer: Customer;
  vehicle: Vehicle;
};

export const EditVehicle = memo(({ open, onClose, customer, vehicle }: EditVehicleProps) => {
  const dispatch = useDispatch();
  const { isConfirmDeleteVehicleDialogOpen, toggleConfirmDeleteVehicleDialogOpen } = useBoolState(
    false,
    'confirmDeleteVehicleDialogOpen',
  );

  const { data: garage } = useFetchAdministratedGarages();
  const { mutateAsync: patchVehicle } = usePatchVehicleInfos();
  const { mutateAsync: deleteCustomerVehicle } = useGarageDeleteCustomerVehicle();
  const [isPending, setIsPending] = useState<boolean>(false);
  const [foundVehicle, setFoundVehicle] = useState<string>(vehicle?.id.toString() ?? '');
  const [vehicleForm, setVehicleForm] = useState<VehicleForm>();

  const toggleIsPending = (e: boolean) => {
    setIsPending(e);
  }
  const toggleFoundVehicle = (e: string) => {
    setFoundVehicle(e);
  }
  const onDeleteVehicle = useCallback(async () => {
    invariant(garage?.id, 'Garage ID is not defined');

    const response = await deleteCustomerVehicle({
      garageId: garage.id,
      vehicleId: `${vehicle.id}`,
      customerId: customer.id,
    });

    if (response.success) {
      dispatch(
        setSnackbar({
          open: true,
          message: response.data ?? 'Véhicule supprimé du fichier client !',
          severity: 'success',
        }),
      );
      onClose();
    } else {
      dispatch(
        setSnackbar({
          open: true,
          message: response.error ?? 'Erreur lors de la suppression du véhicule',
          severity: 'error',
        }),
      );
    }
  }, [customer.id, deleteCustomerVehicle, dispatch, garage?.id, onClose, vehicle.id]);

  const onSubmitForm = useCallback(
    async (valueForm: VehicleForm) => {
      const { averageMileagePerYear, currentMileage, tireInfos } = valueForm;
      // Send to API
      const response = await patchVehicle({
        vehicleId: vehicle.id,
        currentMileage,
        averageMileagePerYear,
        tireWidth: tireInfos.width,
        tireHeight: tireInfos.height,
        tireDiameter: tireInfos.diameter,
        tireSpeedIndex: tireInfos.speedIndex,
      });

      if (response.success) {
        dispatch(
          setSnackbar({
            open: true,
            message: response.data ?? 'Véhicule ajouté au fichier client !',
            severity: 'success',
          }),
        );
        onClose();
      } else {
        dispatch(
          setSnackbar({
            open: true,
            message: response.error ?? "Erreur lors de l'ajout du véhicule",
            severity: 'error',
          }),
        );
      }
    },
    [dispatch, onClose, patchVehicle, vehicle.id],
  );

  return (
    <>
      <Dialog open={open} maxWidth='sm' scroll='body' onClose={onClose} fullWidth>
        <Toolbar
          disableGutters
          variant='dense'
          sx={{
            minHeight: 3,
            backgroundColor: theme.palette.grey[200],
            py: 0,
          }}
        >
          <DialogTitle sx={{ flexGrow: 1 }} component={'div'}>
            <Typography
              py={0}
              my={0}
              sx={{
                pl: '34px',
                color: theme.palette.text.primary,
                textAlign: 'center',
              }}
            >
              <span>
                MODIFIER <b>UN VÉHICULE</b>
              </span>
            </Typography>
          </DialogTitle>
          <Tooltip title='Fermer'>
            <IconButton sx={{ mr: 1 }} size='small' aria-label='close' onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>

        <Typography
          variant='h5'
          color={theme.palette.primary.main}
          sx={{ fontWeight: 'bold', textAlign: 'center', p: 2 }}
        >
          {`${vehicle.brand ?? ''} ${vehicle.model ?? ''} ${vehicle.version ?? ''}`.trim()}
        </Typography>
        <DialogContent sx={{ paddingBottom: 0 }}>
          <Grid container sx={{mb: 1}}>
            <Grid item xs={6} sx={{ position: 'relative' }}>
              <img
                src={CatPlateBg}
                alt="Plaque d'immatriculation"
                style={{ height: '50px', position: 'relative', marginLeft: '21px' }}
              />
              <Typography
                variant='h6'
                color={theme.palette.text.primary}
                sx={{ position: 'absolute', top: '8px', left: '97px' }}
              >
                <b>{formatFrenchVehiclePlate(vehicle.plate)}</b>
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button variant='contained' color='error' onClick={toggleConfirmDeleteVehicleDialogOpen}>
                Supprimer
              </Button>
            </Grid>
          </Grid>
          <VehicleForm vehicle={vehicle} onFormChange={(e) => setVehicleForm(e)} onCancelForm={onClose} onSubmitForm={onSubmitForm} toggleIsPending={toggleIsPending} toggleFoundVehicle={toggleFoundVehicle}/>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: theme.palette.grey[200],
            justifyContent: 'space-between',
          }}
        >
          <Button variant='contained' color='inherit' onClick={() => onClose()}>
            Annuler
          </Button>
          <LoadingButton
            onClick={()=> onSubmitForm(vehicleForm!)}
            disabled={!foundVehicle}
            variant='contained'
            color='primary'
            loading={isPending}
          >
            Enregistrer
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={isConfirmDeleteVehicleDialogOpen}
        onClose={toggleConfirmDeleteVehicleDialogOpen}
        onConfirm={onDeleteVehicle}
        title='Supprimer le véhicule'
        message='Êtes-vous sûr de vouloir supprimer le véhicule assigné à ce client ?'
      />
    </>
  );
});
