import { validatePhoneNumber } from '@movalib/movalib-commons';
import { Grid, TextField, type TextFieldProps } from '@mui/material';
import type { FormikErrors, FormikHandlers, FormikHelpers } from 'formik';
import { memo, useCallback } from 'react';
import type { BusinessForm } from './BusinessCustomerDialog';

type UpsertBusinessCustomerProps = {
  values: BusinessForm;
  errors: FormikErrors<BusinessForm>;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: FormikHelpers<BusinessForm>['setFieldValue'];
};

export const UpdateBusinessCustomer = memo(
  ({ values, errors, handleChange, setFieldValue }: UpsertBusinessCustomerProps) => {
    const handleChangeCompanyName = useCallback<NonNullable<TextFieldProps['onChange']>>(
      (e) => {
        setFieldValue('companyName', e.target.value.toUpperCase());
      },
      [setFieldValue],
    );

    const handleChangePhoneNumber = useCallback<NonNullable<TextFieldProps['onChange']>>(
      (e) => {
        if (e.target.value !== '' && !validatePhoneNumber(e.target.value)) {
          return;
        }
        setFieldValue('phoneNumber', e.target.value);
      },
      [setFieldValue],
    );

    const handleChangeCompanyPhoneNumber = useCallback<NonNullable<TextFieldProps['onChange']>>(
      (e) => {
        if (e.target.value !== '' && !validatePhoneNumber(e.target.value)) {
          return;
        }
        setFieldValue('companyPhoneNumber', e.target.value);
      },
      [setFieldValue],
    );

    return (
      <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                margin='dense'
                autoFocus
                required
                fullWidth
                id='companyName'
                label="NOM DE L''ENTREPRISE"
                name='companyName'
                autoComplete='company-name'
                onChange={handleChangeCompanyName}
                value={values.companyName}
                error={!!errors.companyName}
                helperText={errors.companyName}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type='tel'
                margin='dense'
                fullWidth
                required
                id='companyPhoneNumber'
                label='N° téléphone principal'
                name='companyPhoneNumber'
                autoComplete='tel'
                onChange={handleChangeCompanyPhoneNumber}
                value={values.companyPhoneNumber}
                error={!!errors.companyPhoneNumber}
                helperText={errors.companyPhoneNumber}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type='tel'
                margin='dense'
                fullWidth
                id='phoneNumber'
                label='N° de téléphone secondaire'
                name='phoneNumber'
                autoComplete='tel'
                onChange={handleChangePhoneNumber}
                value={values.phoneNumber}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin='normal'
                fullWidth
                id='email'
                label="Adresse email de l'entreprise"
                name='email'
                autoComplete='email'
                onChange={handleChange}
                value={values.email}
                error={!!errors.email}
                helperText={
                  errors.email ??
                  "L'email est facultatif, mais on vous encourage à le demander pour réduire le papier 🙏🌿 !"
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id='notes'
                margin='dense'
                label='Notes internes'
                onChange={handleChange}
                multiline
                rows={3}
                variant='outlined'
                size='small'
                value={values.notes}
                error={!!errors.notes}
                helperText={errors.notes}
                fullWidth
              />
            </Grid>
          </Grid>
      </>
    );
  },
);
