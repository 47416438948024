import { Fragment, type FunctionComponent, useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Alert, Typography } from '@mui/material';
import theme from '../../../theme';
import Logger from '../../../helpers/Logger';
import { Loader, MovaAppType, VehiclePlateField, VehicleService } from '@movalib/movalib-commons';

interface AddVehicleDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (returnVehiclePlate: string, returnVehicleDescription: string) => void;
}

const AddVehicleDialog: FunctionComponent<AddVehicleDialogProps> = ({ open, onClose, onConfirm }) => {

  const [vehicleDescription, setVehicleDescription] = useState<string | undefined>(undefined);
  const [vehiclePlate, setVehiclePlate] = useState<string | undefined>(undefined);
  const [message, setMessage] = useState<string>("");    
  const [loading, setLoading] = useState(false);
    // Cette variable sert uniquement à éviter les appels multiple à VehicleService.getVehicleDescription (la maj du state étant asynchrone)
    const lastVehiclePlate = useRef('');

  useEffect(() => {
    purgeLocalState();
  }, [open]);

  const handleValidVehiclePlate = (validVehiclePlate: string) => {

    // Permet déviter des appels multiple à l'API le temps de la MAJ du state local
    if (lastVehiclePlate.current === validVehiclePlate) return; // Sortir tôt si la valeur est la même
    lastVehiclePlate.current = validVehiclePlate;

    setLoading(true);

    setVehiclePlate(validVehiclePlate);

    VehicleService.getVehicleDescription(MovaAppType.GARAGE, validVehiclePlate)
      .then((response) => {

        Logger.info(response)
        if(response.success){
          setVehicleDescription(response.data ? response.data : undefined);
          setMessage("");
  
        } else {
          setVehicleDescription(undefined);
          setMessage(response.error && !String(response.error).includes('404') ? response.error : "Identification du véhicule impossible");
        }
        
    
      }).catch(error => {
        console.error(error);

      }).finally(() => {
        setLoading(false);
      });
  };

  const purgeLocalState = () => {
    setVehicleDescription(undefined);
    setVehiclePlate(undefined);
    setMessage("");
  }


  const handleOnClose = () => {
    purgeLocalState()
    onClose();
  }

  const handleOnConfirm = () => {
    purgeLocalState()
    onConfirm(vehiclePlate ? vehiclePlate : '', vehicleDescription ? vehicleDescription : '');
  }

return (
    <Dialog
      fullWidth
      maxWidth='xs'
      open={open}
      onClose={handleOnClose}
      aria-labelledby="add-vehicle-dialog-title"
      aria-describedby="add-vehicle-dialog-description"
    >
      <DialogTitle id="add-vehicle-dialog-title">Recherche du véhicule</DialogTitle>
      <DialogContent>
        < br />
        <VehiclePlateField onValidVehiclePlate={handleValidVehiclePlate} />
        <DialogContentText id="add-vehicle-dialog-description" align='center' sx={{ paddingTop: 2}}>
        {vehicleDescription && 
          <Fragment>
            <Typography variant='h6' color={theme.palette.primary.main} sx={{ fontSize: '16px' }}>
            <b>{vehiclePlate}</b>, véhicule trouvé 
            </Typography>
            <p />
            <Typography variant='body2' color={theme.palette.text.primary}>
              <b>{vehicleDescription}</b>
            </Typography>
          </Fragment>
        }
        </DialogContentText>
        {message && <Alert severity="error" sx={{ mb: 2 }}>{message}</Alert>}
      </DialogContent>
      <DialogActions sx={{ borderTop: 1, 
                          borderColor: theme.palette.grey[200] }}>
        <Button onClick={handleOnClose} color="inherit">
          Annuler
        </Button>
        <Button onClick={handleOnConfirm} 
          color="primary" sx={{ ml: 1}} disabled={!vehicleDescription} variant='contained'>
          Ajouter ce véhicule
        </Button>
      </DialogActions>

      <Loader loading={loading} />
    </Dialog>
  );
}

export default AddVehicleDialog;