import { GarageService as CoreGarageService, type Event, EventState, type Garage } from '@movalib/movalib-commons';
import type Prestation from '@movalib/movalib-commons/dist/src/models/Prestation';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import OperatorIcon from '@mui/icons-material/EngineeringRounded';
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFetchEmployees } from '../../query/employee/EmployeeQuery';
import { setSnackbar } from '../../slices/snackbarSlice';
import UserAvatar from '../UserAvatar';
import { MyEventDialog } from './MyCalendarTypes';

interface Props {
  styledIcon?: boolean;
  withLabel?: boolean;
  localEvent: Event;
  garage: Garage;
  employee?: string | '';
  type: MyEventDialog;
  refreshEvent: () => void;
  onRefresh?: () => void;
  onChange?: (employeeId: string) => void;
  selectedPrestationsId?: Prestation['id'][];
}

const EmployeeChoice: React.FC<Props> = ({
  styledIcon = false,
  withLabel,
  localEvent,
  garage,
  type,
  refreshEvent,
  onRefresh,
  employee,
  onChange,
  selectedPrestationsId,
}) => {
  const { data: employees = [] } = useFetchEmployees(garage.id);
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedEmployee, setSelectedEmployee] = useState<string | '' | undefined>(employee);

  const otherPrestationId = garage.prestations.find((p) => p.code === 'OTHER')?.id || Number.NaN;

  const prestationsList = (selectedPrestationsId || (localEvent.prestations || []).map((p) => p.id)).filter(
    (id) => id !== otherPrestationId,
  );

  useEffect(() => {
    checkIfEmployeeCanDoPrestation();
  }, [prestationsList]);

  const checkIfEmployeeCanDoPrestation = () => {
    const emp = employees.find((e) => `${e.id}` === employee || e.id === selectedEmployee);
    if (!emp || prestationsList.length === 0) {
      return;
    }
    const prestations = emp.prestations.map((p) => p.id);

    const canEmployeeDoIt = prestationsList.every((id) => prestations.includes(id));
    if (!canEmployeeDoIt) {
      dispatch(
        setSnackbar({
          open: true,
          message: 'Le réparateur sélectionné précédemment ne peut pas effectuer les prestations sélectionnées.',
          severity: 'warning',
        }),
      );
      handleClearSelection();
    }
  };

  const handleEmployeeChange = (event: SelectChangeEvent<string>) => {
    const employeeId = event.target.value;

    // On invoque l'éventuel callback
    if (onChange) {
      onChange(employeeId);
    }

    // Cas particulier du choix -1, redirection module employé
    if (Number(employeeId) === -1) {
      history.push('/team');
    }

    // Cas particulier du choix -2, désattribution de l'employé
    if (Number(employeeId) === -2) {
      setSelectedEmployee('');

      if (MyEventDialog.CREATE !== type) {
        CoreGarageService.deleteEventEmployee(garage.id, localEvent.id)
          .then((response) => {
            if (response.success) {
              refreshEvent();
              if (onRefresh) {
                onRefresh();
              }

              dispatch(
                setSnackbar({
                  open: true,
                  message: response.data ?? 'Le réparateur a bien été modifié',
                  severity: 'success',
                }),
              );
            } else {
              dispatch(
                setSnackbar({
                  open: true,
                  message: response.error ?? 'Erreur lors de la modification du réparateur',
                  severity: 'error',
                }),
              );
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }

    const employee = garage?.employees?.find((employee) => employee.id === employeeId) || undefined;

    if (MyEventDialog.CREATE === type && employee !== undefined) {
      setSelectedEmployee(employee.id);
      return;
    }

    if (garage && localEvent && employee !== undefined) {
      CoreGarageService.updateEventEmployee(garage.id, localEvent.id, employee.id)
        .then((response) => {
          if (response.success) {
            refreshEvent();
            if (onRefresh) {
              onRefresh();
            }
            dispatch(
              setSnackbar({
                open: true,
                message: response.data ?? 'Le réparateur a bien été modifié',
                severity: 'success',
              }),
            );
          } else {
            dispatch(
              setSnackbar({
                open: true,
                message: response.error ?? 'Erreur lors de la modification du réparateur',
                severity: 'error',
              }),
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleClearSelection = () => {
    handleEmployeeChange({ target: { value: '-2' } } as SelectChangeEvent<string>);
  };

  if (!(garage.employees && garage.teamManagementActive)) {
    return null;
  }

  const skilledEmployees = employees.filter((emp) => {
    if (!prestationsList.length) {
      return true;
    }
    const prestations = emp.prestations.map((p) => p.id);

    return prestationsList.every((id) => prestations.includes(id));
  });

  return (
    <Grid container>
      <Grid item xs={1} sx={{display: {xs:'none',md:'flex'}}} style={{justifyContent: 'center', alignItems: 'center' }}>
        <Grid container direction='column' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <OperatorIcon
            sx={{ color: styledIcon ? '' : theme.palette.grey[500] }}
            className={styledIcon ? 'styled-icon' : ''}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={11} sx={{ml: {xs:1,md:0}}}>
        <Grid container display='flex'>
          {withLabel && (
            <Grid item xs={3} style={{ display: 'flex', alignItems: 'flex-start' }} sx={{ pl: 1, mt: 1 }}>
              <Typography variant='body1' sx={{ mb: 0.5 }}>
                <b>Réparateur</b>
              </Typography>
            </Grid>
          )}
          <Grid item xs={withLabel ? 8 : 11} sx={{ mt: 1 }} style={{ display: 'flex', alignItems: 'flex-start' }}>
            <FormControl fullWidth size='small' disabled={localEvent.state === EventState.CANCELLED}>
              <InputLabel id='employee-select-label'>Choisissez le réparateur ...</InputLabel>
              <Select
                value={(MyEventDialog.DETAILS === type && localEvent?.employees?.[0]?.id) || selectedEmployee}
                onChange={handleEmployeeChange}
                variant='outlined'
                size='small'
                label='Choisissez un réparateur ...'
              >
                {skilledEmployees
                  .sort((a, b) => a.firstname.localeCompare(b.firstname)) // Tri par ordre alphabétique
                  .map((employee) => (
                    <MenuItem key={employee.id} value={employee.id}>
                      <UserAvatar avatar={employee.avatar} alt={employee.firstname} size='small' />
                      <Typography>
                        {employee.firstname} {employee.lastname}
                      </Typography>
                    </MenuItem>
                  ))}
                {skilledEmployees.length === 0 && (
                  <MenuItem key={-1} value={-1} sx={{ backgroundColor: theme.palette.grey[200] }}>
                    <div
                      style={{
                        textAlign: 'left',
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'center',
                        fontSize: '13px',
                      }}
                    >
                      <AddIcon />
                      &nbsp;AJOUTER
                    </div>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <Grid item xs={1} sx={{ pl: 1, mr: 1 }}>
              <IconButton
                disabled={localEvent.state === EventState.CANCELLED}
                aria-label='clear selection'
                onClick={handleClearSelection}
                size='small'
              >
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EmployeeChoice;
