import {
  type CategoryPrestation,
  ConfirmationDialog,
  GarageService as CoreGarageService,
  type Event,
  type Garage,
  PartsApplicationType,
  type Prestation,
  PrestationState,
} from '@movalib/movalib-commons';
import { flexCenter, getApplicationShortLabel } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import { Save, Check as CheckIcon } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  type SelectProps,
  TextField,
  ToggleButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import invariant from 'invariant';
import { type MouseEvent, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { StyledToggleButtonGroup } from '../../helpers/Styled';
import { applicationChoiceRequired, flexEnd } from '../../helpers/Tools';
import { useBoolState } from '../../helpers/hooks/useBoolState';
import { setSnackbar } from '../../slices/snackbarSlice';
import theme from '../../theme';
import type { CatergoryPrestationAggrs } from '../../pages/Settings';

type EventPrestationListDialogProps = {
  onClose: (reason?: string) => void;
  garage: Garage;
  event: Event;
  onRefresh?: () => void;
  availablePrestations: Prestation[];
};

type TmpPrestation = Record<
  Prestation['id'],
  {
    status: 'initial' | 'added' | 'removed';
    applications?: PartsApplicationType[];
  }
>;

const importIcon = (iconName: string): string => {
  try {
    const lowerCaseIconName = iconName.toLowerCase();
    return require(`../../assets/images/icons/${lowerCaseIconName}.png`);
  } catch (_error) {
    return '';
  }
};

const findApplication = (eventTitle: string, prestationName: string, isMultipleApplication: boolean) => {
  let applications = [
    eventTitle
      .split('|')
      .find((title) => title.includes(prestationName))
      ?.split(prestationName)[1]
      ?.trim(),
  ];

  if (!applications || applications.length === 0) {
    return undefined;
  }

  if (isMultipleApplication) {
    applications = applications[0]?.split('+')?.map((app) => app.trim()) as string[];
  }

  return applications
    .map((app) => {
      switch (app) {
        case 'AV':
          return PartsApplicationType.FRONT;
        case 'AR':
          return PartsApplicationType.REAR;
        case 'AV + AR':
          return PartsApplicationType.FRONT_REAR;
        case 'G':
          return PartsApplicationType.LEFT;
        case 'D':
          return PartsApplicationType.RIGHT;
        case 'G + D':
          return PartsApplicationType.LEFT_RIGHT;
        case 'AVG':
          return PartsApplicationType.FRONT_LEFT;
        case 'AVD':
          return PartsApplicationType.FRONT_RIGHT;
        case 'ARG':
          return PartsApplicationType.REAR_LEFT;
        case 'ARD':
          return PartsApplicationType.REAR_RIGHT;
        default:
          return undefined;
      }
    })
    .filter(Boolean);
};

export const EventPrestationListDialog = ({
  onClose,
  garage,
  event,
  onRefresh,
  availablePrestations,
}: EventPrestationListDialogProps) => {
  // Prestation Focus pour l'application
  const [focusedPrestation, setFocusedPrestation] = useState<Prestation | null>(null);

  const [otherReason, setOtherReason] = useState<string>(event?.otherReason || '');

  const [tmpPrestations, setTmpPrestations] = useState<TmpPrestation>(
    (event.prestations || []).reduce((acc, prestation) => {
      acc[prestation.id] = { status: 'initial' };
      const applications = findApplication(event.title, prestation.name, prestation.multipleApplication);
      if (applications?.length) {
        acc[prestation.id].applications = applications.filter(Boolean) as PartsApplicationType[];
      }
      return acc;
    }, {} as TmpPrestation),
  );
  const [prestationsWithCategory, setPrestationsWithCategory] = useState<CatergoryPrestationAggrs[]>([]);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();

  const { isApplicationChoiceDialogOpenned, toggleApplicationChoiceDialogOpenned } = useBoolState(
    false,
    'applicationChoiceDialogOpenned',
  );

  const [isPrestationsMenuOpen, setIsPrestationsMenuOpen] = useState(false);
  const [prestationsSearchTerm, setPrestationsSearchTerm] = useState(''); // État local pour la recherche des prestations
  const prestationsSearchInputRef = useRef<HTMLInputElement | null>(null); // Référence pour le champ de recherche
  const [expandedCategory, setExpandedCategory] = useState({});

  useEffect(() => {
    if (garage) {
      setIsPrestationsMenuOpen(false);
      const categoryPrestations = [...garage.prestationCategories];
      const categoryPrestationTable = [] as CatergoryPrestationAggrs[];
      categoryPrestations.forEach((category: CategoryPrestation) => {
        const prestations = displayedPrestations?.filter(
          (p) => p.categoryCode === category.code && p.state === PrestationState.VALIDATED && p.active,
        ) as Prestation[];
        categoryPrestationTable.push({ ...category, prestations: prestations });
      });
      categoryPrestationTable.sort((a, b) => a.position - b.position);
      setPrestationsWithCategory(categoryPrestationTable);
    }
  }, [garage, tmpPrestations]);

  const handleMenuClose = (e: any) => {
    if (e.currentTarget.getAttribute('aria-label') !== 'category') {
    setIsPrestationsMenuOpen(false)
  };
}
  const handleMenuOpen = () => setIsPrestationsMenuOpen(true);
  const handleCategoryClick = (categoryCode: string) => {
    setExpandedCategory((prevCategory) => (prevCategory === categoryCode ? '' : categoryCode));
  };
  const displayedPrestations = availablePrestations.filter((prestation) => {
    const isActive = prestation.state === PrestationState.VALIDATED && prestation.active;

    const isAlreadyInEvent = tmpPrestations[prestation.id]?.status !== undefined;

    return isActive && !isAlreadyInEvent;
  });

  const onSavePrestationsChange = () => {
    // On récupère les prestations (soit initial, soit ajoutées) et on les filtre pour ne pas envoyer les prestations supprimées
    const prestations = Object.keys(tmpPrestations)
      .filter((key) => tmpPrestations[+key].status !== 'removed')
      .map((key) => {
        const prestation = availablePrestations.find((p) => p.id === +key);
        if (!prestation) {
          return undefined;
        }

        return {
          id: prestation.id,
          code: prestation.code,
          applications: tmpPrestations[prestation.id].applications,
        };
      })
      .filter(Boolean);

    if (prestations.find((p) => p?.code === 'OTHER') && !otherReason) {
      dispatch(
        setSnackbar({
          open: true,
          message: 'Lorsque la prestation "Autres" est sélectionnée, vous devez préciser la prestation.',
          severity: 'error',
        }),
      );
      return;
    }

    const req = {
      startDate: event.start as Date,
      endDate: event.end as Date,
      quoteId: event.quoteId,
      notes: event.notes,
      prestations,
      otherReason,
    };

    CoreGarageService.updateGarageEvent(garage.id, event.id, req).then((response) => {
      if (response.success && response.data) {
        // on ferme la dialog des prestations et on provoque le refresh de l'event
        if (onRefresh) {
          onRefresh();
        }
        onClose();

        dispatch(
          setSnackbar({
            open: true,
            message: response.data ?? 'Votre rendez-vous a bien été mis à jour',
            severity: 'success',
          }),
        );
      } else {
        dispatch(
          setSnackbar({
            open: true,
            message: response.error ?? 'Mise à jour du rendez-vous impossible',
            severity: 'error',
          }),
        );
      }
    });
  };

  const onRemovePrestation = (id: Prestation['id']) => {
    try {
      invariant(tmpPrestations[id], 'You cannot remove a prestation that does not exist');
    } catch (error) {
      console.error(error);
      return;
    }

    if (tmpPrestations[id].status === 'added') {
      setTmpPrestations((prev) => {
        // remove properly the prestation from the list
        const { [id]: _, ...remainingPrestations } = prev;

        return remainingPrestations;
      });

      // Si la prestation temporaire supprimée est "Autres" alors on réinitialise le champs de précision
      if (availablePrestations.find((prestation) => prestation.id === id)?.code === 'OTHER') {
        setOtherReason('');
      }
      return;
    }
    setTmpPrestations((prev) => ({ ...prev, [id]: { ...prev[id], status: 'removed' } }));
  };

  const onAddPrestation: SelectProps<Prestation['id']>['onChange'] = (event) => {
    if (!event.target.value) return;
    // Si la prestation nécessite une application, alors on ouvre la fenêtre de choix d'application
    const prestation = availablePrestations.find((p) => p.id === event.target.value);
    if (prestation && applicationChoiceRequired(prestation)) {
      setFocusedPrestation(prestation);
      toggleApplicationChoiceDialogOpenned();
    }

    setTmpPrestations((prev) => ({ ...prev, [event.target.value]: { status: 'added' } }));
  };

  const onResetInitialPrestation = (id: Prestation['id']) => {
    try {
      invariant(tmpPrestations[id], 'You cannot reset a prestation that does not exist');
    } catch (error) {
      console.error(error);
      return;
    }
    setTmpPrestations((prev) => ({ ...prev, [id]: { ...prev[id], status: 'initial' } }));
  };

  const handleCancelApplicationChoice = () => {
    try {
      invariant(focusedPrestation, 'You cannot cancel an application choice without a focused prestation');
    } catch (error) {
      console.error(error);
      return;
    }

    onRemovePrestation(focusedPrestation.id);
    toggleApplicationChoiceDialogOpenned();
    setFocusedPrestation(null);
  };

  const onCloseApplicationChoiceDialog = (bypassRemovePrestation = false) => {
    try {
      invariant(focusedPrestation, 'You cannot close an application choice without a focused prestation');
    } catch (error) {
      console.error(error);
      return;
    }

    const { id } = focusedPrestation;

    // Si le client n'a pas sélectionné d'application, alors on supprime la prestation de la liste
    if (!(tmpPrestations[id].applications?.length || bypassRemovePrestation)) {
      onRemovePrestation(id);
    }
    toggleApplicationChoiceDialogOpenned();
    setFocusedPrestation(null);
  };

  const onChangeApplicationChoice = (_e: MouseEvent, value: PartsApplicationType[]) => {
    try {
      invariant(focusedPrestation, 'You cannot change an application choice without a focused prestation');
    } catch (error) {
      console.error(error);
      return;
    }

    setTmpPrestations((prev) => ({
      ...prev,
      [focusedPrestation.id]: { ...prev[focusedPrestation.id], applications: value },
    }));

    // Si la prestation n'est pas en multiple application, alors on close la dialog directement
    if (!focusedPrestation.multipleApplication) {
      onCloseApplicationChoiceDialog(true);
    }
  };

  const onChangeOtherReason = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherReason(event.target.value);
  };

  const prestationOTHER = availablePrestations.find((prestation) => prestation.code === 'OTHER');

  const prestationsKeys = Object.keys(tmpPrestations).filter((key) => key !== `${prestationOTHER?.id}`);

  const activePrestations = Object.keys(tmpPrestations)
    .filter((key) => tmpPrestations[+key].status !== 'removed')
    .map((key) => {
      const prestation = availablePrestations.find((p) => p.id === +key);
      if (!prestation) {
        return undefined;
      }

      return {
        id: prestation.id,
        applications: tmpPrestations[prestation.id].applications,
      };
    })
    .filter(Boolean);

    function filterSearch(prestationName: string): boolean {
      const prestationN = prestationName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      const search = prestationsSearchTerm.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      return prestationN.includes(search);
    }

  function renderPrestation(prestation: any) {
    return (
      <MenuItem key={prestation.id} value={prestation.id}>
        {importIcon(prestation.code) && (
          <img
            src={importIcon(prestation.code)}
            style={{
              width: '20px',
              height: '20px',
              paddingLeft: '4px',
              marginRight: '7px',
            }}
          />
        )}
        {prestation.name}
      </MenuItem>
    );
  }

  return (
    <>
      <Box id='prestation-dialog-container' height='700px'>
        <Toolbar
          disableGutters
          variant='dense'
          sx={{
            display: 'block',
            minHeight: 3,
            backgroundColor: theme.palette.grey[200],
            p: 0,
          }}
        >
          <DialogTitle padding={1} marginBottom={3}>
            <Grid container>
              <Grid item xs={11} style={flexCenter} sx={{ pl: `${100 / 12}%` }}>
                <Typography
                  style={flexCenter}
                  variant='h6'
                  color={theme.palette.text.primary}
                  textTransform='uppercase'
                >
                  Prestations du rendez-vous
                </Typography>
              </Grid>

              <Grid item xs={1} style={flexEnd}>
                <IconButton sx={{ mr: 0 }} size='small' aria-label='close' onClick={() => onClose()}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
        </Toolbar>
        <DialogContent
          sx={{
            flexDirection: 'column',
            gap: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'start', md: 'center' },
          }}
        >
          <FormControl variant='standard' sx={{ width: { xs: '100%', md: '60%' }, marginBottom: '24px' }}>
            <InputLabel id='event-prestations-label'>Choisissez une prestation à ajouter</InputLabel>

            <Select
              labelId='event-prestations-label'
              id='event-prestations'
              value=''
              open={isPrestationsMenuOpen}
              onChange={onAddPrestation}
              onOpen={handleMenuOpen}
              onClose={(e) => {handleMenuClose(e)}}
              variant='standard'
              MenuProps={{
                PaperProps: {
                  className: 'full-screen',
                },
              }}
            >
              <MenuItem disableRipple>
                <TextField
                  inputRef={prestationsSearchInputRef}
                  placeholder='Rechercher une prestation...'
                  fullWidth
                  aria-label='input-search'
                  variant='outlined'
                  size='small'
                  value={prestationsSearchTerm}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      paddingRight: '2px',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Button
                          variant='contained'
                          disableElevation
                          color='primary'
                          aria-label='clear search'
                          onClick={() => {
                            setIsPrestationsMenuOpen(false);
                            setPrestationsSearchTerm('');
                          }}
                          sx={{
                            borderRadius: 1,
                            color: 'white',
                          }}
                        >
                          <CheckIcon />
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => setPrestationsSearchTerm(e.target.value.toLowerCase())}
                  onKeyDown={(e) => e.stopPropagation()} // Empêche le Select de réagir aux touches saisies
                />
              </MenuItem>
              {garage &&
                (() => {
                  const allFilteredPrestations = prestationsWithCategory.flatMap((categoryPrestation) =>
                    categoryPrestation.prestations.filter((prestation) =>
                      filterSearch(prestation.name.toLowerCase())
                    ),
                  );

                  if (prestationsSearchTerm && allFilteredPrestations.length === 0) {
                    return (
                      <MenuItem disabled key='no-results'>
                        Aucun résultat
                      </MenuItem>
                    );
                  }

                  const items = prestationsWithCategory.flatMap((categoryPrestation) => {
                    const filteredPrestations = categoryPrestation.prestations.filter((prestation) =>
                      filterSearch(prestation.name.toLowerCase())
                    );

                    if (prestationsSearchTerm) {
                      return filteredPrestations.map((prestation) => renderPrestation(prestation));
                    }

                    const elements = [
                      categoryPrestation.prestations.length > 0 && (
                        <MenuItem
                          key={`${categoryPrestation.code}-header`}
                          aria-label='category'
                          onClick={(event: any) => {
                            event.stopPropagation();
                            handleCategoryClick(categoryPrestation.code);
                          }}
                          style={{ cursor: 'pointer', fontWeight: 'bold' }}
                        >
                          {categoryPrestation.name.toUpperCase()}
                        </MenuItem>
                      ),
                    ];

                    if (expandedCategory === categoryPrestation.code) {
                      elements.push(
                        ...categoryPrestation.prestations.map((prestation: Prestation) => renderPrestation(prestation)),
                      );
                    }

                    return elements;
                  });
                  return items;
                })()}
            </Select>
          </FormControl>
          <Box height='400px' sx={{ width: { xs: '100%', md: '60%' } }}>
            {prestationsKeys.map((prestationId) => {
              const prestation = availablePrestations.find((prestation) => prestation.id === +prestationId);
              if (!(prestation?.id && tmpPrestations[prestation.id])) {
                return null;
              }

              return (
                <>
                  <Box
                    key={prestation.code}
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        position: 'relative',
                        padding: '0 8px',
                      }}
                    >
                      {importIcon(prestation.code) && (
                        <img
                          src={importIcon(prestation.code)}
                          style={{
                            width: '20px',
                            height: '20px',
                            paddingLeft: '4px',
                            marginRight: '7px',
                            //opacity: 0.7,
                          }}
                        />
                      )}
                      <Typography variant='body2'>
                        {prestation.name}
                        &nbsp;
                        {tmpPrestations[prestation.id].applications
                          ?.map((application) => getApplicationShortLabel(application))
                          .join(' + ')}
                      </Typography>
                      {tmpPrestations[prestation.id].status === 'removed' && (
                        <div
                          id='removed-prestation'
                          style={{
                            position: 'absolute',
                            top: '50%',
                            height: '0',
                            right: '0',
                            left: '0',
                            border: '1px solid red',
                          }}
                        />
                      )}
                      {tmpPrestations[prestation.id].status === 'added' && (
                        <span
                          id='new-prestation'
                          style={{
                            position: 'absolute',
                            top: '-5px',
                            right: '-40px',
                            color: theme.palette.primary.dark,
                            textTransform: 'uppercase',
                            fontFamily: 'Caveat',
                            lineHeight: '11px',
                            fontSize: '11px',
                            fontWeight: 'bold',
                          }}
                        >
                          Nouveau !
                        </span>
                      )}
                    </Box>
                    {tmpPrestations[prestation.id].status === 'removed' ? (
                      <Button
                        variant='text'
                        color='inherit'
                        sx={{ color: '#A1A1A1' }}
                        onClick={() => onResetInitialPrestation(prestation.id)}
                      >
                        Annuler
                      </Button>
                    ) : (
                      <Button variant='text' color='error' onClick={() => onRemovePrestation(prestation.id)}>
                        Supprimer
                      </Button>
                    )}
                  </Box>
                  {prestation.code === 'OTHER' && (
                    <Box style={{ width: '60%', marginLeft: '10px' }}>
                      <TextField fullWidth size='small' label='Précisez la prestation...' name='otherReason' />
                    </Box>
                  )}
                </>
              );
            })}
            {prestationOTHER && tmpPrestations[prestationOTHER.id] && (
              <>
                <Box
                  key={prestationOTHER.code}
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '8px',
                      position: 'relative',
                      padding: '0 8px',
                    }}
                  >
                    {importIcon(prestationOTHER.code) && (
                      <img
                        src={importIcon(prestationOTHER.code)}
                        style={{
                          width: '20px',
                          height: '20px',
                          paddingLeft: '4px',
                          marginRight: '7px',
                          //opacity: 0.7,
                        }}
                      />
                    )}
                    <Typography variant='body2'>
                      {prestationOTHER.name}
                      &nbsp;
                      {tmpPrestations[prestationOTHER.id].applications
                        ?.map((application) => getApplicationShortLabel(application))
                        .join(' + ')}
                    </Typography>
                    {tmpPrestations[prestationOTHER.id].status === 'removed' && (
                      <div
                        id='removed-prestation'
                        style={{
                          position: 'absolute',
                          top: '50%',
                          height: '0',
                          right: '0',
                          left: '0',
                          border: '1px solid red',
                        }}
                      />
                    )}
                    {tmpPrestations[prestationOTHER.id].status === 'added' && (
                      <div
                        id='new-prestation'
                        style={{
                          position: 'absolute',
                          top: -5,
                          right: -40,
                          color: theme.palette.primary.dark,
                          textTransform: 'uppercase',
                          fontFamily: 'Caveat',
                          lineHeight: 11,
                          fontSize: 11,
                          fontWeight: 'bold',
                        }}
                      >
                        Nouveau !
                      </div>
                    )}
                  </Box>
                  {tmpPrestations[prestationOTHER.id].status === 'removed' ? (
                    <Button
                      variant='text'
                      color='inherit'
                      sx={{ color: '#A1A1A1' }}
                      onClick={() => onResetInitialPrestation(prestationOTHER.id)}
                    >
                      Annuler
                    </Button>
                  ) : (
                    <Button variant='text' color='error' onClick={() => onRemovePrestation(prestationOTHER.id)}>
                      Supprimer
                    </Button>
                  )}
                </Box>
                <Box sx={{ width: '95%', ml: 1, mt: 1 }}>
                  <TextField
                    fullWidth
                    size='small'
                    label='Précisez la prestation...'
                    name='otherReason'
                    value={otherReason}
                    onChange={onChangeOtherReason}
                  />
                </Box>
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            position: 'absolute',
            bottom: '10px',
            right: '10px',
          }}
        >
          <Button color='inherit' variant='contained' onClick={() => onClose()}>
            Annuler
          </Button>
          <Button
            color='primary'
            variant='contained'
            startIcon={<Save />}
            onClick={onSavePrestationsChange}
            disabled={activePrestations.length === 0}
          >
            Enregistrer
          </Button>
        </DialogActions>
      </Box>

      {isApplicationChoiceDialogOpenned && focusedPrestation && (
        <ConfirmationDialog
          open
          onClose={handleCancelApplicationChoice}
          showConfirm={focusedPrestation.multipleApplication}
          confirmLabel='CONFIRMER'
          onConfirm={onCloseApplicationChoiceDialog}
          closeLabel='Annuler'
          title={`${focusedPrestation.name} - Application`}
          message={
            <StyledToggleButtonGroup
              orientation={isMobile ? 'vertical' : 'horizontal'}
              size='small'
              style={flexCenter}
              onChange={onChangeApplicationChoice}
              value={tmpPrestations[focusedPrestation.id].applications}
              aria-label='application-choice'
            >
              {focusedPrestation.operations
                ?.filter(
                  (o, index, array) =>
                    o.application && array.findIndex((op) => op.application === o.application) === index,
                )
                .sort((a, b) => {
                  const labelA = getApplicationShortLabel(a.application);
                  const labelB = getApplicationShortLabel(b.application);
                  return labelB.localeCompare(labelA);
                })
                .map((operation) => (
                  <ToggleButton
                    size='large'
                    style={flexCenter}
                    key={operation.code}
                    sx={{ width: 100 }}
                    value={operation.application ?? ''}
                  >
                    {getApplicationShortLabel(operation.application)}
                  </ToggleButton>
                ))}
            </StyledToggleButtonGroup>
          }
        />
      )}
    </>
  );
};
