import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  ClickAwayListener,
  Collapse,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  darken,
  lighten,
} from '@mui/material';
import React, { type FunctionComponent, memo, useEffect, useRef, useState } from 'react';
import theme from '../theme'; // Import du thème personnalisé
import { colors, type FormField } from '../helpers/Constants';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../slices/snackbarSlice';
import ImageUpload from '../components/ImageUpload';
import {
  AddressFields,
  type Document,
  DocumentType,
  type Garage,
  GarageService,
  Logger,
  type MovaFormField,
  PrestationState,
  Schedule,
  ScheduleFields,
  SubscriptionState,
  type CategoryPrestation,
} from '@movalib/movalib-commons';
import { useHistory } from 'react-router-dom';
import type { DaySchedule } from '@movalib/movalib-commons';
import { format } from 'date-fns';
import { deleteCookies, flexEnd, flexStart } from '../helpers/Tools';
import CarRepairIcon from '@mui/icons-material/CarRepairRounded';
import TeamIcon from '@mui/icons-material/EngineeringRounded';
import InfoIcon from '@mui/icons-material/InfoRounded';
import ServicesIcon from '@mui/icons-material/BuildRounded';
import { StyledTableCell, StyledTableRow } from '../helpers/Styled';
import type Prestation from '@movalib/movalib-commons/dist/src/models/Prestation';
import LogoutIcon from '@mui/icons-material/LogoutRounded';
import EditIcon from '@mui/icons-material/EditRounded';
import ValidateIcon from '@mui/icons-material/CheckRounded';
import CancelIcon from '@mui/icons-material/CloseRounded';
import AddIcon from '@mui/icons-material/AddRounded';
import DoneIcon from '@mui/icons-material/DoneRounded';
import EmptyCard from '../components/EmptyCard';
import AddSupplierDialog from '../dialogs/AddSupplierDialog';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import { flexCenter, validateField } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import AddPrestationDialog from '../dialogs/AddPrestationDialog';
import SubscriptionIcon from '@mui/icons-material/CardMembershipRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CategoriesColorHelp from '../assets/images/help/presta_categories_color.png';

export enum SettingsMenu {
  GARAGE,
  TEAM,
  SERVICES,
  ACCOUNT,
  SUBSCRIPTION,
}

interface TabPanelProps {
  index: number;
  value: number;
  children?: React.ReactNode;
}

type Form = {
  name: FormField;
  streetNumber: FormField;
  streetName: FormField;
  additional: FormField;
  postalCode: FormField;
  cityName: FormField;
  workforce: FormField;
  contactPhone: FormField;
  contactEmail: FormField;
  photo: FormField;
  schedules: FormField;
  services?: FormField;
  customer?: FormField;
  vehicle?: FormField;
  notes?: FormField;
  quoteId?: FormField;
};

const initialFormState = {
  name: { value: '', isValid: true },
  streetNumber: { value: '', isValid: true },
  streetName: { value: '', isValid: true },
  additional: { value: '', isValid: true },
  postalCode: { value: '', isValid: true },
  cityName: { value: '', isValid: true },
  workforce: { value: 0, isValid: true },
  contactPhone: { value: '', isValid: true },
  contactEmail: { value: '', isValid: true },
  photo: { value: '', isValid: true },
  schedules: { value: [], isValid: true },
  services: { value: [], isValid: true },
  customer: { value: null, isValid: true },
  vehicle: { value: '', isValid: true },
  notes: { value: '', isValid: true },
  quoteId: { value: '', isValid: true },
};

const drawerWidth = 240;

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 11 }}>{children}</Box>}
    </div>
  );
}

interface SettingsProps {
  garageId: string;
}

export interface CatergoryPrestationAggrs extends CategoryPrestation {
  prestations: Prestation[];
}
const Settings: FunctionComponent<SettingsProps> = ({ garageId }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState<Form>(initialFormState);
  // Récupération des données garage pour l'utilisateur connecté
  const [garage, setGarage] = useState<Garage | null>();
  const hasRefreshedRef = useRef(false);
  const history = useHistory();
  const [selectedPanel, setSelectedPanel] = useState<SettingsMenu>(SettingsMenu.GARAGE);
  const [openAddSupplier, setOpenAddSupplier] = useState(false);
  const [openAddPrestation, setOpenAddPrestation] = useState(false);
  const [openDowntimeInfo, setOpenDowntimeInfo] = React.useState(false);
  const [openAppointmentDelay, setOpenAppointmentDelay] = React.useState(false);
  const [prestationsWithCategory, setPrestationsWithCategory] = useState<CatergoryPrestationAggrs[]>([]);
  const categoryOpen = useRef('');

  const [garageLogo, setGarageLogo] = useState<Document | undefined>(undefined);

  useEffect(() => {
    if (!hasRefreshedRef.current) {
      refreshGarage();
      hasRefreshedRef.current = true;
    }
    setSelectedPanel(SettingsMenu.GARAGE);
  }, []);

  // Chargement initial des données du garage
  useEffect(() => {
    if (garage) {
      setForm((prevForm) => ({
        ...prevForm,
        name: { ...prevForm['name'], value: garage.name },
        streetName: { ...prevForm['streetName'], value: garage.address.streetName },
        additional: { ...prevForm['additional'], value: garage.address.additional },
        postalCode: { ...prevForm['postalCode'], value: garage.address.postalCode },
        cityName: { ...prevForm['cityName'], value: garage.address.cityName },
        workforce: { ...prevForm['workforce'], value: garage.workforce },
        schedules: { ...prevForm['schedules'], value: garage.schedules },
        contactPhone: { ...prevForm['contactPhone'], value: garage.contactPhone },
        contactEmail: { ...prevForm['contactEmail'], value: garage.contactEmail },
      }));

      // récupération du logo
      setGarageLogo(garage.documents?.filter((doc) => doc.type === DocumentType.GARAGE_LOGO)[0]);
    }
  }, [garage]); // Exécute le hook à chaque fois que le garage change

  const handleChange = (fieldName: keyof Form, newValue: any, save?: boolean) => {
    setForm((prevForm) => ({ ...prevForm, [fieldName]: { ...prevForm[fieldName], value: newValue } }));

    if (save) {
      let request = {
        [fieldName]: newValue,
      };

      saveSetting(request);
    }
  };

  const refreshGarage = () => {
    if (garageId) {
      GarageService.getGarageSettings(garageId).then((response) => {
        if (response.success) {
          // Purge du state global (permettra de refresh lors du retour à l'agenda)
          const categoryPrestations = response.data?.prestationCategories as CategoryPrestation[];
          const categoryPrestationTable = [] as CatergoryPrestationAggrs[];
          categoryPrestations.forEach((category: CategoryPrestation) => {
            const prestations = response.data?.prestations.filter(
              (p) => p.categoryCode === category.code,
            ) as Prestation[];
            categoryPrestationTable.push({ ...category, prestations: prestations });
          });
          categoryPrestationTable.sort((a, b) => a.position - b.position);
          setPrestationsWithCategory(categoryPrestationTable);
          setGarage(response.data);
          Logger.info(response.data);
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? 'Chargement des données impossible',
              severity: 'error',
            }),
          );
        }
      });
    }
  };

  const handleAddressChange = (fieldName: keyof Form, newValue: any, save?: boolean) => {
    setForm((prevForm) => ({ ...prevForm, [fieldName]: { ...prevForm[fieldName], value: newValue } }));

    // On renvoit l'ensemble de l'adresse
    if (save) {
      let request = {
        address: {
          streetName: fieldName === 'streetName' ? newValue : form.streetName.value,
          additional: fieldName === 'additional' ? newValue : form.additional.value,
          postalCode: fieldName === 'postalCode' ? newValue : form.postalCode.value,
          cityName: fieldName === 'cityName' ? newValue : form.cityName.value,
        },
      };

      saveSetting(request);
    }
  };

  const handleScheduleChange = (schedules: DaySchedule[]) => {
    if (schedules) {
      // On contrôle l'absence d'erreur dans le tableau de schedule
      const hasErrors = schedules.some((day) => day.intervals.some((interval) => interval.error !== null));

      Logger.info(schedules);

      if (!hasErrors) {
        // On crée un objet Schedule sur la base du DaySchedule reçu de ScheduleFields
        let newSchedules = new Array<Schedule>();
        schedules.forEach((s) => {
          // Seuls les journées actives (cochées) sont envoyées
          if (s.checked) {
            newSchedules.push(
              new Schedule(
                s.day,
                s.intervals.map(({ startTime, endTime, countryCode }) => ({
                  startTime: startTime ? format(new Date(startTime), 'HH:mm') : null,
                  endTime: endTime ? format(new Date(endTime), 'HH:mm') : null,
                  countryCode,
                })),
                true,
              ),
            );
          }
        });

        console.log(JSON.stringify(newSchedules));
        let request = {
          schedules: newSchedules,
        };

        saveSetting(request);
      }
    }
  };

  const handleUploadPhoto = (file: File) => {
    if (file) {
      console.log(file);

      if (file) {
        let formData = new FormData();
        formData.append('file', file);

        uploadLogo(formData);
      }
    }
  };

  const uploadLogo = (request: FormData) => {
    if (garage) {
      GarageService.uploadLogo(garage.id, request).then((response) => {
        if (response.success) {
          dispatch(
            setSnackbar({ open: true, message: response.data ?? 'Modification enregistrée', severity: 'success' }),
          );
          refreshGarage();
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? 'Modification du logo impossible',
              severity: 'error',
            }),
          );
        }
      });
    }
  };

  const saveSetting = (request: any) => {
    if (garage) {
      GarageService.updateGarage(garage.id, request).then((response) => {
        if (response.success) {
          dispatch(
            setSnackbar({ open: true, message: response.data ?? 'Modifications enregistrées', severity: 'success' }),
          );
          refreshGarage();
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? 'Modifications des données impossible',
              severity: 'error',
            }),
          );
        }
      });
    }
  };

  const savePrestation = (prestation: Prestation, request: any) => {
    if (garage && prestation) {
      GarageService.updateGaragePrestation(garage.id, String(prestation.id), request).then((response) => {
        if (response.success) {
          dispatch(
            setSnackbar({ open: true, message: response.data ?? 'Modifications enregistrées', severity: 'success' }),
          );
          refreshGarage();
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? 'Modifications des données impossible',
              severity: 'error',
            }),
          );
        }
      });
    }
  };

  const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
  };

  const handleLogoutClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();

    // Suppression des cookies
    deleteCookies();
    // Suppression des données persistées en localStorage
    localStorage.clear();
    // Redirection vers le login
    history.push('/login');
  };

  const handleCloseAddSupplier = (refresh: boolean) => {
    setOpenAddSupplier(false);
    // On refresh les data si demandée
    if (refresh) {
      refreshGarage();
    }
  };

  const handleCloseAddPrestation = (refresh: boolean) => {
    setOpenAddPrestation(false);
    // On refresh les data si demandée
    if (refresh) {
      refreshGarage();
    }
  };

  const settingsMenuList = () => (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, pt: 10 }}
      role='presentation'
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <Box sx={{ flexGrow: 1 }}>
        {' '}
        {/* Cette Box prendra tout l'espace vertical disponible */}
        <List>
          <ListItem
            key={'menu-1'}
            disablePadding
            sx={{
              backgroundColor:
                selectedPanel === SettingsMenu.GARAGE
                  ? darken(theme.palette.background.paper, 0.1)
                  : lighten(theme.palette.background.paper, 0.6),
            }}
          >
            <ListItemButton onClick={() => setSelectedPanel(SettingsMenu.GARAGE)}>
              <ListItemIcon>
                <CarRepairIcon />
              </ListItemIcon>
              <ListItemText primary={'Mon garage'} />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={'menu-2'}
            disablePadding
            sx={{
              backgroundColor:
                selectedPanel === SettingsMenu.TEAM
                  ? darken(theme.palette.background.paper, 0.1)
                  : lighten(theme.palette.background.paper, 0.6),
            }}
          >
            <ListItemButton onClick={() => setSelectedPanel(SettingsMenu.TEAM)}>
              <ListItemIcon>
                <TeamIcon />
              </ListItemIcon>
              <ListItemText primary={'Mon équipe'} />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={'menu-3'}
            disablePadding
            sx={{
              backgroundColor:
                selectedPanel === SettingsMenu.SERVICES
                  ? darken(theme.palette.background.paper, 0.1)
                  : lighten(theme.palette.background.paper, 0.6),
            }}
          >
            <ListItemButton onClick={() => setSelectedPanel(SettingsMenu.SERVICES)}>
              <ListItemIcon>
                <ServicesIcon />
              </ListItemIcon>
              <ListItemText primary={'Mes services'} />
            </ListItemButton>
          </ListItem>
          {garage?.subscriptions &&
            garage?.subscriptions.filter((s) => s.garageId === garage.id && s.state === SubscriptionState.ACTIVE)
              .length > 0 && (
              <ListItem
                key={'menu-4'}
                disablePadding
                sx={{
                  backgroundColor:
                    selectedPanel === SettingsMenu.SUBSCRIPTION
                      ? darken(theme.palette.background.paper, 0.1)
                      : lighten(theme.palette.background.paper, 0.6),
                }}
              >
                <ListItemButton onClick={() => setSelectedPanel(SettingsMenu.SUBSCRIPTION)}>
                  <ListItemIcon>
                    <SubscriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Mon abonnement'} />
                </ListItemButton>
              </ListItem>
            )}
        </List>
      </Box>
      <Box>
        {' '}
        {/* Cette Box sera positionnée en bas */}
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={(e) => handleLogoutClick(e)}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={'Déconnexion'} />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Box>
  );

  const PrestationRow = memo((props: { prestation: Prestation; withCategory: boolean }) => {
    type MovaOperationForm = {
      active: MovaFormField; // Boolean
    };

    type MovaPrestationForm = {
      active: MovaFormField; // Boolean
      name: MovaFormField;
      downtime: MovaFormField; // Integer
      appointmentDelay: MovaFormField; // Integer
      operations: MovaOperationForm[]; // Liste des opérations
    };

    const initialPrestationFormState = {
      active: { value: false, isValid: true },
      name: { value: '', isValid: true },
      downtime: { value: 0, isValid: true },
      appointmentDelay: { value: 0, isValid: true },
      operations: [],
    };

    const { prestation } = props;
    const [openRow, setOpenRow] = useState(false);
    const [editRow, setEditRow] = useState(false);
    const [formRow, setFormRow] = useState<MovaPrestationForm>(initialPrestationFormState);

    useEffect(() => {
      // Initialisation du formulaire
      initForm();
    }, [prestation]);

    const initForm = () => {
      // Initialisation du formulaire
      setFormRow(() => {
        const initialState = initialPrestationFormState;

        if (prestation) {
          initialState.active.value = true;
          initialState.downtime.value = prestation.downtime;
          initialState.appointmentDelay.value = prestation.appointmentDelay;
        }

        return initialState;
      });
    };

    const handleInputChange = (fieldName: keyof MovaPrestationForm, newValue: any) => {
      const newField: MovaFormField = { [fieldName]: { value: newValue, isValid: true } };

      setFormRow({ ...formRow, ...newField });
    };

    const validateForm = () => {
      let newForm: MovaPrestationForm = { ...formRow };

      // Validator pour les champs obligatoires
      newForm.downtime = validateField(formRow.downtime, (value) => !!value, 'Champ obligatoire');
      newForm.appointmentDelay = validateField(
        formRow.appointmentDelay,
        (value) => Number(value) >= 0,
        'Champ obligatoire',
      );

      // Validator pour l'email
      newForm.downtime = validateField(
        newForm.downtime,
        (value) => {
          return Number(value) > 0;
        },
        "Le temps d'immobilistation ne peut pas être 0",
      );

      setFormRow(newForm);
      Logger.info(newForm);
      return !Boolean(newForm.downtime.error || newForm.appointmentDelay.error);
    };

    const handleUpdatePrestation = (prestation: Prestation) => {
      if (prestation && validateForm()) {
        // On contrôle les données saisies
        let request = {
          downtime: formRow.downtime.value,
          appointmentDelay: formRow.appointmentDelay.value,
        };

        savePrestation(prestation, request);

        // Fermeture de l'édition de la ligne
        initForm();
        setEditRow(!editRow);
      }
    };

    const handleEnablePrestation = (prestation: Prestation) => {
      if (garage && prestation) {
        GarageService.enableGaragePrestation(garage.id, String(prestation.id)).then((response) => {
          if (response.success) {
            dispatch(setSnackbar({ open: true, message: response.data ?? 'Prestation activée', severity: 'success' }));
            refreshGarage();
          } else {
            dispatch(
              setSnackbar({
                open: true,
                message: response.error ?? 'Activation de la prestation impossible',
                severity: 'error',
              }),
            );
          }
        });
      }
    };

    const handleDisablePrestation = (prestation: Prestation) => {
      if (garage && prestation) {
        GarageService.disableGaragePrestation(garage.id, String(prestation.id)).then((response) => {
          if (response.success) {
            dispatch(
              setSnackbar({ open: true, message: response.data ?? 'Prestation désactivée', severity: 'success' }),
            );
            refreshGarage();
          } else {
            dispatch(
              setSnackbar({
                open: true,
                message: response.error ?? 'Désactivation de la prestation impossible',
                severity: 'error',
              }),
            );
          }
        });
      }
    };

    return (
      <>
        <React.Fragment>
          <StyledTableRow
            sx={{
              '& > *': { borderBottom: 'unset' },
              backgroundColor: prestation.state === PrestationState.NEW ? theme.palette.secondary.light : 'transparent',
              py: 2,
            }}
            onClick={() => (!(editRow || prestation.state === PrestationState.NEW) ? setOpenRow(!openRow) : null)}
          >
            {props.withCategory && (
              <TableCell>
                {garage?.prestationCategories
                  .find((category) => category.code === prestation.categoryCode)
                  ?.name.toUpperCase()}
              </TableCell>
            )}
            <TableCell sx={{ pl: 1 }}>{prestation.name}</TableCell>
            <TableCell>
              {!editRow ? (
                <>
                  <b>{prestation.downtime}</b> h{' '}
                </>
              ) : (
                <Box style={flexStart}>
                  <TextField
                    id='downtime'
                    value={formRow.downtime.value}
                    type='number'
                    required
                    size='small'
                    onClick={(e) => {
                      e.stopPropagation(); // Cela empêche l'événement de remonter au TableRow
                    }}
                    onChange={(e) => {
                      e.stopPropagation(); // Cela empêche l'événement de remonter au TableRow
                      handleInputChange('downtime', e.target.value);
                    }}
                    error={!formRow.downtime.isValid}
                    sx={{ width: '30%' }}
                    InputProps={{ inputProps: { min: 0 } }}
                  />{' '}
                  <Typography variant='body2' sx={{ ml: 3 }}>
                    {' '}
                    Heure(s)
                  </Typography>{' '}
                </Box>
              )}
            </TableCell>
            <TableCell>
              {!editRow ? (
                <>
                  <b>{prestation.appointmentDelay}</b> jr{' '}
                </>
              ) : (
                <Box style={flexStart}>
                  <TextField
                    id='appointmentDelay'
                    value={formRow.appointmentDelay.value}
                    type='number'
                    required
                    size='small'
                    onClick={(e) => {
                      e.stopPropagation(); // Cela empêche l'événement de remonter au TableRow
                    }}
                    onChange={(e) => {
                      e.stopPropagation(); // Cela empêche l'événement de remonter au TableRow
                      handleInputChange('appointmentDelay', e.target.value);
                    }}
                    sx={{ width: '30%' }}
                    InputProps={{ inputProps: { min: 0 } }}
                  />{' '}
                  <Typography variant='body2' sx={{ ml: 3 }}>
                    {' '}
                    Jour(s)
                  </Typography>{' '}
                </Box>
              )}
            </TableCell>

            <TableCell align='right'>
              {!editRow ? (
                <Grid container sx={{ p: 0 }} style={flexEnd}>
                  {prestation.active && (
                    <Button
                      color='inherit'
                      variant='contained'
                      style={flexCenter}
                      aria-label='modifier prestation'
                      size='small'
                      sx={{ ml: 2, textTransform: 'none' }}
                      onClick={(e) => {
                        e.stopPropagation(); // Cela empêche l'événement de remonter au TableRow
                        setEditRow(!editRow);
                      }}
                    >
                      <EditIcon sx={{ fontSize: '1rem', mr: 1 }} />
                      MODIFIER
                    </Button>
                  )}
                  {prestation.state === PrestationState.VALIDATED && (
                    <Tooltip
                      title={
                        prestation.active
                          ? 'Vos clients ne pourront plus choisir cette prestation'
                          : 'Vos clients pourront choisir cette prestation'
                      }
                      placement='top'
                      followCursor
                      enterDelay={400}
                      slotProps={{
                        tooltip: {
                          sx: {
                            maxWidth: 'none',
                            display: 'flex',
                          },
                        },
                      }}
                    >
                      <Button
                        color={prestation.active ? 'error' : 'primary'}
                        variant='contained'
                        style={flexCenter}
                        aria-label='modifier prestation'
                        size='small'
                        sx={{ ml: 2, textTransform: 'none' }}
                        onClick={(e) => {
                          e.stopPropagation(); // Cela empêche l'événement de remonter au TableRow
                          {
                            prestation.active
                              ? handleDisablePrestation(prestation)
                              : handleEnablePrestation(prestation);
                          }
                        }}
                      >
                        {prestation.active ? (
                          <>
                            <CancelIcon sx={{ fontSize: '1rem', mr: 1 }} />
                            DÉSACTIVER
                          </>
                        ) : (
                          <>
                            <DoneIcon sx={{ fontSize: '1rem', mr: 1 }} />
                            ACTIVER CETTE PRESTATION
                          </>
                        )}
                      </Button>
                    </Tooltip>
                  )}

                  {prestation.state === PrestationState.NEW && (
                    <Typography
                      variant='body2'
                      sx={{ mt: 0.5, mb: 0.5 }}
                      style={flexCenter}
                      color={theme.palette.text.secondary}
                    >
                      En cours de configuration ...
                    </Typography>
                  )}
                </Grid>
              ) : (
                <Grid container sx={{ p: 0 }} style={flexEnd}>
                  <Button
                    disableElevation
                    style={flexCenter}
                    color='primary'
                    aria-label='valider modifications'
                    size='small'
                    variant='contained'
                    onClick={(e) => {
                      e.stopPropagation(); // Cela empêche l'événement de remonter au TableRow
                      e.preventDefault();
                      handleUpdatePrestation(prestation);
                    }}
                  >
                    <ValidateIcon sx={{ fontSize: '1rem', mr: 1 }} />
                    Enregistrer
                  </Button>
                  <Button
                    color='inherit'
                    variant='text'
                    style={flexCenter}
                    aria-label='annuler modifications'
                    size='small'
                    sx={{ ml: 2 }}
                    onClick={(e) => {
                      e.stopPropagation(); // Cela empêche l'événement de remonter au TableRow
                      initForm();
                      setEditRow(!editRow);
                    }}
                  >
                    <CancelIcon sx={{ fontSize: '1.1rem', mr: 1 }} />
                    Annuler
                  </Button>
                </Grid>
              )}
            </TableCell>
          </StyledTableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
              <Collapse in={openRow} timeout='auto' unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Typography variant='subtitle1' gutterBottom component='div'>
                    <b>Opérations associées</b>
                  </Typography>
                  {prestation?.operations?.map((operation) => (
                    <Typography variant='body2' key={operation.description} gutterBottom component='div'>
                      {operation.description}
                    </Typography>
                  ))}
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      </>
    );
  });

  const CategoryPrestationRow = memo((props: { categoryPrestationRow: CatergoryPrestationAggrs }) => {
    const [open, setOpen] = React.useState(categoryOpen.current === props.categoryPrestationRow.code);
    const { categoryPrestationRow } = props;
    const [selectedColor, setSelectedColor] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleOpen = () => {
      categoryOpen.current = categoryPrestationRow.code;
      setOpen(!open);
    };

    useEffect(() => {
      setSelectedColor(categoryPrestationRow.color);
    }, []);
    const handleChangeColor = (event: SelectChangeEvent<string>) => {
      setSelectedColor(event.target.value);
      setIsLoading(true);
      GarageService.updateColorPrestationCategory(garageId, categoryPrestationRow.code, event.target.value).finally(
        () => {
          setIsLoading(false);
        },
      );
    };

    return (
      <React.Fragment>
        <StyledTableRow
          sx={{
            '& > *': { borderBottom: 'unset' },
            backgroundColor: open ? theme.palette.primary.light : '',
            cursor: 'pointer',
          }}
          onClick={() => handleOpen()}
        >
          <TableCell sx={{ px: 2, py: '6px' }}>
            <IconButton aria-label='expand row' size='small' onClick={() => handleOpen()}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component='th' scope='row' sx={{ px: 2, py: '6px' }}>
            {categoryPrestationRow.name.toUpperCase()}
          </TableCell>
          <TableCell sx={{ px: 2, py: 0 }}>{categoryPrestationRow.description}</TableCell>
          <TableCell align='center' sx={{ px: 2, py: '6px' }}>
            {isLoading ? (
              <Box display='flex' justifyContent='center' alignItems='center'>
                <CircularProgress size={24} />
              </Box>
            ) : (
              <FormControl fullWidth variant='standard'>
                <Select
                  size='small'
                  value={selectedColor}
                  onChange={(e) => {
                    e.stopPropagation(); // Arrête la propagation pour éviter d'ouvrir la ligne
                    handleChangeColor(e);
                  }}
                  displayEmpty
                  renderValue={(value: string) =>
                    value === '' || !value ? (
                      <Typography sx={{ color: theme.palette.text.secondary }}>Choisissez une couleur...</Typography>
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <Box
                          sx={{
                            width: 20,
                            height: 20,
                            borderRadius: '50%',
                            backgroundColor: value,
                          }}
                        />
                        {colors.find((color) => color.hex === value)?.name}
                      </Box>
                    )
                  }
                  onClick={(e) => e.stopPropagation()} // Ajoutez ceci pour éviter la propagation du clic
                >
                  {colors.map((color) => (
                    <MenuItem key={color.hex} value={color.hex}>
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          borderRadius: '50%',
                          backgroundColor: color.hex,
                          marginRight: '10px',
                        }}
                      />
                      {color.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </TableCell>
        </StyledTableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box sx={{ margin: 1 }}>
                {categoryPrestationRow?.prestations &&
                [...categoryPrestationRow.prestations].filter((p) => p.active).length === 0 ? (
                  <Typography variant='h6' gutterBottom component='div'>
                    Pas de prestation pour cette catégorie
                  </Typography>
                ) : (
                  <React.Fragment>
                    <Table size='small' aria-label='purchases'>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Nom</StyledTableCell>
                          <StyledTableCell>
                            Immobilisation véhicule
                            <ClickAwayListener onClickAway={() => setOpenDowntimeInfo(false)}>
                              <Tooltip
                                placement='top'
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                onClose={() => setOpenDowntimeInfo(false)}
                                open={openDowntimeInfo}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title='Permet de définir la durée par défaut du rendez-vous sur votre agenda'
                              >
                                <IconButton
                                  size='small'
                                  color='primary'
                                  onClick={() => setOpenDowntimeInfo(true)}
                                  sx={{ color: theme.palette.primary.main, p: 0, ml: 1, mb: '2px' }}
                                >
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            </ClickAwayListener>
                          </StyledTableCell>
                          <StyledTableCell>
                            Délai prise RDV
                            <ClickAwayListener onClickAway={() => setOpenAppointmentDelay(false)}>
                              <Tooltip
                                placement='top'
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                onClose={() => setOpenAppointmentDelay(false)}
                                open={openAppointmentDelay}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title='Délai minimum avant de pouvoir envisager une prise de rendez-vous (côté client)'
                              >
                                <IconButton
                                  size='small'
                                  color='primary'
                                  onClick={() => setOpenAppointmentDelay(true)}
                                  sx={{ color: theme.palette.primary.main, p: 0, ml: 1, mb: '2px' }}
                                >
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            </ClickAwayListener>
                          </StyledTableCell>
                          <StyledTableCell>Actions</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {categoryPrestationRow?.prestations &&
                          [...categoryPrestationRow.prestations]
                            .filter((p) => p.active)
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((prestation) => (
                              <PrestationRow key={prestation.id} withCategory={false} prestation={prestation} />
                            ))}
                      </TableBody>
                    </Table>
                  </React.Fragment>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  });

  const handleOpenAddSupplier = () => {
    setOpenAddSupplier(true);
  };

  const handleOpenAddPrestation = () => {
    setOpenAddPrestation(true);
  };

  const handleDeleteSupplier = (supplierId: number) => {
    if (garage && garage.id && supplierId) {
      // Suppression du grossiste
      GarageService.deleteGarageSupplier(garage.id, String(supplierId)).then((response) => {
        if (response.success) {
          dispatch(setSnackbar({ open: true, message: response.data ?? 'Grossiste supprimé', severity: 'success' }));
          refreshGarage();
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? 'Suppression du grossiste impossible',
              severity: 'error',
            }),
          );
        }
      });
    }
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3, ml: 1 }} sx={{ pl: 2, pt: 0 }}>
        <Drawer
          anchor={'left'}
          open={true}
          variant='persistent'
          sx={{
            position: 'fixed',
            zIndex: 10,
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              backgroundColor: lighten(theme.palette.background.paper, 0.6),
            },
          }}
        >
          {settingsMenuList()}
        </Drawer>

        <Grid item xs={12} sx={{ ml: `${drawerWidth}px` }}>
          <TabPanel value={selectedPanel} index={SettingsMenu.GARAGE}>
            <Grid container sx={{ pb: 2 }}>
              <Grid item xs={12} sx={{ mr: 2 }}>
                <Alert severity='success' sx={{ mb: 2, borderRadius: 20 }} icon={<InfoIcon />} className='styled-alert'>
                  Ces informations sont visibles par vos clients depuis l'application Movalib
                </Alert>
              </Grid>

              <Grid item xs={6}>
                <Typography variant='h6' color={theme.palette.text.secondary}>
                  <b>Description</b>
                </Typography>
                <TextField
                  id='settings-garage-name'
                  label='NOM DU GARAGE'
                  value={form.name.value}
                  required
                  size='small'
                  onBlur={(e) => handleChange('name', e.target.value, true)}
                  onChange={(e) => handleChange('name', e.target.value, false)}
                  sx={{ width: '98%', mt: 2.5 }}
                />
                <Typography variant='h6' color={theme.palette.text.secondary} sx={{ mt: 2 }}>
                  <b>Adresse</b>
                </Typography>
                <AddressFields
                  form={form}
                  size='small'
                  handleChange={(fieldName, newValue, save) => handleAddressChange(fieldName, newValue, save)}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
              >
                {garageLogo ? (
                  <Tooltip title='Modifier votre logo'>
                    <Button
                      onClick={() => handleUploadPhoto}
                      component='label'
                      sx={{ border: `1px solid ${theme.palette.grey[400]}`, width: '300px', height: '300px', m: 2 }}
                    >
                      <img
                        src={garageLogo.fileSignedUrl}
                        alt='Logo du Garage'
                        style={{ width: '250px', height: '250px' }}
                      />
                      <input
                        type='file'
                        accept='image/*'
                        hidden // Hide the ugly default file input
                        onChange={(e) => handleUploadPhoto(e.target.files?.[0]!)}
                      />
                    </Button>
                  </Tooltip>
                ) : (
                  <ImageUpload onUpload={handleUploadPhoto} title='AJOUTER VOTRE LOGO' />
                )}
                <Alert severity='success' icon={<InfoIcon />} sx={{ borderRadius: 20, mt: 2 }} className='styled-alert'>
                  Conseil pour un rendu optimal : une image carrée et pas trop lourde (moins de 1 Mo).
                </Alert>
              </Grid>
              <Grid item xs={12} sx={{ mt: 0 }}>
                <Typography variant='h6' color={theme.palette.text.secondary} sx={{ mt: 2, mb: 3 }}>
                  <b>Contact</b>
                </Typography>
                <Grid container>
                  <Grid item xs={3} sx={{ mb: 2 }}>
                    <TextField
                      id='settings-garage-contact-email'
                      label='EMAIL'
                      value={form.contactEmail.value}
                      required
                      size='small'
                      sx={{ width: '90%' }}
                      onBlur={(e) => handleChange('contactEmail', e.target.value, true)}
                      onChange={(e) => handleChange('contactEmail', e.target.value, false)}
                    />
                  </Grid>
                  <Grid item xs={9} sx={{ float: 'left' }}>
                    <TextField
                      id='settings-garage-contact-phone'
                      label='N° DE TÉLÉPHONE'
                      value={form.contactPhone.value}
                      required
                      size='small'
                      onBlur={(e) => handleChange('contactPhone', e.target.value, true)}
                      onChange={(e) => handleChange('contactPhone', e.target.value, false)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6' color={theme.palette.text.secondary} sx={{ mt: 2, mb: 3 }}>
                  <b>Horaires d'ouverture</b>
                </Typography>
                <ScheduleFields
                  schedules={form.schedules.value}
                  size='small'
                  onChange={handleScheduleChange}
                  timePickerStep={30}
                />
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={selectedPanel} index={SettingsMenu.TEAM}>
            <Grid container sx={{ pb: 2, pr: 4 }}>
              <Grid item xs={12}>
                <Typography variant='h6' color={theme.palette.text.secondary}>
                  <b>Effectif atelier</b>
                </Typography>
                <TextField
                  id='settings-workforce'
                  label='NOMBRE DE RÉPARATEURS'
                  value={form.workforce.value}
                  disabled
                  type='number'
                  required
                  size='small'
                  onBlur={(e) => handleChange('workforce', e.target.value, true)}
                  onChange={(e) => handleChange('workforce', e.target.value, false)}
                  error={Boolean(form.workforce.error)}
                  sx={{ width: '50%', mt: 2.5 }}
                />
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={selectedPanel} index={SettingsMenu.SERVICES}>
            <Grid container sx={{ pb: 2, pr: 4 }}>
              <Grid item xs={12}>
                <Typography variant='h6' color={theme.palette.text.secondary}>
                  <b>Grossistes</b>
                </Typography>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Button
                    onClick={handleOpenAddSupplier}
                    color='primary'
                    size='small'
                    variant='contained'
                    sx={{ mt: 1, mb: 1, textTransform: 'none', width: '125px' }}
                    startIcon={<AddIcon />}
                  >
                    {' '}
                    Ajouter
                  </Button>
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <TableContainer component={Card} sx={{ mt: 2 }}>
                    <Table stickyHeader size='small' aria-label='suppliers table'>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Nom</StyledTableCell>
                          <StyledTableCell>Email</StyledTableCell>
                          <StyledTableCell>Actions</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {garage?.suppliers && garage?.suppliers.length === 0 && (
                          <TableRow key={'aucun-result'}>
                            <TableCell colSpan={3} align='center' sx={{ color: theme.palette.text.secondary, p: 4 }}>
                              AUCUN RESULTAT
                            </TableCell>
                          </TableRow>
                        )}
                        {garage?.suppliers &&
                          garage.suppliers.map((supplier) => (
                            <TableRow key={supplier.id + '-supplier'} sx={{ '& > *': { borderBottom: 'unset' } }}>
                              <TableCell size='small'>{supplier.name}</TableCell>
                              <TableCell>
                                <b>{supplier.email}</b>
                              </TableCell>
                              <TableCell align='right'>
                                <IconButton
                                  aria-label='supprimer grossiste'
                                  size='small'
                                  onClick={() => handleDeleteSupplier(supplier.id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography variant='h6' color={theme.palette.text.secondary}>
                  <b>Prestations actives</b> ({garage?.prestations.filter((p) => p.active).length})
                </Typography>
                <Typography color={theme.palette.primary.main} sx={{ fontFamily: 'Caveat', fontSize: '1.3rem', mb: 1 }}>
                  <b>
                    Les prestations actives sont disponibles lors d'une prise de rendez-vous par vos clients ou par vous
                    même
                  </b>
                </Typography>
                <Grid container>
                  <Grid item xs={12}>
                    <Button
                      onClick={handleOpenAddPrestation}
                      color='primary'
                      size='small'
                      variant='contained'
                      sx={{ mt: 1, mb: 1, textTransform: 'none', width: '125px' }}
                      startIcon={<AddIcon />}
                    >
                      {' '}
                      Ajouter
                    </Button>
                  </Grid>
                  {(!garage?.prestations || (garage.prestations && garage?.prestations.length === 0)) && (
                    <Grid item xs={12}>
                      <EmptyCard />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TableContainer component={Card} sx={{ mt: 2 }}>
                      <Table stickyHeader size='small' aria-label='prestations table'>
                        <TableHead>
                          <TableRow>
                            <TableCell />
                            <StyledTableCell>Nom</StyledTableCell>
                            <StyledTableCell>Description</StyledTableCell>
                            <StyledTableCell>
                              Couleur
                              <Tooltip
                                placement='top'
                                title={
                                  <Grid container>
                                    <Typography sx={{ fontSize: '0.9rem', mb: 1 }}>
                                      Ajoute un bandeau de couleur sur la gauche de chaque rdv de cette catégorie
                                    </Typography>
                                    <Grid item xs={12} style={flexCenter}>
                                      <img
                                        src={CategoriesColorHelp}
                                        alt='Illustration couleur categories'
                                        style={{ height: '70px', position: 'relative' }}
                                      />
                                    </Grid>
                                  </Grid>
                                }
                              >
                                <IconButton
                                  size='small'
                                  color='primary'
                                  sx={{ color: theme.palette.primary.main, p: 0, ml: 1, mb: '2px' }}
                                >
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {prestationsWithCategory.map((row) => (
                            <CategoryPrestationRow key={row.code} categoryPrestationRow={row} />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Typography variant='h6' color={theme.palette.text.secondary}>
                      <b>Prestations inactives</b> ({garage?.prestations.filter((p) => !p.active).length})
                    </Typography>
                    <Typography
                      color={theme.palette.primary.main}
                      sx={{ fontFamily: 'Caveat', fontSize: '1.3rem', mb: 1 }}
                    >
                      <b>Les prestations inactives ne sont pas disponibles pour une prise de rendez-vous</b>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <TableContainer component={Card} sx={{ mt: 2 }}>
                      <Table stickyHeader size='small' aria-label='prestations table'>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Catégorie</StyledTableCell>
                            <StyledTableCell>Nom</StyledTableCell>
                            <StyledTableCell>
                              Immobilisation véhicule
                              <ClickAwayListener onClickAway={() => setOpenDowntimeInfo(false)}>
                                <Tooltip
                                  placement='top'
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  onClose={() => setOpenDowntimeInfo(false)}
                                  open={openDowntimeInfo}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  title='Permet de définir la durée par défaut du rendez-vous sur votre agenda'
                                >
                                  <IconButton
                                    size='small'
                                    color='primary'
                                    onClick={() => setOpenDowntimeInfo(true)}
                                    sx={{ color: theme.palette.primary.main, p: 0, ml: 1, mb: '2px' }}
                                  >
                                    <InfoIcon />
                                  </IconButton>
                                </Tooltip>
                              </ClickAwayListener>
                            </StyledTableCell>
                            <StyledTableCell>
                              Délai prise RDV
                              <ClickAwayListener onClickAway={() => setOpenAppointmentDelay(false)}>
                                <Tooltip
                                  placement='top'
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  onClose={() => setOpenAppointmentDelay(false)}
                                  open={openAppointmentDelay}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  title='Délai minimum avant de pouvoir envisager une prise de rendez-vous (côté client)'
                                >
                                  <IconButton
                                    size='small'
                                    color='primary'
                                    onClick={() => setOpenAppointmentDelay(true)}
                                    sx={{ color: theme.palette.primary.main, p: 0, ml: 1, mb: '2px' }}
                                  >
                                    <InfoIcon />
                                  </IconButton>
                                </Tooltip>
                              </ClickAwayListener>
                            </StyledTableCell>
                            <StyledTableCell>Actions</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {garage?.prestations && garage?.prestations.length === 0 && (
                            <TableRow key={'0-presta'}>
                              <TableCell colSpan={5} align='center' sx={{ color: theme.palette.text.secondary, p: 4 }}>
                                AUCUN RESULTAT
                              </TableCell>
                            </TableRow>
                          )}
                          {garage?.prestations &&
                            [...garage.prestations]
                              .filter((p) => !p.active)
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .map((prestation) => (
                                <PrestationRow key={prestation.id} withCategory={true} prestation={prestation} />
                              ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={selectedPanel} index={SettingsMenu.SUBSCRIPTION}>
            {garage?.subscriptions &&
              garage?.subscriptions
                .filter((s) => s.garageId === garage.id && s.state === SubscriptionState.ACTIVE)
                .map((subscription) => (
                  <>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <Typography variant='h6' color={theme.palette.text.secondary}>
                        <b>Votre abonnement</b>
                      </Typography>
                      <Typography sx={{ width: '50%', mt: 2.5 }} color={theme.palette.text.secondary}>
                        <b>
                          {subscription.paymentInterval === 'MONTH' ? (
                            <>Mensuel</>
                          ) : subscription.paymentInterval === 'QUARTER' ? (
                            <>Trimestriel (10% de remise)</>
                          ) : (
                            <>Annuel (20% de remise)</>
                          )}
                        </b>
                        <br />
                      </Typography>
                    </Grid>
                    {process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL && (
                      <Grid item xs={12} sx={{ mt: 3 }}>
                        <Button variant='contained'>
                          <a
                            href={
                              process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL +
                              '?prefilled_email=' +
                              subscription.companyEmail
                            }
                            target='_blank'
                            style={{ color: theme.palette.text.primary, textDecoration: 'none' }}
                          >
                            Accès portail facturation MOVALIB
                          </a>
                        </Button>
                      </Grid>
                    )}
                    <Grid item xs={12} sx={{ mt: 3, mr: 2 }}>
                      <Alert icon={<InfoIcon />} sx={{ borderRadius: 20 }} className='styled-alert'>
                        Pour toutes questions relatives à votre abonnement, n’hésitez pas à nous écrire à{' '}
                        <a href='mailto:contact@movalib.com' target='_blank' rel='noreferrer'>
                          compta@movalib.com
                        </a>
                      </Alert>
                    </Grid>
                  </>
                ))}
          </TabPanel>

          <TabPanel value={selectedPanel} index={SettingsMenu.ACCOUNT}></TabPanel>
        </Grid>
      </Grid>

      {openAddSupplier && garage && garage.id && (
        <AddSupplierDialog
          open={openAddSupplier}
          garageId={garage.id}
          onClose={handleCloseAddSupplier}
          showHelp={true}
        />
      )}

      {openAddPrestation && garage && garage.id && (
        <AddPrestationDialog
          open={openAddPrestation}
          garageId={garage.id}
          onClose={handleCloseAddPrestation}
          showHelp={true}
        />
      )}
    </>
  );
};

export default Settings;
