import { ConfirmationDialog, type Customer } from '@movalib/movalib-commons';
import CloseIcon from '@mui/icons-material/CloseRounded';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { type FormikConfig, useFormik } from 'formik';
import InfoIcon from '@mui/icons-material/Info';
import { type FunctionComponent, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useBoolState } from '../../helpers/hooks/useBoolState';
import { useConvertCustomer, useDeleteCustomer, useUpdateCustomer } from '../../query/customer/CustomerQuery';
import { useFetchAdministratedGarages } from '../../query/garage/GarageQuery';
import { setSnackbar } from '../../slices/snackbarSlice';
import theme from '../../theme';
import { CustomerVehiclesList } from './vehicle/CustomerVehiclesList';
import { UpdateIndividualCustomer } from './UpdateIndividualCustomer';
import PersonIcon from '@mui/icons-material/Person';
import { flexCenter } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import { cleanString, flexLeftRow } from '../../helpers/Tools';
import CarsIcon from '@mui/icons-material/DirectionsCarRounded';

interface CustomerDialogProps {
  open: boolean;
  onClose: (refresh: boolean) => void;
  customer: Customer;
}

const formSchema = Yup.object({
  email: Yup.string().email('Email invalide').optional().nullable(),
  firstname: Yup.string().required('Prénom requis'),
  lastname: Yup.string().required('Nom requis'),
  phoneNumber: Yup.string()
    .required('N° de téléphone requis')
    .test('len', 'Le n° de téléphone doit comporter 10 chiffres', (val) => val?.length === 10),
  notes: Yup.string().optional().nullable(),
});

export type Form = Yup.InferType<typeof formSchema>;

export const CustomerDialog: FunctionComponent<CustomerDialogProps> = memo(({ open, onClose, customer }) => {
  const dispatch = useDispatch();

  const { isConfirmationDialogOpen, toggleConfirmationDialogOpen } = useBoolState(false, 'confirmationDialogOpen');
  const { isConvertDialogOpen, toggleConvertDialogOpen } = useBoolState(false, 'convertDialogOpen');

  const { data: garage } = useFetchAdministratedGarages();
  const { mutateAsync: updateCustomer } = useUpdateCustomer();
  const { mutateAsync: deleteCustomer } = useDeleteCustomer();
  const { mutateAsync: convertCustomer } = useConvertCustomer();

  const handleConvertCustomer = useCallback(() => {
    if (!(garage && customer)) {
      return;
    }
    convertCustomer({ garageId: garage.id, customerId: customer.id }).then((response) => {
      if (response.success) {
        onClose(true);
        dispatch(
          setSnackbar({
            open: true,
            message: response.data ?? 'Le client a bien été modifié',
            severity: 'success',
          }),
        );
      } else {
        dispatch(
          setSnackbar({
            open: true,
            message: response.error ?? 'Erreur lors de la modification du client',
            severity: 'error',
          }),
        );
      }
    });
    toggleConvertDialogOpen();
  }, [customer, convertCustomer, dispatch, garage, onClose, toggleConvertDialogOpen]);

  const handleDeleteCustomer = useCallback(() => {
    if (!(garage && customer)) {
      return;
    }
    deleteCustomer({ garageId: garage.id, customerId: customer.id }).then((response) => {
      if (response.success) {
        onClose(true);
        dispatch(
          setSnackbar({
            open: true,
            message: response.data ?? 'Le client a bien été supprimé',
            severity: 'success',
          }),
        );
      } else {
        dispatch(
          setSnackbar({
            open: true,
            message: response.error ?? 'Erreur lors de la suppression du client',
            severity: 'error',
          }),
        );
      }
    });
    toggleConfirmationDialogOpen();
  }, [customer, deleteCustomer, dispatch, garage, onClose, toggleConfirmationDialogOpen]);

  const onSubmitForm: FormikConfig<Form>['onSubmit'] = useCallback(
    (values: Form) => {
      if (!garage) {
        return;
      }
      const req: Form = {
        email: values.email === '' ? null : cleanString(values.email!),
        firstname: cleanString(values.firstname),
        lastname: cleanString(values.lastname),
        phoneNumber: cleanString(values.phoneNumber),
        notes: values.notes,
      };

      updateCustomer({ garageId: garage.id, payload: { id: customer.id, ...req } }).then((response) => {
        if (response.success) {
          // Fermeture de la boite de dialogue (avec refresh des customers)
          onClose(true);
          dispatch(
            setSnackbar({
              open: true,
              message: response.data ?? 'Le client a bien été modifié',
              severity: 'success',
            }),
          );
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? 'Erreur lors de la modification du client',
              severity: 'error',
            }),
          );
        }
      });
    },
    [garage, dispatch, onClose, updateCustomer],
  );

  const { values, errors, isValid, dirty, handleChange, handleSubmit, setFieldValue } = useFormik<Form>({
    initialValues: {
      email: customer?.email ?? '',
      firstname: customer?.firstname ?? '',
      lastname: customer?.lastname ?? '',
      phoneNumber: customer?.phoneNumber ?? '',
      notes: customer?.notes ?? '',
    },
    validationSchema: formSchema,
    onSubmit: onSubmitForm,
    enableReinitialize: true,
  });

  return (
    <Dialog open={open} onClose={() => onClose(false)} fullWidth maxWidth='md' scroll='body'>
      <Toolbar
        disableGutters
        variant='dense'
        sx={{
          minHeight: 3,
          backgroundColor: theme.palette.grey[200],
          py: 0,
        }}
      >
        <DialogTitle sx={{ flexGrow: 1 }} component={'div'}>
          <Typography
            py={0}
            my={0}
            sx={{
              pl: '34px',
              color: theme.palette.text.primary,
              textAlign: 'center',
            }}
          >
            <span>
              MODIFIER <b>UN CLIENT</b>
            </span>
          </Typography>
        </DialogTitle>
        <Tooltip title='Fermer'>
          <IconButton sx={{ mr: 1 }} size='small' aria-label='close' onClick={() => onClose(false)}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <form onSubmit={handleSubmit}>
      <DialogContent>
          <Typography
            variant='subtitle1'
            color={theme.palette.text.secondary}
            sx={{ pb: 0.5, fontWeight: 'bold', mb: 2 }}
            style={flexLeftRow}
            className='styled-alert'
          >
            <PersonIcon sx={{ mr: 1, pl: 2 }} />
            PARTICULIER
            <Box sx={{display: {xs: 'flex', md: 'none' }, flex: '1 1 100%'}}/>
            <Button
              color='primary'
              variant='text'
              onClick={toggleConvertDialogOpen}
              sx={{ position: {xs:'relative', md:'absolute'}, right: { md:'25px' }}}
            >
              Convertir en Professionnel
            </Button>
          </Typography>

          <UpdateIndividualCustomer
            values={values}
            errors={errors}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
          />
          <Typography
            variant='subtitle1'
            color={theme.palette.text.secondary}
            sx={{ pb: 0.5, mb: 1, fontWeight: 'bold' }}
            style={flexLeftRow}
            className='styled-alert'
          >
            <CarsIcon sx={{ mr: 1, pl: 2 }} /> VÉHICULES ACTIFS
          </Typography>
          <CustomerVehiclesList customer={customer} />
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: theme.palette.grey[200],
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {!!customer && (
            <Button color='error' variant='contained' onClick={toggleConfirmationDialogOpen}>
              Supprimer le client
            </Button>
          )}
          <Button disabled={dirty ? !isValid : true} type='submit' variant='contained'>
            Enregistrer
          </Button>
        </DialogActions>
      </form>
      <ConfirmationDialog
        showConfirm
        open={isConfirmationDialogOpen}
        onClose={toggleConfirmationDialogOpen}
        onConfirm={handleDeleteCustomer}
        title={'Suppression du client'}
        message={'Êtes-vous sûr de vouloir supprimer ce client ?'}
      />
      <ConfirmationDialog
        showConfirm
        open={isConvertDialogOpen}
        onClose={toggleConvertDialogOpen}
        onConfirm={handleConvertCustomer}
        title={'Conversion du client'}
        message={
          <Box>
            <Typography sx={{ mb: 3 }}>
              Vous confirmez vouloir convertir ce client en <b>Professionnel</b> ?
            </Typography>
            <Alert className='styled-alert' icon={<InfoIcon />}>
              Vous ne perdrez aucune donnée associée à ce client
            </Alert>
          </Box>
        }
      />
    </Dialog>
  );
});
