/**
 * Un email valide
 */
const emailRegex: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

/**
 * Un mot de passe qui contient au moins une majuscule, une minuscule, un chiffre
 * et un caractère spécial. La longueur du mot de passe doit être d'au moins 8 caractères.
 */
const passwordRegex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

/**
 * Un numéro de téléphone (10 chiffres)
 */
const phoneNumberRegex: RegExp = /^\d{0,10}$/;

/**
 * Un code postal (5 chiffres)
 */
const postalCodeRegex: RegExp = /^\d{5}$/;

/**
 * Une URL valide
 */
const urlRegex: RegExp = /^(ftp|http|https):\/\/[^ "]+$/;

/**
 * Un nom pouvant contenir des lettres, chiffres d'une taille comprise entre 3 et 20 caractères
 */
const userNameRegex: RegExp = /^[a-z0-9_-]{3,20}$/;

/**
 * Un texte pouvant contenir des lettres, chiffres et quelques caractères spéciaux
 */
const textRegex: RegExp = /^[\s\S]*$/;

/**
 * Un texte spécialisé poour les NOMS, pouvant contenir des lettres, chiffres et quelques caractères spéciaux
 */
const nameRegex: RegExp = /^[a-zA-ZéèàùçâêîôûäëïöüÿñæœÇÉÂÊÎÔÛÄËÏÖÜÁÍÓÚÝáíóúýß\- ']+$/;

export function validatePhoneNumber(phoneNumber: string): boolean {
  if (phoneNumber === null || phoneNumber === undefined) {
    return false;
  }
  return phoneNumberRegex.test(phoneNumber);
}

export function validateName(text: string): boolean {
  if (text === null || text === undefined) {
    return false;
  }
  return nameRegex.test(text);
}

export function validateText(text: string): boolean {
  if (text === null || text === undefined) {
    return false;
  }
  return textRegex.test(text);
}

export function validateEmail(email: string): boolean {
  if (email === null || email === undefined) {
    return false;
  }
  return emailRegex.test(email);
}
