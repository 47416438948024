import invariant from 'invariant';
import {useFetchCustomerCount, useFetchCustomers, useSearchCustomers} from "../query/customer/CustomerQuery";

type UseCustomersInfosProps = {
	garageId: string;
	query?: string;
	page?: number;
}

export const useCustomersInfos = ({ garageId, query='', page = 0 }: UseCustomersInfosProps) => {
	// Page could not be a negative number
	invariant(page >= 0, 'page should be a positive number')
	const { data: customersCount} = useFetchCustomerCount(garageId);
	const { data: customers} = useFetchCustomers(garageId, page);
	const { data: searchedCustomers} = useSearchCustomers(garageId, query);

	return {
		customersCount: customersCount || 0,
		customers: customers || [],
		searchedCustomers: searchedCustomers || [],
	}
}
